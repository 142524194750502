/*
First Section
*/

.culture .firstSection {
    position: relative;
    width: 100vw;

    background-size: cover;
    background-image: linear-gradient(90.15deg,
    #0c101c -10.1%,
    rgba(12, 16, 28, 0) 106.97%),
    url("../images/culture/firstSection.png");
}

.culture .firstSection .content .title {
    color: #f8f8f8;
    font-family: "Baloo 2";
    font-style: normal;
}

.culture .firstSection .content .description {
    font-family: "Poppins";
    font-style: normal;
    color: #ffffff;
}

.culture .firstSection .content .margin {
    background: #f05033;
}
.culture .firstSection .top{
    gap: 24px;
}
@media (min-width: 360px ) and (max-width: 1024px) {
    .culture .firstSection {
        height: 100vh;
        padding: 34px;
    }
    .culture .firstSection .content {
        width: 100%;
        height: 100%;
        gap: 22px;
        margin-bottom: 100px;
        justify-content: flex-end;    
    }

    .culture .firstSection .content .title {
        width: 100%;

        /* Heading H1 */
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;
        /* or 89px */
        /* White Text */
    }

    .culture .firstSection .content .description {
        width: 100%;
        /* Heading H6 */

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* or 133% */
    }

    .culture .firstSection .buttons {
        position: absolute;
        height: 6px;
        width: 220px;
        left: calc((100vw/2) - 80px);
        bottom: 100px;        
        gap: 20px;
        border-radius: 0px;
    }

    .culture .firstSection .buttons .button {
        height: 6px;
        width: 40px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        cursor: pointer;
    }

    .culture .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}

@media (min-width: 1024px ) and (max-width: 1440px) {
    .culture .firstSection {
        height: 100vh;
        padding: 80px;
    }
    .culture .firstSection .content {
        width: 100%;
        height: 100%;
        gap: 22px;
        margin-bottom: 100px;
        justify-content: flex-end;    
    }

    .culture .firstSection .content .title {
        width: 100%;

        /* Heading H1 */
        font-weight: 440;
        font-size: 84px;
        line-height: 106%;
        /* or 89px */
        /* White Text */
    }

    .culture .firstSection .content .description {
        width: 100%;
        /* Heading H6 */

        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
        /* or 133% */
    }

    .culture .firstSection .buttons {
        position: absolute;
        height: 6px;
        width: 220px;
        left: calc((100vw/2) - 80px);
        bottom: 100px;        
        gap: 20px;
        border-radius: 0px;
    }

    .culture .firstSection .buttons .button {
        height: 6px;
        width: 40px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        cursor: pointer;
    }

    .culture .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}
@media (min-width: 1440px) {
    .culture .firstSection {
        height: 100vh;
        padding: 80px;
    }
    .culture .firstSection .content {
        width: 100%;
        height: 100%;
        gap: 22px;
        margin-bottom: 100px;
        justify-content: flex-end;    
    }

    .culture .firstSection .content .title {
        width: 100%;

        /* Heading H1 */
        font-weight: 440;
        font-size: 84px;
        line-height: 106%;
        /* or 89px */
        /* White Text */
    }

    .culture .firstSection .content .description {
        width: 75%;
        /* Heading H6 */

        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
        /* or 133% */
    }

    .culture .firstSection .buttons {
        position: absolute;
        height: 6px;
        width: 220px;
        left: calc((100vw/2) - 80px);
        bottom: 100px;        
        gap: 20px;
        border-radius: 0px;
    }

    .culture .firstSection .buttons .button {
        height: 6px;
        width: 40px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        cursor: pointer;
    }

    .culture .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}

/* Second Section*/
.culture .secondSection {
    position: relative;
    width: 100vw;
    border-radius: 0px;
    background-color: #08090D;
}

.culture .secondSection .main .image {
    content: url('../images/culture/secondSection3.svg');
}

.culture .secondSection .main .content .info .title {
    color: #F05033;
    font-family: 'Baloo 2';
    font-style: normal;
}

.culture .secondSection .main .content .info .title span:first-child {
    color: #0B2EAC;
}

.culture .secondSection .main .content .info .title span:last-child {
    color: #F05033;
}
.culture .secondSection .main .content .info .description {
    font-family: 'Poppins';
    font-style: normal;
    color: #F8F8F8;
}

.culture .secondSection .margin {
    background: #f05033;
}
.culture .secondSection .things .header .title{
    
    /* Heading H2 */
    color: #F8F8F8;
    font-family: 'Baloo 2';
    font-style: normal;
}

@media (min-width : 360px) and (max-width:1024px) {
    .home .fourthSection {
        width: 100vw;
        padding: 43px 36px 36px 31px;
        align-items: center;
        justify-content: space-evenly;
        gap :34px;
        height: 100%;
    }
    .home .fourthSection .top{
        gap: 24px;
        align-items: center;
    }
    .home .fourthSection .top .content{
        width: 295px;
        justify-content: space-between;
        padding: 0px;
        gap: 12px;
    }
    .home .fourthSection .top .title{
        white-space : pre-wrap;
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;
        /* or 77px */
    }
    .home .fourthSection .top .margin{
        width: 10px;
        height: 289px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        background: #F05033;
    }
    .home .fourthSection .top .description{
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }
    .home .fourthSection .main{
    }
    .home .fourthSection .projects{
    }
    .home .fourthSection .projects .project {
        width: 326px;
        flex-direction: column !important;
        position: relative;
    }

    .home .fourthSection .projects .project .project-number {
        align-self: flex-start;
        font-weight: 500;
        font-size: 64px;
        line-height: 96px;
        /* identical to box height */
    }

    .home .fourthSection .projects .project .project-content {
        align-self: flex-end;
        align-items: flex-end;
        flex-direction: column !important;
        width: 297px;
        gap: 24px;
    }

    .home .fourthSection .projects .project .project-content .image {
        width: 100%;
        height: 142px;
        object-fit: cover;
        border-radius: 10px;
    }

    .home .fourthSection .projects .project .project-content .project-main {
        align-items: flex-start;
        padding: 0px;
        gap: 6px;
    }

    .home .fourthSection .projects .project .project-content .project-main .margin {
        width: 121px;
        height: 6.3px;
    }

    .home .fourthSection .projects .project .project-content .project-main .project-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
    }
    .home .fourthSection .projects .project .project-content .project-info {
        align-self: flex-start;
        gap : 24px;
        width: 295px;
    }
    .home .fourthSection .projects .project .project-content .project-main .project-description {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }

    .home .fourthSection .projects .project .project-content .project-button {
        width: 126px;
        height: 48px;
        border: 2px solid #f8f8f8;
        border-radius: 3px;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;

        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
    }
    .home .fourthSection .margin{
        width: 961.59px;
        height: 0px;
    }
    .home .fourthSection .projects .content .hover-button {

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        height: 48px;
        width: 126px;
        left: 0px;
        top: 0px;
        border-radius: 3px;
        border: 2px solid #F8F8F8;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        align-items: center;
        text-align: center;

        /* White Text */

        color: #F8F8F8;

      }

    .home .fourthSection .projects  .content .hover-button svg{
        height: 14.29px ;
        width: 27.4px;
        position: absolute;
    right: -20px;
    background: #222327;
    }    
    /* .home .fourthSection .projects .content .hover-button:hover svg{
        transform: scalex(1.43);
        right: -25px;
    } */
    .home .fourthSection .view-all .hover-button {
        position: relative;
        padding: 19px 22px;
        transition: all 0.2s ease;
        border: none;
        background-color: transparent;
        gap: 14px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        color: #F4F4F4;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .home .fourthSection .view-all .hover-button:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border: 0.6px solid #F86A1A;
        width: 56px;
        height: 56px;
        transition: all 0.3s ease;
      }
      .home .fourthSection .view-all  .hover-button span {
        position: relative;
        font-size: 16px;
        line-height: 18px;
        font-weight: 900;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        vertical-align: middle;
      }
      .home .fourthSection .view-all .hover-button svg{
        fill: #F05033;
      }
      
      .home .fourthSection .view-all  .hover-button:hover:before {
        width: 100%;
        background: #F05033;
        border: 1.4px solid #F05033;
      }
      .home .fourthSection .view-all  .hover-button:hover svg {
        transform: translateX(0);
        fill: #F4F4F4;
      }
      .home .fourthSection .view-all  .hover-button:active {
        transform: scale(0.96);
      }
}
@media (min-width : 1024px) and (max-width:1439px) {
    .home .fourthSection {
        width: 100vw;
        padding: 50px 50px 50px 50px;
        align-items: center;
        justify-content: space-evenly;
        gap :34px;
    }
    .home .fourthSection .top{
        height: 214px;
        gap: 24px;
        align-items: center;

    }
    .home .fourthSection .top .content{
        width: 864px;
        justify-content: space-between;
    }
    .home .fourthSection .top .title{
        white-space : pre-wrap;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
        /* or 77px */
    }
    .home .fourthSection .top .margin{
        height: 214px;
        width: 10px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        background: #F05033;
    }
    .home .fourthSection .top .description{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    .home .fourthSection .main{
        width: 864px;

    }
    .home .fourthSection .projects{
        padding-top: 100px;
        padding-bottom: 100px;
        width: 791px;
    }
    .home .fourthSection .projects .project .right{
        right: 0;
    }
    .home .fourthSection .projects .project .left{
        left: 0;
    }
    .home .fourthSection .projects .project {
        height: 441px;
        justify-content: center;
        position: relative;
    }

    .home .fourthSection .projects .project .project-number {
        position: absolute;
        font-weight: 500;
        align-self: flex-start;
        font-size: 128px;
        line-height: 192px;
        /* identical to box height */
    }

    .home .fourthSection .projects .project .project-content {
        position: absolute;
        align-self: flex-end;
        align-items: flex-end;
        gap: 24px;
    }

    .home .fourthSection .projects .project .project-content .image {
        width: 270px;
        height: 315px;
        object-fit: cover;
        border-radius: 10px;
    }

    .home .fourthSection .projects .project .project-content .project-main {
        align-items: flex-start;
        padding: 0px;
        gap: 6px;
    }

    .home .fourthSection .projects .project .project-content .project-main .margin {
        width: 121px;
        height: 6.3px;
    }

    .home .fourthSection .projects .project .project-content .project-main .project-title {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
    }
    .home .fourthSection .projects .project .project-content .project-info {
        align-self: flex-start;
        gap : 24px;
        width: 492px;
    }
    .home .fourthSection .projects .project .project-content .project-main .project-description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .home .fourthSection .projects .project .project-content .project-button {
        width: 126px;
        height: 48px;
        border: 2px solid #f8f8f8;
        border-radius: 3px;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;

        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
    }
    .home .fourthSection .margin{
        width: 961.59px;
        height: 0px;
    }
    .home .fourthSection  .box {
        height: 48px;
        width: 142px;

        align-items: center;
        justify-content: center;
    }

    .home .fourthSection  .box .text {
        height: 28.138957977294922px;
        width: 114.88965606689453px;
        left: 13.623046875px;
        top: 10.15380859375px;
        text-align: center;

        /* Label */
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        /* White Text */
    }
    .home .fourthSection .projects .content .hover-button {

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        height: 48px;
        width: 126px;
        left: 0px;
        top: 0px;
        border-radius: 3px;
        border: 2px solid #F8F8F8;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        align-items: center;
        text-align: center;

        /* White Text */

        color: #F8F8F8;

      }

    .home .fourthSection .projects  .content .hover-button svg{
        height: 14.29px ;
        width: 27.4px;
        position: absolute;
        right: -20px;
        background: #08090D;
    }    
    .home .fourthSection .projects .content .hover-button:hover svg{
        transition: all ease-in-out 5000ms;
        transform: scalex(1.43);
        right: -25px;
    }
    .home .fourthSection .view-all .hover-button {
        position: relative;
        padding: 19px 22px;
        transition: all 0.2s ease;
        border: none;
        background-color: transparent;
        gap: 14px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        color: #F4F4F4;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .home .fourthSection .view-all .hover-button:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border: 0.6px solid #F86A1A;
        width: 56px;
        height: 56px;
        transition: all 0.3s ease;
      }
      .home .fourthSection .view-all  .hover-button span {
        position: relative;
        font-size: 16px;
        line-height: 18px;
        font-weight: 900;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        vertical-align: middle;
      }
      .home .fourthSection .view-all .hover-button svg{
        fill: #F05033;
      }
      
      .home .fourthSection .view-all  .hover-button:hover:before {
        width: 100%;
        background: #F05033;
        border: 1.4px solid #F05033;
      }
      .home .fourthSection .view-all  .hover-button:hover svg {
        transform: translateX(0);
        fill: #F4F4F4;
      }
      .home .fourthSection .view-all  .hover-button:active {
        transform: scale(0.96);
      }
}
@media (min-width: 1440px) {
    .culture .secondSection {
        padding: 120px;
    }
    .culture .secondSection .main {
        width: calc(100vw - (80px));
        gap: 30px;
        padding:80px 80px;
    }

    .culture .secondSection .main .content {
        align-items: start;
        gap: 24px;
    }

    .culture .secondSection .main .content .info {
        align-items: start;
        gap: 12px;
    }

    .culture .secondSection .main .image {
        width: 100%;
    }

    .culture .secondSection .main .content .info .title {

        /* Heading H2 */
        align-self: stretch;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .culture .secondSection .main .content .info .description {
        width: 75%;
        /* Text */
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    .culture .secondSection .main .content .margin {
        width:25%;
        height: 6.3px;
    }
    .culture .secondSection .things{
        align-items: start;
        width: 100%;
        justify-content: start;
        gap: 42px;
        justify-content: center;
    }
    .culture .secondSection .things .header{
        gap: 24px;
        align-items: start;
        
    }
    .culture .secondSection .things .header .title{

        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
        /* identical to box height, or 77px */
        
        /* White Text */
        
    }
    .culture .secondSection .things .margin{
        width: 10px;
        height: 83px;
    }
    .home .fourthSection {
        width: 100vw;
        padding: 50px 50px 50px 50px;
        align-items: center;
        justify-content: space-evenly;
        gap :64px;
    }
    .home .fourthSection .top{
        width: 1089px;
        height: 214px;
        gap: 24px;
        align-items: center;

    }
    .home .fourthSection .top .content{
        justify-content: space-between;
    }
    .home .fourthSection .top .title{
        height: 154px;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
        /* or 77px */
    }
    .home .fourthSection .top .margin{
        height: 214px;
        width: 10px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        background: #F05033;
    }
    .home .fourthSection .top .description{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    .home .fourthSection .main{
        width: 1288.7px;

    }
    .home .fourthSection .projects{
        padding-top: 100px;
        padding-bottom: 100px;
        gap: 32px;
    }
    .home .fourthSection .projects .project {
        height: 333.7px;
        position: relative;
        justify-content: center;
    }
    .home .fourthSection .projects .project .right{
        right: 0;
    }
    .home .fourthSection .projects .project .left{
        left: 0;
    }
    .home .fourthSection .projects .project .project-number {
        position: absolute;
        font-weight: 500;
        align-self: flex-start;
        font-size: 128px;
        line-height: 192px;
        /* identical to box height */
    }

    .home .fourthSection .projects .project .project-content {
        position: absolute;
        align-self: flex-end;
        align-items: flex-end;
        gap: 24px;
    }

    .home .fourthSection .projects .project .project-content .image {
        width: 422px;
        height: 240px;
        object-fit: contain;
        border-radius: 10px;
    }

    .home .fourthSection .projects .project .project-content .project-main {
        width: 715px;
        align-items: flex-start;
        padding: 0px;
        gap: 6px;
    }

    .home .fourthSection .projects .project .project-content .project-main .margin {
        width: 121px;
        height: 6.3px;
    }

    .home .fourthSection .projects .project .project-content .project-main .project-title {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
    }
    .home .fourthSection .projects .project .project-content .project-info {
        align-self: flex-start;
        gap : 24px;
    }
    .home .fourthSection .projects .project .project-content .project-main .project-description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .home .fourthSection .projects .project .project-content .project-button {
        width: 126px;
        height: 48px;
        border: 2px solid #f8f8f8;
        border-radius: 3px;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;

        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
    }
    .home .fourthSection .margin{
        width: 961.59px;
        height: 0px;
    }
    .home .fourthSection  .box {
        height: 48px;
        width: 142px;

        align-items: center;
        justify-content: center;
    }

    .home .fourthSection  .box .text {
        height: 28.138957977294922px;
        width: 114.88965606689453px;
        left: 13.623046875px;
        top: 10.15380859375px;
        text-align: center;

        /* Label */
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        /* White Text */
    }
    .home .fourthSection .projects .content .hover-button {

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        height: 48px;
        width: 126px;
        left: 0px;
        top: 0px;
        border-radius: 3px;
        border: 2px solid #F8F8F8;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        align-items: center;
        text-align: center;

        /* White Text */

        color: #F8F8F8;

      }

      .home .fourthSection .projects  .content .hover-button svg{
        height: 14.29px ;
        width: 27.4px;
        position: absolute;
        right: -20px;
        background: #08090D;
    }    
    /* .home .fourthSection .projects .content .hover-button:hover svg{
        transition: all ease-in-out 500ms;
        transform: scalex(1.43);
        right: -25px;
    } */
    .home .fourthSection .view-all .hover-button {
        position: relative;
        padding: 19px 22px;
        transition: all 0.2s ease;
        border: none;
        background-color: transparent;
        gap: 14px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        color: #F4F4F4;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .home .fourthSection .view-all .hover-button:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border: 0.6px solid #F86A1A;
        width: 56px;
        height: 56px;
        transition: all 0.3s ease;
      }
      .home .fourthSection .view-all  .hover-button span {
        position: relative;
        font-size: 16px;
        line-height: 18px;
        font-weight: 900;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        vertical-align: middle;
      }
      .home .fourthSection .view-all .hover-button svg{
        fill: #F05033;
      }
      
      .home .fourthSection .view-all  .hover-button:hover:before {
        width: 100%;
        background: #F05033;
        border: 1.4px solid #F05033;
      }
      .home .fourthSection .view-all  .hover-button:hover svg {
        transform: translateX(0);
        fill: #F4F4F4;
      }
      .home .fourthSection .view-all  .hover-button:active {
        transform: scale(0.96);
      }
}
/* Cards */
.culture .secondSection .things .cards .card .title{
    /* Heading H5 */

    color: #FFFFFF;
    font-family: 'Poppins';
    text-transform: uppercase;
}
.culture .secondSection .things .cards .card .description{
    font-family: 'Poppins';
    font-style: normal;
    color: #FFFFFF;
}
.culture .secondSection .things .info-cards .info-card .info-number{
    text-transform: uppercase;
    color: #222327;
    font-family: 'Poppins';
}
.culture .secondSection .things .info-cards .info-card .info-title{
    font-family: 'Poppins';
    color: #F8F8F8;
}
.culture .secondSection .things .info-cards .info-card .info-description{

    font-family: 'Poppins';
    color: #F8F8F8;
}
@media (min-width: 360px ) and (max-width: 1024px) {
    .culture .secondSection .things .cards {
        width: 100%;
        gap:57px;
        flex-wrap: wrap;
        justify-content: center;
        
    }
    .culture .secondSection .things .cards .card{
        width: 100%;
        align-items: flex-start;
        padding: 16px;
        gap: 24px;
    }
    .culture .secondSection .things .cards .card .image{
        width: 60px;
        height: 60px;
    }
    .culture .secondSection .things .cards .card .title{
        width: 100%;

        /* Heading H5 */
        text-transform: capitalize;
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
    }
    .culture .secondSection .things .cards .card .description{

        width: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;

    }
    .culture .secondSection .things .info-cards{
        align-items: flex-start;
        padding: 0px;
        gap: 33px;
    }
    .culture .secondSection .things .info-cards .info-card{
        align-items: flex-start;
        padding: 0px;
        gap: 12px;
        width: 100%;
        
    }
    .culture .secondSection .things .info-cards .info-card .info-number{
        align-items: center;
        text-transform: uppercase;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        width: 39px;
        height: 48px;
        line-height: 48px;
    }
    .culture .secondSection .things .info-cards .info-card .info-title{
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        font-style: normal;
    }
    .culture .secondSection .things .info-cards .info-card .info-description{
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        font-style: normal;
        width: 256px;
    }    
}
@media (min-width: 1024px ) and (max-width: 1440px) {
    .culture .secondSection .things .cards {
        width: 100%;
        gap:57px;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: 29px;
    }
    .culture .secondSection .things .cards .card{
        width: 100%;
        align-items: flex-start;
        padding: 16px 24px 24px;
        gap: 24px;
    }
    .culture .secondSection .things .cards .card .image{
        width: 90px;
        height: 90px;
    }
    .culture .secondSection .things .cards .card .title{
        width: 100%;

        /* Heading H5 */
        
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;


    }
    .culture .secondSection .things .cards .card .description{

        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

    }
    .culture .secondSection .things .info-cards{
        align-items: flex-start;
        padding: 0px;
        gap: 33px;
    }
    .culture .secondSection .things .info-cards .info-card{
        align-items: flex-start;
        padding: 0px;
        gap: 12px;
        width: 100%;
    }
    .culture .secondSection .things .info-cards .info-card .info-number{
        align-items: flex-start;

        font-style: normal;
        font-weight: 600;
        font-size: 64px;
        line-height: 96px;
    }
    .culture .secondSection .things .info-cards .info-card .info-title{
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;
        font-style: normal;
    }
    .culture .secondSection .things .info-cards .info-card .info-description{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        font-style: normal;
    }    
}
@media (min-width: 1440px) {
    .culture .secondSection .things .cards {
        width: 100%;
        gap:57px;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: 29px;
        margin-bottom:30px;
    }
    .culture .secondSection .things .cards .card{
        width: 572px;
        align-items: flex-start;
        padding: 16px 24px 24px;
        gap: 24px;
    }
    .culture .secondSection .things .cards .card .image{
        width: 90px;
        height: 90px;
    }
    .culture .secondSection .things .cards .card .title{
        width: 100%;
        /* Heading H5 */
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
    }
    .culture .secondSection .things .cards .card .description{

        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

    }
    .culture .secondSection .things .info-cards{
        align-items: flex-start;
        padding: 0px;
        gap: 33px;
    }
    .culture .secondSection .things .info-cards .info-card{
        align-items: flex-start;
        padding: 0px;
        gap: 12px;

        width: 1179px;
        height: 108px;
    }
    .culture .secondSection .things .info-cards .info-card{
        align-items: flex-start;
        padding: 0px;
        gap: 12px;

        width: 1179px;
        height: 108px;
    }
    .culture .secondSection .things .info-cards .info-card .info-number{
        width: 108px;
        height: 96px;

        font-style: normal;
        font-weight: 600;
        font-size: 64px;
        line-height: 96px;
    }
    .culture .secondSection .things .info-cards .info-card .info-title{
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;
        font-style: normal;
    }
    .culture .secondSection .things .info-cards .info-card .info-description{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        font-style: normal;
    }    
}