.privacy-policy{
    width: 100vw;    
    background: #08090D;
}
.privacy-policy .card{
    color : white
}
.privacy-policy .card .title{
    color: #F8F8F8;
    font-family: 'Baloo 2';
    font-style: normal;
}
@media (min-width: 360px) and (max-width: 1024px) {
    .privacy-policy{
        padding: 16px;
        gap : 16px;
    }
    .privacy-policy .card{
        padding: 16px;
        width: 100%;
        gap:24px
    }
    .privacy-policy .card .content{
        gap : 24px;
         /* White Text */
     }
    .privacy-policy .card .title{
        font-weight: 400;
        font-size: 32px;
        line-height: 120%; 
    }
    .privacy-policy .card .description{
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        white-space: pre-line;
        /* White Text */
    }
    .privacy-policy .margin{
        width: 100%;
        height: 2px;
        background-color: gray;
    }
    .privacy-policy .card .content .description .list{
        margin-left: 30px;
        font-style: normal;
        display: flex;
        flex-direction: column;
        white-space: pre-line;
        /* White Text */
        list-style:circle;
        color: #F8F8F8;
    }
}
@media (min-width: 1024px) and (max-width: 1440px) {
    .privacy-policy{
        padding: 80px;
        width: 100vw;    
        gap : 32px;
    }
    .privacy-policy .card{
        width: 100%;
        gap:24px
    }
    .privacy-policy .card .content{
        gap : 24px;
         /* White Text */
     }
    .privacy-policy .card .title{
        font-weight: 400;
        font-size: 64px;
        line-height: 120%; 
    }
    .privacy-policy .card .description{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        white-space: pre-line;
        /* White Text */
    }
    .privacy-policy .margin{
        width: 100%;
        height: 2px;
        background-color: gray;
    }
    .privacy-policy .card .content .description .list{
        margin-left: 30px;
        font-style: normal;
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        white-space: pre-line;
        /* White Text */
        list-style:circle;
        color: #F8F8F8;
    }
}
@media (min-width: 1440px){
    .privacy-policy{
        padding: 120px;
        gap : 64px;
    }
    .privacy-policy .card{
        width: 100%;
        gap:24px
    }
    .privacy-policy .card .title{
        font-weight: 400;
        font-size: 64px;
        line-height: 120%; 
    }
    .privacy-policy .card .content{
       gap : 24px;
        /* White Text */
    }
    .privacy-policy .card .description{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        white-space: pre-line;
        /* White Text */
    }
    .privacy-policy .card .margin{
        width: 100%;
        height: 2px;
        background-color: gray;
    }
    .privacy-policy .card .content .description .list{
        margin-left: 30px;
        font-style: normal;
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        white-space: pre-line;
        /* White Text */
        list-style:circle;
        color: #F8F8F8;
    }
}