.contactus .firstSection {
  width: 100vw;
  background: #08090d;
}
.contactus .firstSection .content .main .title {
  font-family: "Baloo 2";
  font-style: normal;
  color: #f8f8f8;
}
.contactus .firstSection .content .main .description {
  font-family: "Poppins";
  color: #f8f8f8;
}
.contactus .firstSection .content .form .input .text {
  color: #f8f8f8;
  font-family: "Poppins";
}
.contactus .firstSection .content .form .input .input-field {
  background: #222327;
  color: #f8f8f8;
  border: 1px solid #f8f8f8;
}
.contactus .firstSection .content .form .input .input-textarea {
  isolation: isolate;
  background: #222327;
  /* White Text */
  font-family: "Roboto";
  color: #f8f8f8 ;
  border: 1px solid #f8f8f8;
}
.contactus .firstSection .content .form  .input-checkbox .checkbox:checked{
    accent-color: #222327;
    border: 1px solid #F8F8F8;
}
.contactus .firstSection .content .form  .input-checkbox .text
{
    font-family: 'Poppins';
    color: #F8F8F8;
}
.contactus .firstSection .content .form  .button
{
    background: #F05033;
    border-radius: 3px;
    color: #F4F4F4;
    font-family: 'Poppins';
}
.contactus .firstSection .info .content .main .title{
            
    font-family: 'Poppins';
    color: #F8F8F8;
}
.contactus .firstSection .info .content .main .description{
    
    /* White Text */
    
    color: #F8F8F8;
    font-family: 'Poppins';

}
@media (min-width: 360px) and (max-width: 1024px) {
  .contactus .firstSection {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 0px 60px 32px;
    gap: 60px;
    width: 100vw;
  }
  .contactus .firstSection .content {
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    width: 300px;
  }
  .contactus .firstSection .content .main {
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    
    width: 300px;
  }
  .contactus .firstSection .content .main .title {
    font-weight: 400;
    font-size: 36px;
    line-height: 120%;
    /* identical to box height, or 89px */

    /* White Text */
  }
  .contactus .firstSection .content .main .description {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .contactus .firstSection .content .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
  }
  .contactus .firstSection .content .form .input {
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    
    width: 300px;
  }
  .contactus .firstSection .content .form .input .text {

    /* Label */
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }
  .contactus .firstSection .content .form .input .input-field {
    align-items: center;
    padding: 12px;
    gap: 8px;
    
    width: 300px;
    height: 48px;
    /* background variation */
  }
  .contactus .firstSection .content .form .input .input-textarea {
    align-items: flex-start;
    padding: 12px;
    isolation: isolate;

    width: 300px;
    height: 180px;
  }
  .contactus .firstSection .content .form  .input-checkbox {
    align-items: center;
    padding: 0px 0px 16px;
    gap: 12px;
    white-space: nowrap;

    width: 179px;
    height: 40px;
  }
  .contactus .firstSection .content .form  .input-checkbox .checkbox{
    width: 18px;
    height: 18px;
    accent-color: #222327;
  }
  .contactus .firstSection .content .form  .input-checkbox .text{

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    }
    .contactus .firstSection .content .form  .button{
        width: 142px;
        height: 48px;        
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
    }
    .contactus .firstSection .info{
      width: 300px;
      gap : 80px;
      }
    .contactus .firstSection .info .content{
        align-items: flex-start;
        padding: 0px;
        gap: 32px;
    }
    .contactus .firstSection .info .content .main{
        align-items: flex-start;
        padding: 0px;
        gap: 4px;
        
      }
    .contactus .firstSection .info .content .main .title{
        height: 27px;

        /* Label */

        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        
        
        /* White Text */

    }
    .contactus .firstSection .info .content .main .description{
      font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }    
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .contactus .firstSection {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 112px 0px 112px 80px;
    width: 100vw;
    gap: 80px;
  }
  .contactus .firstSection .content {
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    
    width: 780px;
  }
  .contactus .firstSection .content .main {
    align-items: start;
    padding: 0px;
    gap: 24px;

    width: 780px;
  }
  .contactus .firstSection .content .main .title {
    font-weight: 440;
    font-size: 84px;
    line-height: 106%;
    /* identical to box height, or 89px */

    /* White Text */
  }
  .contactus .firstSection .content .main .description {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }
  .contactus .firstSection .content .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 737px;
  }
  .contactus .firstSection .content .form .input {
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    width: 737px;
  }
  .contactus .firstSection .content .form .input .text {
    width: 737px;
    height: 27px;

    /* Label */

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }
  .contactus .firstSection .content .form .input .input-field {
    align-items: center;
    padding: 12px;
    gap: 8px;

    width: 737px;
    height: 48px;

    /* background variation */
  }
  .contactus .firstSection .content .form .input .input-textarea {
    padding: 12px;
    width: 737px;
    height: 180px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }
  .contactus .firstSection .content .form  .input-checkbox {
    align-items: center;
    padding: 0px 0px 16px;
    gap: 12px;
    white-space: nowrap;

    width: 179px;
    height: 40px;
  }
  .contactus .firstSection .content .form  .input-checkbox .checkbox{
    width: 18px;
    height: 18px;
    accent-color: #222327;
  }
  .contactus .firstSection .content .form  .input-checkbox .text{

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    }
    .contactus .firstSection .content .form  .button{
        width: 142px;
        height: 48px;        
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
    }
    .contactus .firstSection .info{
        width: 343px;
        gap : 80px;
        width: 780px;
      }
    .contactus .firstSection .info .content{
        align-items: flex-start;
        padding: 0px;
        gap: 32px;
        width: 343px;
    }
    .contactus .firstSection .info .content .main{
        align-items: flex-start;
        padding: 0px;
        gap: 4px;
        
        width: 343px;
    }
    .contactus .firstSection .info .content .main .title{
        width: 343px;
        height: 27px;

        /* Label */

        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        
        
        /* White Text */

    }
    .contactus .firstSection .info .content .main .description{
        width: 343px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }    
}

@media (min-width: 1440px) {
  .contactus .firstSection {
    padding: 112px 0px 112px 80px;
    gap: 80px;
    align-items: center;
    justify-content: center;
  }
  .contactus .firstSection .content {
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    width: 780px;
  }
  .contactus .firstSection .content .main {
    align-items: start;
    padding: 0px;
    gap: 24px;

    width: 780px;
  }
  .contactus .firstSection .content .main .title {
    font-weight: 440;
    font-size: 84px;
    line-height: 106%;
    /* identical to box height, or 89px */

    /* White Text */
  }
  .contactus .firstSection .content .main .description {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }
  .contactus .firstSection .content .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 737px;
    height: 565px;
  }
  .contactus .firstSection .content .form .input {
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    width: 737px;
  }
  .contactus .firstSection .content .form .input .text {
    width: 737px;
    height: 27px;

    /* Label */

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }
  .contactus .firstSection .content .form .input .input-field {
    align-items: center;
    padding: 12px;
    gap: 8px;

    width: 737px;
    height: 48px;

    /* background variation */
  }
  .contactus .firstSection .content .form .input .input-textarea {
    padding: 12px;
    width: 737px;
    height: 180px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }
  .contactus .firstSection .content .form  .input-checkbox {
    align-items: center;
    padding: 0px 0px 16px;
    gap: 12px;
    white-space: nowrap;
    width: 179px;
    height: 40px;
  }
  .contactus .firstSection .content .form  .input-checkbox .checkbox{
    width: 18px;
    height: 18px;
    accent-color: #222327;
  }
  .contactus .firstSection .content .form  .input-checkbox .text{

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    }
    .contactus .firstSection .content .form  .button{
        width: 142px;
        height: 48px;        
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
    }
    .contactus .firstSection .info{
        width: 343px;
        gap : 80px;
    }
    .contactus .firstSection .info .content{
        align-items: flex-start;
        padding: 0px;
        gap: 32px;
        width: 343px;
    }
    .contactus .firstSection .info .content .main{
        align-items: flex-start;
        padding: 0px;
        gap: 4px;
        
        width: 343px;
    }
    .contactus .firstSection .info .content .main .title{
        width: 343px;
        height: 27px;

        /* Label */

        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        
        
        /* White Text */

    }
    .contactus .firstSection .info .content .main .description{
        width: 343px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }    
}
.submit-button:hover {
  background-color: #ee3434 !important;
  border: 1px solid #f8f8f8 !important;
  cursor: pointer !important;
}