/*
First Section
*/

.home .firstSection {
    position: relative;
    width: 100vw;
    overflow: hidden;
}

.home .firstSection .content{
    z-index: 1;
}
/*Slideshow*/
.home .slideshow {
    position: absolute;
    margin: 0 auto;
    width: 100%;
    height: 100%; /* Adjust height to fill the screen */
}

.home .slideshow .slideshowSlider {
    height: 100%;
    white-space: nowrap;
    width: 100%; /* Ensure slideshow container fills the screen */
    transition: transform ease-in-out 2s; /* Removed 'all' transition */
}

.home .slideshow .slideshowSlider .slide {
    display: inline-block;
    background-size: cover; /* Ensure images cover the entire slide */
    background-position: center; /* Center the background image */
    height: 100%; /* Ensure the slide fills the container vertically */
    width: 100vw; /* Adjusted width to fill the entire viewport width */
}

/*-------------*/

.home .firstSection .content .title {
    color: #f8f8f8;
    font-family: "Baloo 2";
    font-style: normal;
}

.home .firstSection .content .description {
    font-family: "Poppins";
    font-style: normal;
    color: #ffffff;
}

.home .firstSection .content .margin {
    background: #f05033;
}

.home .firstSection .buttons .button {
    background-color: #D9D9D9;
}

.home .firstSection .buttons .selected {
    background-color: #f05033;
}

@media (min-width: 360px ) and (max-width: 1024px) {
    .home .firstSection {
        height: 100vh;
    }
    .home .firstSection .content {
        width: 100%;
        height: 100%;
        gap: 22px;
        padding: 34px;
        margin-bottom: 100px;
        justify-content: flex-end;    
    }

    .home .firstSection .content .title {
        width: 100%;

        /* Heading H1 */
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;
        /* or 89px */
        /* White Text */
    }

    .home .firstSection .content .description {
        width: 100%;
        /* Heading H6 */

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* or 133% */
    }

    .home .firstSection .buttons {
        position: absolute;
        height: 6px;
        width: 220px;
        left: calc((100vw/2) - 80px);
        bottom: 100px;        
        gap: 20px;
        border-radius: 0px;
    }

    .home .firstSection .buttons .button {
        height: 6px;
        width: 40px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        cursor: pointer;
    }

    .home .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}

@media (min-width: 1024px ) and (max-width: 1440px) {
    .home .firstSection {
        height: 100vh;
    }
    .home .firstSection .content {
        width: 100%;
        height: 100%;
        gap: 22px;
        padding: 80px;
        margin-bottom: 100px;
        justify-content: flex-end;    
    }

    .home .firstSection .content .title {
        width: 100%;

        /* Heading H1 */
        font-weight: 440;
        font-size: 84px;
        line-height: 106%;
        /* or 89px */
        /* White Text */
    }

    .home .firstSection .content .description {
        width: 75%;
        /* Heading H6 */

        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
        /* or 133% */
    }

    .home .firstSection .buttons {
        position: absolute;
        height: 6px;
        width: 220px;
        left: calc((100vw/2) - 80px);
        bottom: 100px;        
        gap: 20px;
        border-radius: 0px;
    }

    .home .firstSection .buttons .button {
        height: 6px;
        width: 40px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        cursor: pointer;
    }

    .home .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}
@media (min-width: 1440px) {
    .home .firstSection {
        height: 100vh;
        width: 100vw;
    }
    .home .firstSection .content {
        width: 100%;
        height: 100%;
        gap: 22px;
        padding: 80px;
        margin-bottom: 100px;
        justify-content: flex-end;    
    }
    .home .firstSection .content .top{
        gap : 24px;
    }
    .home .firstSection .content .title {
        width: 100%;

        /* Heading H1 */
        font-weight: 440;
        font-size: 84px;
        line-height: 106%;
        /* or 89px */
        /* White Text */
    }

    .home .firstSection .content .description {
        width: 75%;
        /* Heading H6 */

        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
        /* or 133% */
    }

    .home .firstSection .buttons {
        position: absolute;
        height: 6px;
        width: 220px;
        left: calc((100vw/2) - 80px);
        bottom: 100px;        
        gap: 20px;
        border-radius: 0px;
    }

    .home .firstSection .buttons .button {
        height: 6px;
        width: 40px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        cursor: pointer;
    }

    .home .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}
/*Second Section*/
.home .secondSection {
    position: relative;
    width: 100vw;
    border-radius: 0px;
    background-color: #08090D;
}

.home .secondSection .image {
    content: url('../images/home/secondSectionImage.svg');
    object-fit: cover;
}

.home .secondSection .content .title {
    color: #F8F8F8;
}

.home .secondSection .content .title span {
    color: #F05033;
}

.home .secondSection .content .description {
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
}
@media (min-width: 360px ) and (max-width: 1023px) {
    .home .secondSection {
        border-radius: 0px;
        padding: 32px;        
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
    }

    .home .secondSection .content {
        width: 296px;
        height: 290px;
        align-items: center;
        gap: 24px;
    }

    .home .secondSection .image {
        width: 295px;
        height: 267.11px;
        border-radius: 10px;
    }

    .home .secondSection .content .title {
        width: 296px;
        height: 129px;

        /* Heading H2 */
        align-self: stretch;
        font-family: 'Baloo 2';
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;
    }

    .home .secondSection .content .description {
        width: 296px;
        height: 147px;


        /* Text */

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }
}
@media (min-width: 1024px ) and (max-width: 1439px) {
    .home .secondSection {
        padding: 80px;
        gap: 30px;
        justify-content: space-evenly;
        align-items: center;
    }

    .home .secondSection .content {
        align-items: center;
        gap: 24px;
        height: 553px;

    }

    .home .secondSection .image {
        width: 419px;
        height: 562.44px;
        border-radius: 10px;
    }

    .home .secondSection .content .title {
        height: 385px;
        width: 364.99609375px;
        /* Heading H2 */
        align-self: stretch;
        font-family: 'Baloo 2';
        font-style: normal;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .home .secondSection .content .description {
        height: 144px;
        width: 364.99609375px;


        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
}
@media (min-width: 1440px) {
    .home .secondSection {
        height: 722.437744140625px;
        padding: 80px;
        gap: 30px;
        justify-content: space-evenly;
        align-items: center;
    }

    .home .secondSection .content {
        align-items: center;
        gap: 24px;
    }

    .home .secondSection .image {
        width: 628.89px;
        height: 562.44px;
        border-radius: 10px;
        
    }

    .home .secondSection .content .title {
        width: 571.11px;
        height: 231px;

        /* Heading H2 */
        align-self: stretch;
        font-family: 'Baloo 2';
        font-style: normal;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .home .secondSection .content .description {
        width: 571.11px;
        height: 96px;

        /* Text */

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
}


/*Third Section*/
.home .thirdSection {
    position: relative;
    width: 100vw;
    border-radius: 0px;
    background-color: #252730;
}

.home .thirdSection .top .title {
    font-family: 'Baloo 2';
    font-style: normal;
    color: #F8F8F8;
}

.home .thirdSection .top .box {
    background: #F05033;
    border-radius: 3px;
}

.home .thirdSection .top .box {
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
}
.home .thirdSection .services .content .cards  {
    display: inline-flex;
    overflow: hidden;
}
.home .thirdSection .services .content .cards .card
{
    display: inline-block;
}
.home .thirdSection .services .content .cards .card .image {
    object-fit: cover;
    border-radius: 10px;
    height: 100%;
    width: 100%;
}

.home .thirdSection .services .content .cards .card .title {
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
}

.home .thirdSection .services .content .cards .card .colorBend {

    background: rgba(12, 16, 28, 0.5);
    width: 100%;
    border-radius: 10px;
    height: 100%;
    position: absolute;
}

.home .thirdSection .services .buttons .button {
    background-color: #D9D9D9;
}

.home .thirdSection .services .buttons .selected {
    background-color: #f05033;
}
.home .thirdSection .services .content .cards .cardslide{
    height: 460.22px;
    display: inline-flex;
    gap: 20px;
    transition: ease 1000ms;
    transform: translate3d(-0%,0,0);
}
.home .thirdSection .services .content .cards .cardslide .card {
    white-space: nowrap;
}

.home .thirdSection .services .buttons {
    white-space: pre-wrap;
    justify-content:center;
    max-width: 1800px;
}
/* Hover Effect */
.home .thirdSection .services .content .cards .card:hover .title{
   bottom:15%;
}
.home .thirdSection .services .content .cards .card:hover .colorBend{
    background: rgba(12, 16, 28, 0.3);
}
  /* Expand */
  .home .thirdSection .services .hide{
    display:  none;
}
.home .thirdSection .services .expand{
    height: 100% !important;
    display: inline-flex;
    justify-content: center;
    flex-wrap: wrap;
    transform: translate3d(0%,0%,0) !important;
}
.home .thirdSection .services .content  .contentControls .left{
    width: 52px;
    height: 52px;
    left: 52px;
    top: 256px;
    background: #F05033;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    fill: #FFFFFF;
    cursor: pointer;
}
.home .thirdSection .services .content  .contentControls svg{
    fill: #FFFFFF;
}
.home .thirdSection .services .content  .contentControls .right{
    width: 52px;
    height: 52px;
    left: 52px;
    top: 256px;
    background: #F05033;
    border-radius: 100px;
    transform: rotate(-180deg);
    align-items: center;
    justify-content: center;
    fill: #FFFFFF;
    cursor: pointer;
}
.home .thirdSection .services .content  .contentControls .disable{
    background: #323232 !important;
    fill: #7b7b7b;
}

@media (min-width: 360px ) and (max-width: 1023px) {
    .home .thirdSection {
        padding: 50px;
        align-items: center;
        gap: 24px;
    }

    .home .thirdSection .top {
        width: 327px;
        height: 63px;
        align-items: center;
        justify-content: space-between;
    }

    .home .thirdSection .top .title {
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;
    }

    .home .thirdSection .top .box {
        width: 107px;
        height: 33px;
        border-radius: 100px;
        display: flex;
        flex-direction: row;

        font-weight: 500;
        font-size: 14px;
        line-height: 21px;

        gap: 6px;
        align-items: center;
        justify-content: center;
    }

    .home .thirdSection .top .box img {
        width: 14px ;
        height: 14px ;
    }
    .home .thirdSection .services {
        gap: 24px;
        align-items: center;
    }

    .home .thirdSection .services .content {
        width: 100vw;
        justify-content: center;
    }

    .home .thirdSection .services .content .cards {
        width: 100vw;
        height: 100%;
        padding: 20px;
        overflow-x: scroll;
        
    }
    .home .thirdSection .services .content .cards {
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    .home .thirdSection .services .content .cards::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
    .home .thirdSection .services .content .cards .card {
        width: 234.67px;
        height: 312px;
        position: relative;   
        cursor: pointer;
    }
    .home .thirdSection .services .content .controls {
        cursor: pointer;
        display: none;
    }
    .home .thirdSection .services .content .cards .cardslide{
        height: 100%;
    }
    .home .thirdSection .services .content .contentControls {
        gap: 24px;
        align-items: center
    }
    .home .thirdSection .services .content .cards .card .colorBend {
        border-radius: 10px;

    }
    .home .thirdSection .services .content .cards .card:hover .title{
        top:60%;
     }
    .home .thirdSection .services .content .cards .card .title {
        position: absolute;
        padding: 12px;
        left: 0%;
        right: 0.04%;
        bottom: 5%;
        width: 172px;
        height: 27px;
        
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        white-space: pre-wrap;
        cursor: pointer;

        display: flex;
        align-items: self-end;
    }

    .home .thirdSection .services .buttons {
        gap: 20px;
        border-radius: 0px;
        display: none;
    }

    .home .thirdSection .services .buttons .button {
        width: 40px;
        height: 6px;
        left: 0px;
        
        top: 0px;
        border-radius: 0px;
        cursor: pointer;
    }
    

}
@media (min-width: 1024px ) and (max-width: 1439px) {
    .home .thirdSection {
        width: 100vw;
        border-radius: 0px;
        padding: 40px 60px 80px 60px;
        align-items: center;
        gap: 24px;
    }

    .home .thirdSection .top {
        width: 863px;
        height: 97px;
        align-items: center;
        justify-content: space-between;
    }

    .home .thirdSection .top .title {
        width: 231.58px;
        height: 77px;

        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .home .thirdSection .top .box {
        height: 48px;
        width: 156px;

        display: flex;
        flex-direction: row;

        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        gap : 12px;
        align-items: center;
        justify-content: center;
    }

    .home .thirdSection .services {
        gap: 24px;
        align-items: center;
    }

    .home .thirdSection .services .content {
        width: 1320.47px;
        height: 312px;
        justify-content: center;
    }

    .home .thirdSection .services .content .cards {
        height: 312px;
        width: 760.47px;
    }

    .home .thirdSection .services .content .cards .card {
        position: relative;   
        width: 234.67px;
        height: 311.85px;
        cursor: pointer;
    }
    .home .thirdSection .services .content .controls {
        cursor: pointer;
    }

    .home .thirdSection .services .content .contentControls {
        gap: 24px;
        align-items: center;
        justify-content: center;
    }

    .home .thirdSection .services .content .cards .card .title {
        position: absolute;
        padding: 12px;
        left: 0%;
        right: 0.04%;
        bottom: 5%;        
        width: 100%;
        height: 48px;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        white-space: pre-wrap;
        cursor: pointer;

        display: flex;
        align-items: self-end;
       
        
    }
    .home .thirdSection .services .buttons {
        gap: 20px;
        border-radius: 0px;
    }

    .home .thirdSection .services .buttons .button {
        width: 40px;
        height: 6px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        cursor: pointer;
    }

}
@media (min-width: 1440px) {
    .home .thirdSection {
        padding: 50px;
        align-items: center;
        gap: 24px;
    }

    .home .thirdSection .top {
        width: 1282px;
        height: 97px;
        align-items: center;
        justify-content: space-between;
    }

    .home .thirdSection .top .title {
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .home .thirdSection .top .box {
        height: 48px;
        width: 156px;

        display: flex;
        flex-direction: row;

        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        gap : 12px;
        align-items: center;
        justify-content: center;
    }


    .home .thirdSection .services {
        gap: 24px;
        align-items: center;
    }

    .home .thirdSection .services .content {
        width: 1320.47px;
        height: 460.22px;
    }

    .home .thirdSection .services .content .cards {
        height: 460.22px;
        width: 1168.47px;
    }

    .home .thirdSection .services .content .cards .card {
        width: 373.49px;
        height: 460.22px;
        position: relative;   
        cursor: pointer;

    }
    .home .thirdSection .services .content .controls {
        cursor: pointer;
        width: 52px;
        height: 52px;
    }

    .home .thirdSection .services .content .contentControls {
        gap: 24px;
        align-items: center
    }
    .home .thirdSection .services .content .cards .card .colorBend {
        border-radius: 10px;

    }
    .home .thirdSection .services .content .cards .card .title {
        position: absolute;
        padding: 12px;
        left: 0%;
        right: 0.04%;
        bottom: 5%;
        cursor: pointer;

        width: 305px;
        height: 48px;
        font-weight: 500;
        font-size: 32px;
        line-height: 48px;
        white-space: pre-wrap;
        display: flex;
        align-items: self-end;
    }

    .home .thirdSection .services .buttons {
        gap: 20px;
        border-radius: 0px;
    }

    .home .thirdSection .services .buttons .button {
        width: 40px;
        height: 6px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        cursor: pointer;
    }

}
/* Fourth Section*/
.home .fourthSection{
    position: relative;
    width: 100vw;
    background: #08090D;
    }
    .home .fourthSection .top {
        padding-top: 200px;
    }
.home .fourthSection .top .title{
    font-family: 'Baloo 2';
    font-style: normal;
    color: #F8F8F8;
}
.home .fourthSection .top .title span{
    color: #F05033;
}
.home .fourthSection .top .description{
    font-family: 'Poppins';
    font-style: normal;
    color: #F8F8F8;
    padding-top: 20px;
    
}

.home .fourthSection .projects .project .project-number {
        font-weight: 200;
        font-size: 64px;
        line-height: 10px;
        color: #b3d3ab73;   
}

.home .fourthSection .projects .project .project-content .project-main .margin {
    background: #f05033;
}

.home .fourthSection .projects .project .project-content .project-main .project-title {
    text-transform: uppercase;
    color: #f05033;
    font-family: "Poppins";
    font-style: normal;
}

.home .fourthSection .projects .project .project-content .project-main .project-description {
    font-family: "Poppins";
    color: #ede7e7;
    font-style: normal;
    padding-top: 15px;
}


.home .fourthSection .projects .project .project-content .project-button {
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    color: #f8f8f8;

}

.home .fourthSection .projects .project .project-content .project-info .content .see-more-button {
    align-self:normal;
    background-color: #db3923;
    background-image: none;
    background-position: 0 50%;
    background-repeat: repeat no-repeat;
    background-size: 4px 3px;
    border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
    border-style: solid;
    border-width: 3px;
    box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    width: 400px;
    display: inline-block;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1.3rem;
    line-height: 23px;
    outline: none;
    padding-top: 80px;
    align-items:first baseline;
    padding: 1rem;
    text-decoration: none;
    transition: all 235ms ease-in-out;
    border-bottom-left-radius: 15px 255px;
    border-bottom-right-radius: 225px 15px;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.home .fourthSection .projects .project .project-content .project-info .content .see-more-button:hover {
    box-shadow: rgb(248, 247, 247) 8px 10px 10px -10px;
    transform: translate3d(0, 8px, 0);
    background-color: #db3923;
}

.home .fourthSection .projects .project .project-content .project-info .content .see-more-button:focus {
    box-shadow: rgb(255, 254, 254) 2px 8px 4px -6px;
    color: #ffffff;
}

.home .fourthSection .view-all .button-space {
   
    width: 100%;
}

.home .fourthSection .view-all .all {
    display: flex;
    perspective: 10px;
    transform: perspective(300px) rotateX(20deg);
    will-change: perspective;
    perspective-origin: center center;
    transition: all 1s ease-out;
    justify-content: center;
    transform-style: preserve-3d;
    padding-bottom: 100px;
    padding-top: 0;
    text-align: center;
    
  }
  .home .fourthSection .view-all .all:hover {
    perspective: 500px;
    transition: all 1s ease-in;
    transform: perspective(1000px) rotateX(0deg);
    .text {
      opacity: 1;
    }
     & > div {
      opacity: 1;
      transition-delay: 0s;
    }
    .explainer {
      opacity: 0;
    }
  }
  
  .home .fourthSection .view-all .left, .center, .right, .lefter, .righter {
    width: 200px;
    height: 150px;
    transform-style: preserve-3d;
    border-radius: 10px;
    border: 1px solid #fff;
    box-shadow: 0 0 20px 5px rgb(190, 180, 175);
    opacity: 0;
    transition: all .3s ease;
    transition-delay: 1s;
    position: relative;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: rgb(230, 101, 37);
    background-blend-mode: color-burn;
   
    
    &:hover {
      box-shadow: 0 0 30px 10px rgb(73, 70, 73);
    background-color: rgb(73, 70, 73);
    text-align: center;
    }
  }
  .home .fourthSection .view-all .text {
    transform: translateY(30px);
    opacity: 0;
    transition: all .3s ease;
    bottom: 0;
    left: 5px;
    position: absolute;
    will-change: transform;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.6)
    
  }
  .home .fourthSection .view-all .lefter {
    transform: translateX(-60px) translateZ(-50px) rotateY(-10deg);
    background-image: url("../../assets/images/home/processor.png");
    background-size: 60%;
  }
  .home .fourthSection .view-all .left {
    transform: translateX(-30px) translateZ(-25px) rotateY(-5deg);
    background-image: url("../../assets/images/home/conversation.png");
    background-size: 60%;
  }
  .home .fourthSection .view-all .center {
    opacity: 1;
    background-image: url("../../assets/images/home/public-relation.png");
    background-size: 60%;
  }
  .home .fourthSection .view-all .right {
    transform: translateX(30px) translateZ(-25px) rotateY(5deg);
    background-image: url("../../assets/images/home/growth.png");
    background-size: 60%;
  }
  .home .fourthSection .view-all .righter {
    transform: translateX(60px) translateZ(-50px) rotateY(10deg);
    background-image: url("../../assets/images/home/reward.png");
    background-size: 60%;
  }
  .home .fourthSection .view-all .explainer {
    font-weight: 300;
    font-size: 2rem;
    color: #fff;
    transition: all .6s ease;
    width: 100%;
    height: 100%;
    background-color: #000000;
    background-image: radial-gradient(circle at center top, rgb(71, 71, 87), rgb(73, 73, 87));
    border-radius: 10px;
    text-shadow: 0 0 10px rgba(97, 94, 94, 0.778);
    
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home .fourthSection .view-all .explainer .logo {
    padding: 20px 10px 20px 10px ;
  }
  
  

.home .fourthSection .view-all .button-space .view-all-button {
    margin: 10px;
    margin-bottom: 200px;
    padding: 20px 80px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    width: 400px;
    border-radius: 10px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 30px -10px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819  51%, #FF512F  100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}


.home .fourthSection .view-all .button-space .view-all-button:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}


.home .fourthSection .view-all .button-space .view-all-button:active {
    transform: scale(0.95);
}


.home .fourthSection  .box {
    background: #F05033;
    border-radius: 3px;
    cursor: pointer;
}

.home .fourthSection  .box .text {
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
    cursor: pointer;
}
.home .fourthSection .projects .project .project-content .image {
    opacity: 0.7;
}
.home .fourthSection .projects .project .project-content .image:hover {
    opacity: 1;
}
.home .fourthSection .disable{
    color: #7b7b7b !important;
    border-color: #7b7b7b !important ;
}
.home .fourthSection .disable svg{
    fill: #7b7b7b !important;
}
@media (min-width : 360px) and (max-width:1024px) {
    .home .fourthSection {
        width: 100vw;
        padding: 43px 36px 36px 31px;
        align-items: center;
        justify-content: space-evenly;
        gap :34px;
        height: 100%;
    }

    .home .fourthSection .top{
        gap: 24px;
        align-items: center;
    }
    .home .fourthSection .top .content{
        width: 295px;
        justify-content: space-between;
        padding: 0px;
        gap: 12px;
    }
    .home .fourthSection .top .title{
        white-space : pre-wrap;
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;
        /* or 77px */
    }
    .home .fourthSection .top .margin{
        width: 10px;
        height: 289px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        background: #F05033;
    }
    .home .fourthSection .top .description{
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }
    .home .fourthSection .projects{
        gap:24px;
    }
    .home .fourthSection .projects .project {
        width: 326px;
        flex-direction: column !important;
        position: relative;
    }
    .home .fourthSection .projects .project .project-number {
        align-self: center;
        font-weight: 500;
        font-size: 65px;
        line-height: 96px;
        /* identical to box height */
    }
    .home .fourthSection .projects .project .project-content {
        align-self: flex-end;
        align-items: flex-end;
        flex-direction: column !important;
        width: 297px;
        gap: 24px;
    }
    .home .fourthSection .projects .project .project-content .image {
        width: 100%;
        height: 142px;
        object-fit: cover;
        border-radius: 10px;
        
    }
    .home .fourthSection .projects .project .project-content .project-main {
        align-items: flex-start;
        padding: 0px;
        gap: 6px;
    }

    .home .fourthSection .projects .project .project-content .project-main .margin {
        width: 121px;
        height: 6.3px;
    }

    .home .fourthSection .projects .project .project-content .project-main .project-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
    }
    .home .fourthSection .projects .project .project-content .project-info {
        align-self: flex-start;
        gap : 24px;
        width: 295px;
    }
    .home .fourthSection .projects .project .project-content .project-main .project-description {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }

    .home .fourthSection .projects .project .project-content .project-button {
        width: 126px;
        height: 48px;
        border: 2px solid #f8f8f8;
        border-radius: 3px;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
    }
    .home .fourthSection .margin{
        width: 961.59px;
        height: 0px;
    }
    .home .fourthSection .projects .content .hover-button {

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        height: 48px;
        width: 126px;
        left: 0px;
        top: 0px;
        border-radius: 3px;
        border: 2px solid #F8F8F8;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        align-items: center;
        text-align: center;

        /* White Text */

        color: #F8F8F8;

      }

    .home .fourthSection .projects  .content .hover-button svg{
        height: 14.29px ;
        width: 27.4px;
        position: absolute;
        right: -20px;
        background: #222327;
    }    
    /* .home .fourthSection .projects .content .hover-button:hover svg{
        transform: scalex(1.43);
        right: -25px;
    } */
    .home .fourthSection .view-all .hover-button {
        position: relative;
        padding: 19px 22px;
        transition: all 0.2s ease;
        border: none;
        background-color: transparent;
        gap: 14px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        color: #F4F4F4;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .home .fourthSection .view-all .hover-button:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border: 0.6px solid #F86A1A;
        width: 56px;
        height: 56px;
        transition: all 0.3s ease;
      }
      .home .fourthSection .view-all  .hover-button span {
        position: relative;
        font-size: 16px;
        line-height: 18px;
        font-weight: 900;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        vertical-align: middle;
      }

      

      .home .fourthSection .view-all .hover-button svg{
        fill: #F05033;
      }
      
      .home .fourthSection .view-all  .hover-button:hover:before {
        width: 100%;
        background: #F05033;
        border: 1.4px solid #F05033;
      }
      .home .fourthSection .view-all  .hover-button:hover svg {
        transform: translateX(0);
        fill: #F4F4F4;
      }
      .home .fourthSection .view-all  .hover-button:active {
        transform: scale(0.96);
      }
}
@media (min-width : 1024px) and (max-width:1439px) {
    .home .fourthSection {
        width: 100vw;
        padding: 50px 50px 50px 50px;
        align-items: center;
        justify-content: space-evenly;
        gap :34px;
    }
    .home .fourthSection .top{
        height: 214px;
        gap: 24px;
        align-items: center;

    }
    .home .fourthSection .top .content{
        width: 864px;
        justify-content: space-between;
    }
    .home .fourthSection .top .title{
        white-space : pre-wrap;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
        /* or 77px */
    }
    .home .fourthSection .top .margin{
        height: 214px;
        width: 10px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        background: #F05033;
    }
    .home .fourthSection .top .description{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    .home .fourthSection .main{
        width: 864px;

    }
    .home .fourthSection .projects{
        padding-top: 100px;
        padding-bottom: 100px;
        width: 791px;
    }
    .home .fourthSection .projects .project .right{
        right: 0;
    }
    .home .fourthSection .projects .project .left{
        left: 0;
    }
    .home .fourthSection .projects .project {
        height: 441px;
        justify-content: center;
        position: relative;
    }

    .home .fourthSection .projects .project .project-number {
        align-self: center;
        font-weight: 500;
        font-size: 65px;
        line-height: 96px;
        padding-right: 40px;
         
        /* identical to box height */
    }

    .home .fourthSection .projects .project .project-content {
        position: absolute;
        align-self: flex-end;
        align-items: flex-end;
        gap: 24px;
    }

    .home .fourthSection .projects .project .project-content .image {
        width: 270px;
        height: 105px;
        object-fit: cover;
        border-radius: 10px;
        
       
    }

    .home .fourthSection .projects .project .project-content .project-main {
        align-items: flex-start;
        padding: 0px;
        gap: 6px;
    }

    .home .fourthSection .projects .project .project-content .project-main .margin {
        width: 121px;
        height: 6.3px;
    }

    .home .fourthSection .projects .project .project-content .project-main .project-title {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
    }
    .home .fourthSection .projects .project .project-content .project-info {
        align-self: flex-start;
        gap : 24px;
        width: 492px;
    }
    .home .fourthSection .projects .project .project-content .project-main .project-description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .home .fourthSection .projects .project .project-content .project-button {
        width: 126px;
        height: 48px;
        border: 2px solid #f8f8f8;
        border-radius: 3px;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;

        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
    }
    .home .fourthSection .margin {
        width: 961.59px;
        height: 0px;
    }
    .home .fourthSection  .box {
        height: 48px;
        width: 142px;

        align-items: center;
        justify-content: center;
    }

    .home .fourthSection  .box .text {
        height: 28.138957977294922px;
        width: 114.88965606689453px;
        left: 13.623046875px;
        top: 10.15380859375px;
        text-align: center;

        /* Label */
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        /* White Text */
    }
    .home .fourthSection .projects .content .hover-button {

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        height: 48px;
        width: 126px;
        left: 0px;
        top: 0px;
        border-radius: 3px;
        border: 2px solid #F8F8F8;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        align-items: center;
        text-align: center;

        /* White Text */

        color: #F8F8F8;

      }

    .home .fourthSection .projects  .content .hover-button svg{
        height: 14.29px ;
        width: 27.4px;
        position: absolute;
        right: -20px;
        background: #08090D;
    }    
    .home .fourthSection .projects .content .hover-button:hover svg{
        transition: all ease-in-out 5000ms;
        transform: scalex(1.43);
        right: -25px;
    }
    .home .fourthSection .view-all .hover-button {
        position: relative;
        padding: 19px 22px;
        transition: all 0.2s ease;
        border: none;
        background-color: transparent;
        gap: 14px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        color: #F4F4F4;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .home .fourthSection .view-all .hover-button:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border: 0.6px solid #F86A1A;
        width: 10px;
        height: 65px;
        transition: all 0.3s ease;
        background-color: #F86A1A;
      }


    }
      .home .fourthSection .view-all  .hover-button span {
        position: relative;
        font-size: 16px;
        line-height: 18px;
        font-weight: 900;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        vertical-align: middle;
      }
      .home .fourthSection .view-all .hover-button svg{
        fill: #F05033;
      }
      
      .home .fourthSection .view-all  .hover-button:hover:before {
        width: 100%;
        background: #F05033;
        border: 1.4px solid #F05033;
      }
      .home .fourthSection .view-all  .hover-button:hover svg {
        transform: translateX(0);
        fill: #F4F4F4;
      }
      .home .fourthSection .view-all  .hover-button:active {
        transform: scale(0.96);
      }

@media (min-width: 1440px) {
    .home .fourthSection {
        width: 100vw;
        padding: 50px 50px 50px 50px;
        align-items: center;
        justify-content: space-evenly;
        gap :64px;
    }
    .home .fourthSection .top{
        width: 1089px;
        height: 214px;
        gap: 24px;
        align-items: center;

    }
    .home .fourthSection .top .content{
        justify-content: space-between;
    }
    .home .fourthSection .top .title{
        height: 154px;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
        /* or 77px */
    }
    .home .fourthSection .top .margin{
        height: 214px;
        width: 10px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        background: #F05033;
    }
    .home .fourthSection .top .description{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    .home .fourthSection .main{
        width: 1288.7px;

    }
    .home .fourthSection .projects{
        padding-top: 100px;
        padding-bottom: 100px;
        gap: 32px;
    }
    .home .fourthSection .projects .project {
        height: 333.7px;
        position: relative;
        justify-content: center;
    }
    .home .fourthSection .projects .project .right{
        right: 0;
    }
    .home .fourthSection .projects .project .left{
        left: 0;
    }
    .home .fourthSection .projects .project .project-number {
        position: absolute;
        font-weight: 500;
        align-self: flex-start;
        font-size: 128px;
        line-height: 192px;
        padding-right: 40px;
        /* identical to box height */
    }

    .home .fourthSection .projects .project .project-content {
        position: absolute;
        align-self: flex-end;
        align-items: flex-end;
        gap: 24px;
    }

    .home .fourthSection .projects .project .project-content .image {
        width: 422px;
        height: 240px;
        object-fit: contain;
        border-radius: 10px;
        
    }



    .home .fourthSection .projects .project .project-content .project-main {
        width: 715px;
        align-items: flex-start;
        padding: 0px;
        gap: 6px;
    }

    .home .fourthSection .projects .project .project-content .project-main .margin {
        width: 121px;
        height: 6.3px;
    }

    .home .fourthSection .projects .project .project-content .project-main .project-title {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
    }
    .home .fourthSection .projects .project .project-content .project-info {
        align-self: flex-start;
        gap : 24px;
    }
    .home .fourthSection .projects .project .project-content .project-main .project-description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .home .fourthSection .projects .project .project-content .project-button {
        width: 126px;
        height: 48px;
        border: 2px solid #f8f8f8;
        border-radius: 3px;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;

        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
    }
    .home .fourthSection .margin{
        width: 961.59px;
        height: 0px;
    }
    .home .fourthSection  .box {
        height: 48px;
        width: 142px;

        align-items: center;
        justify-content: center;
    }

    .home .fourthSection  .box .text {
        height: 28.138957977294922px;
        width: 114.88965606689453px;
        left: 13.623046875px;
        top: 10.15380859375px;
        text-align: center;

        /* Label */
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        /* White Text */
    }

    .home .fourthSection .projects .content .hover-button {

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        height: 48px;
        width: 126px;
        left: 0px;
        top: 0px;
        border-radius: 3px;
        border: 2px solid #F8F8F8;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        align-items: center;
        text-align: center;

        /* White Text */

        color: #F8F8F8;

      }

      .home .fourthSection .projects  .content .hover-button svg{
        height: 14.29px ;
        width: 27.4px;
        position: absolute;
        right: -20px;
        background: #08090D;
    }    
    /* .home .fourthSection .projects .content .hover-button:hover svg{
        transition: all ease-in-out 500ms;
        transform: scalex(1.43);
        right: -25px;
    } */
    .home .fourthSection .view-all .hover-button {
        position: relative;
        padding: 19px 22px;
        transition: all 0.2s ease;
        border: none;
        background-color: transparent;
        gap: 14px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        color: #F4F4F4;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .home .fourthSection .view-all .hover-button:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border: 0.6px solid #F86A1A;
        width: 10px;
        height: 65px;
        transition: all 0.3s ease;
        background-color: #F86A1A;
      }
      .home .fourthSection .view-all  .hover-button span {
        position: relative;
        font-size: 16px;
        line-height: 18px;
        font-weight: 900;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        vertical-align: middle;
      }
      .home .fourthSection .view-all .hover-button svg{
        fill: #F05033;
      }
      
      .home .fourthSection .view-all  .hover-button:hover:before {
        width: 100%;
        background: #F05033;
        border: 1.4px solid #F05033;
      }
      .home .fourthSection .view-all  .hover-button:hover svg {
        transform: translateX(0);
        fill: #F4F4F4;
      }
      .home .fourthSection .view-all  .hover-button:active {
        transform: scale(0.96);
      }
}
/* Fifth Section */
.home .fifthSection {
    position: relative;
    width: 100vw;
    border-radius: 0px;
    background-color: #08090D;
}

.home .fifthSection .image {
    content: url('../images/home/fifthSection.png')
}

.home .fifthSection .content .info .title {
    color: #F8F8F8;
    font-family: 'Baloo 2';
    font-style: normal;
}

.home .fifthSection .content .info .title span:first-child {
    color: #0B2EAC;
}

.home .fifthSection .content .info .title span:last-child {
    color: #F05033;
}

.home .fifthSection .content .box {
    background: #F05033;
    border-radius: 3px;
    cursor: pointer;
}

.home .fifthSection .content .box .text {
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
    cursor: pointer;
}
.home .fifthSection .content .hover-button:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border: 0.6px solid #F86A1A;
    width: 10px;
    height: 65px;
    background-color: #f86a1a;
    transition: all 0.3s ease;
  }

.home .fifthSection .content .info .description {
    font-family: 'Poppins';
    font-style: normal;
    color: #F8F8F8;
}
@media (min-width: 360px ) and (max-width: 1024px) {
    .home .fifthSection {
        border-radius: 0px;
        padding: 80px 32px 80px 32px;      
        flex-direction: column-reverse;  
        gap: 32px;
        align-items: center;

    }

    .home .fifthSection .content {
        align-items: start;
        gap: 24px;
    }

    .home .fifthSection .content .info {
        align-items: start;
        padding: 0px;
        gap: 12px;

        width: 100%;
    }

    .home .fifthSection .image {
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

    .home .fifthSection .content .info .title {
        width: 100%;

        /* Heading H2 */
        align-self: stretch;
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;
    }

    .home .fifthSection .content .info .description {
        width: 100%;
        
        
        /* Text */
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
    }

    .home .fifthSection .content .box {
        height: 48px;
        width: 142px;

        border-radius: 3px;

        

        align-items: center;
        justify-content: center;
    }

    .home .fifthSection .content .box .text {
        height: 28.138957977294922px;
        width: 114.88965606689453px;

        text-align: center;

        /* Label */
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        /* White Text */
    }
    .home .fifthSection .content .hover-button {
        position: relative;
        padding: 19px 22px;
        transition: all 0.2s ease;
        border: none;
        background-color: transparent;
        gap: 14px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        color: #F4F4F4;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .home .fifthSection .content .hover-button:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border: 0.6px solid #F86A1A;
        width: 10px;
        height: 65px;
        background-color: #f86a1a;
        transition: all 0.3s ease;
      }
      .home .fifthSection .content .hover-button span {
        position: relative;
        font-size: 16px;
        line-height: 18px;
        font-weight: 900;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        vertical-align: middle;
      }
      .home .fifthSection .content  .hover-button svg{
        fill: #F05033;
      }
      
      .home .fifthSection .content .hover-button:hover:before {

        width: 10px;
        height: 65px;
        background: #F05033;
        border: 1.4px solid #F05033;
      }
      .home .fifthSection .content .hover-button:hover svg {
        transform: translateX(0);
        fill: #F4F4F4;
      }
      .home .fifthSection .content .hover-button:active {
        transform: scale(0.96);
      }
}

@media (min-width: 1024px ) and (max-width: 1440px) {
    .home .fifthSection {
        padding: 80px;
        gap: 30px;
        justify-content: space-evenly;
        align-items: center;
    }

    .home .fifthSection .content {
        align-items: start;
        gap: 24px;
    }

    .home .fifthSection .content .info {
        align-items: start;
        gap: 24px;
    }

    .home .fifthSection .image {
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

    .home .fifthSection .content .info .title {
        width: 100%;

        /* Heading H2 */
        align-self: stretch;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .home .fifthSection .content .info .description {
        width: 100%;
        
        /* Text */
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .home .fifthSection .content .box {
        width: 142px;
        

        align-items: center;
        justify-content: center;
    }

    .home .fifthSection .content .box .text {
        height: 28.138957977294922px;
        width: 114.88965606689453px;

        text-align: center;

        /* Label */
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        /* White Text */
    }
    .home .fifthSection .content .hover-button {
        position: relative;
        padding: 19px 22px;
        transition: all 0.2s ease;
        border: none;
        background-color: transparent;
        gap: 14px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        color: #F4F4F4;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .home .fifthSection .content .hover-button:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border: 0.6px solid #F86A1A;
        width: 10px;
        height: 65px;
        background-color: #f86a1a;
        transition: all 0.3s ease;
      }
      .home .fifthSection .content .hover-button span {
        position: relative;
        font-size: 16px;
        line-height: 18px;
        font-weight: 900;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        vertical-align: middle;
      }
      .home .fifthSection .content  .hover-button svg{
        fill: #F05033;
      }
      
      .home .fifthSection .content .hover-button:hover:before {
        width: 100%;
        background: #F05033;
        border: 1.4px solid #F05033;
      }
      .home .fifthSection .content .hover-button:hover svg {
        transform: translateX(0);
        fill: #F4F4F4;
      }
      .home .fifthSection .content .hover-button:active {
        transform: scale(0.96);
      }
}
@media (min-width: 1440px) {
    .home .fifthSection {
        padding: 80px;
        gap: 30px;
        justify-content: space-evenly;
        align-items: center;
    }

    .home .fifthSection .content {
        align-items: start;
        gap: 24px;
    }

    .home .fifthSection .content .info {
        align-items: start;
        gap: 24px;
    }

    .home .fifthSection .image {
        width: 630px;

        border-radius: 10px;
    }

    .home .fifthSection .content .info .title {
        width: 100%;

        /* Heading H2 */
        align-self: stretch;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .home .fifthSection .content .info .description {
        width: 570px;
        /* Text */
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .home .fifthSection .content .hover-button {
        position: relative;
        padding: 19px 22px;
        transition: all 0.2s ease;
        border: none;
        background-color: transparent;
        gap: 14px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        color: #F4F4F4;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .home .fifthSection .content .hover-button:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border: 0.6px solid #F86A1A;
        width: 10px;
        height: 65px;
        background-color: #f86a1a;
        transition: all 0.3s ease;
      }
      .home .fifthSection .content .hover-button span {
        position: relative;
        font-size: 16px;
        line-height: 18px;
        font-weight: 900;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        vertical-align: middle;
      }
      .home .fifthSection .content  .hover-button svg{
        fill: #F05033;
      }
      
      .home .fifthSection .content .hover-button:hover:before {
        width: 100%;
        background: #F05033;
        border: 1.4px solid #F05033;
      }
      .home .fifthSection .content .hover-button:hover svg {
        transform: translateX(0);
        fill: #F4F4F4;
      }
      .home .fifthSection .content .hover-button:active {
        transform: scale(0.96);
      }
}