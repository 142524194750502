.lastSection {
    /* background variation */
    background: #3a3b3a;
    align-items: center;
}
.lastSection .image {
    content: url('../images/lastSection.svg');
}
.lastSection .content .title {
    color: #F8F8F8;
    /* Heading H2 */

    font-family: 'Baloo 2';
    font-style: normal;
}
.lastSection .content .description {
    font-family: 'Poppins';
    font-style: normal;
    color: #F8F8F8;
}
.lastSection .content .box {
    background: #F05033;
    border-radius: 3px;
    cursor: pointer;
}
.lastSection .content .box .text {
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
    cursor: pointer;
}
@media (min-width: 360px ) and (max-width: 1023px) {
    .lastSection {
       padding: 32px 32px;
        gap: 21px;
       width: 100vw;
       flex-direction: column !important;
   }

    .lastSection .image {
       width: 100%;
       border-radius: 10px;
       height: 100%;
       object-fit: cover;
   }

    .lastSection .content {
        
       gap: 24px;
       width: 100%;
   }

    .lastSection .content .title {
       width: 100%;
       font-weight: 400;
       font-size: 36px;
       line-height: 120%;
   }

    .lastSection .content .description {
       width: 100%;
       font-weight: 400;
       font-size: 16px;
       line-height: 24px;
   }

    .lastSection .content .box {
       height: 48px;
       width: 156px;
       left: 0px;
       top: 0px;
       border-radius: 3px;
       align-items: center;
       justify-content: center;
   }

    .lastSection .content .box .text {
       height: 28.138957977294922px;
       width: 114.88965606689453px;
       left: 13.623046875px;
       top: 10.15380859375px;
       text-align: center;

       /* Label */
       font-weight: 500;
       font-size: 18px;
       line-height: 27px;
       /* identical to box height */
       /* White Text */
   }
   .lastSection .content .hover-button {
    position: relative;
    padding: 19px 22px;
    transition: all 0.2s ease;
    border: none;
    width: 250px;
    background-color: transparent;
    gap: 14px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #F4F4F4;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
   .lastSection .content .hover-button:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border: 0.6px solid #F86A1A;
    width: 10px;
    height: 65px;
    background-color: #f86a1a;
    transition: all 0.3s ease;
  }
   .lastSection .content .hover-button span {
    position: relative;
    font-size: 16px;
    line-height: 18px;
    font-weight: 900;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    vertical-align: middle;
  }
   .lastSection .content  .hover-button svg{
    fill: #F05033;
  }
  
   .lastSection .content .hover-button:hover:before {
    width: 100%;
    background: #F05033;
    border: 1.4px solid #F05033;
  }
   .lastSection .content .hover-button:hover svg {
    transform: translateX(0);
    fill: #F4F4F4;
  }
   .lastSection .content .hover-button:active {
    transform: scale(0.96);
  }
}
@media (min-width: 1024px ) and (max-width: 1440px) {
     .lastSection {
        padding: 0px 80px 0px 0px;
        gap: 80px;

        width: 100vw;
        height: 422px;
    }

     .lastSection .image {
        width: 426px;
        height: 421px;
        object-fit: cover;
    }

     .lastSection .content {
        gap: 24px;
        width: 438px;
        height: 351px;
    }

     .lastSection .content .title {
        width: 438px;
        height: 231px;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

     .lastSection .content .description {
        width: 438px;
        height: 24px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .lastSection .content .hover-button {
        position: relative;
        padding: 19px 22px;
        transition: all 0.2s ease;
        border: none;
        width: 250px;
        background-color: transparent;
        gap: 14px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #F4F4F4;
        display: flex;
        align-items: center;
        white-space: nowrap;
      }
       .lastSection .content .hover-button:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border: 0.6px solid #F86A1A;
        width: 10px;
        height: 65px;
        background-color: #f86a1a;
        transition: all 0.3s ease;
      }
       .lastSection .content .hover-button span {
        position: relative;
        font-size: 16px;
        line-height: 18px;
        font-weight: 900;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        vertical-align: middle;
      }
       .lastSection .content  .hover-button svg{
        fill: #F05033;
      }
      
       .lastSection .content .hover-button:hover:before {
        width: 100%;
        background: #F05033;
        border: 1.4px solid #F05033;
      }
       .lastSection .content .hover-button:hover svg {
        transform: translateX(0);
        fill: #F4F4F4;
      }
       .lastSection .content .hover-button:active {
        transform: scale(0.96);
      }
}
@media (min-width: 1440px) {
     .lastSection {
        padding: 0px 80px 0px 0px;
        gap: 80px;
        width: 100vw;
        height: 422px;
        justify-content: flex-start;
    }
    


     .lastSection .image {
        height: 422px;
    }

     .lastSection .content {
        gap: 24px;
        width: 100%;
        height: 274px;

    }

     .lastSection .content .title {
        width: 100%;
        height: 154px;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

     .lastSection .content .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
     .lastSection .content .hover-button {
        position: relative;
        padding: 19px 22px;
        transition: all 0.2s ease;
        border: none;
        width: 250px;
        background-color: transparent;
        gap: 14px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #F4F4F4;
        display: flex;
        align-items: center;
        white-space: nowrap;
      }
       .lastSection .content .hover-button:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border: 0.6px solid #F86A1A;
        width: 10px;
        height: 65px;
        background-color: #f86a1a;
        transition: all 0.3s ease;
      }
       .lastSection .content .hover-button span {
        position: relative;
        font-size: 16px;
        line-height: 18px;
        font-weight: 900;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        vertical-align: middle;
      }
       .lastSection .content  .hover-button svg{
        fill: #F05033;
      }
      
       .lastSection .content .hover-button:hover:before {
        width: 100%;
        background: #F05033;
        border: 1.4px solid #F05033;
      }
       .lastSection .content .hover-button:hover svg {
        transform: translateX(0);
        fill: #F4F4F4;
      }
       .lastSection .content .hover-button:active {
        transform: scale(0.96);
      }
}