.termsandcondition{
    width: 100vw;    
    background: #08090D;
}
.termsandcondition .header{
    color : white
}
.termsandcondition .header .title{
    color: #F8F8F8;
    font-family: 'Baloo 2';
    font-style: normal;
}
@media (min-width: 360px) and (max-width: 1024px) {
    .termsandcondition{
        
    }
    .termsandcondition .header{
        padding: 24px;
        width: 100%;
        gap:24px
    }
    .termsandcondition .header .title{
        font-weight: 400;
        font-size: 64px;
        line-height: 120%; 
    }
    .termsandcondition .description{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        white-space: pre-line;
        /* White Text */
    }
    .termsandcondition .margin{
        width: 100%;
        height: 2px;
        background-color: gray;
    }
}
@media (min-width: 1024px) and (max-width: 1440px) {
    .termsandcondition{
        height: 100vh;
        padding: 80px;
    }
    .termsandcondition .header{
        width: 100%;
        gap:24px
    }
    .termsandcondition .header .title{
        font-weight: 400;
        font-size: 64px;
        line-height: 120%; 
    }
    .termsandcondition .description{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        white-space: pre-line;
        /* White Text */
    }
    .termsandcondition .margin{
        width: 100%;
        height: 2px;
        background-color: gray;
    }
}
@media (min-width: 1440px){
    .termsandcondition{
        height: 100vh;
        padding: 120px;
    }
    .termsandcondition .header{
        width: 100%;
        gap:24px
    }
    .termsandcondition .header .title{
        font-weight: 400;
        font-size: 64px;
        line-height: 120%; 
    }
    .termsandcondition .description{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        white-space: pre-line;
        /* White Text */
    }
    .termsandcondition .margin{
        width: 100%;
        height: 2px;
        background-color: gray;
    }
}