/*
First Section
*/

.gallery .firstSection {
    position: relative;
    width: 100vw;

    background-size: cover;
    background: linear-gradient(90.15deg, #0C101C -10.1%, rgba(12, 16, 28, 0) 106.97%);
    background-color:#08090D;
}

.gallery .firstSection .content .title {
    color: #f8f8f8;
    font-family: "Baloo 2";
    font-style: normal;
}

.gallery .firstSection .content .description {
    font-family: "Poppins";
    font-style: normal;
    color: #ffffff;
}

.gallery .firstSection .content .margin {
    background: #f05033;
}
@media (min-width: 360px) and (max-width: 1024px) {
    .gallery .firstSection {
        height: 100vh;
        width: 100vw;
        flex-direction: row;
        gap: 13px;
    }

    .gallery .firstSection .content {
        position: absolute;
        width: 257px;
        left: 32px;
        top: 100px;
        gap: 14px;
    }

    .gallery .firstSection .content .title {
        /* Heading H1 */
        font-weight: 440;
        font-size: 36px;
        line-height: 120%;
        /* or 89px */
        /* White Text */
    }

    .gallery .firstSection .content .description {
        width: 260px;
        height: 192px;
        /* Heading H6 */

        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        /* or 133% */
    }

    .gallery .firstSection .buttons {
        display: none;
        position: absolute;
        height: 6px;
        width: 220px;
        left: calc((100vw/2) - 80px);
        top: 846px;
        gap: 20px;
        border-radius: 0px;
    }

    .gallery .firstSection .buttons .button {
        height: 6px;
        width: 40px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        cursor: pointer;
    }

    .gallery .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .gallery .firstSection {
        height: 100vh;    
    }

    .gallery .firstSection .content {
        position: absolute;
        width: 784px;
        left: 80px;
        top: 300.7px;
        gap: 24px;
    }

    .gallery .firstSection .content .title {
        width: 784px;

        /* Heading H1 */
        font-weight: 440;
        font-size: 64px;
        line-height: 106%;
        /* or 89px */
        /* White Text */
    }

    .gallery .firstSection .content .description {
        width: 1024px;
        /* Heading H6 */

        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
        /* or 133% */
    }

    .gallery .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}

@media (min-width: 1440px) {
    .gallery .firstSection {
        height: 100vh;
        padding: 82px;
        justify-content: center;
    }

    .gallery .firstSection .content {
        width: 1171px;
        gap: 22px;
    }

    .gallery .firstSection .content .title {

        /* Heading H1 */
        font-weight: 440;
        font-size: 84px;
        line-height: 106%;
        /* or 89px */
        /* White Text */
    }

    .gallery .firstSection .content .description {
        /* Heading H6 */
        width: 75%;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        /* or 133% */
    }

    .gallery .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}
/* Second Section*/
.gallery .secondSection{
    background-color: #08090D;
}
@media (min-width: 360px) and (max-width: 1024px) {
    .gallery .secondSection{
        padding: 20px ;
    }
}
@media (min-width: 1024px) and (max-width: 1440px) {
    .gallery .secondSection{
        padding: 80px ;
    }
}
@media (min-width: 1440px) {
.gallery .secondSection{
    padding: 156px ;
}
}