/*
First Section
*/
.innovation .firstSection {
    position: relative;
    width: 100vw;

    background-size: cover;
    background-image: linear-gradient(90.15deg,
            #0c101c -10.1%,
            rgba(12, 16, 28, 0) 106.97%),
        url("../images/innovation/firstSection.jpg");
}

.innovation .firstSection .content .legend .legend-title{
    /* identical to box height, or 133% */
    
    font-family: 'Poppins';
    font-style: normal;
    /* White Text */
    color: #F8F8F8;
    
}
.innovation .firstSection .content .header .title {
    color: #f8f8f8;
    font-family: "Baloo 2";
    font-style: normal;
}

.innovation .firstSection .content .header .description {
    font-family: "Poppins";
    font-style: normal;
    color: #ffffff;
}

.innovation .firstSection .content .header .margin {
    background: #f05033;
}

@media (min-width: 360px) and (max-width: 1024px) {
    .innovation .firstSection {
        height: 100vh;
        padding: 47px 23px 38px 32px;
        justify-content: center;
    }
    .innovation .firstSection .content {
        width: 100%;
        gap: 22px;
        justify-content: space-around;
    }
    .innovation .firstSection .content .legend {
        width: 100%;
        gap:  6px;
    }
    .innovation .firstSection .content .legend .legend-title{
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }
    .innovation .firstSection .content .legend .margin{
      height: 1px;
      width: 100%;
      background: #D9D9D9;
    }
    .innovation .firstSection .content .header {
        padding: 0px 12px 0px 0px;
        gap: 12px;
    }
    .innovation .firstSection .content .header .title {
        width: 322px;

        /* Heading H1 */
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;
        /* or 89px */
        /* White Text */
    }

    .innovation .firstSection .content .header .description {
        width: 100%;
        /* Heading H6 */
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        /* or 133% */
    }

    .innovation .firstSection .content  .header .margin {
        width: 192.53px;
        height: 6.3px;
    }
}
@media (min-width: 1024px) and (max-width: 1440px) {
    .innovation .firstSection {
        height: 100vh;
        padding: 82px;
    }
    .innovation .firstSection .content {
        width: 100%;
        gap: 22px;
        height : 100%;
        justify-content: space-between;
    }
    .innovation .firstSection .content .legend {
        width: 100%;
        gap:  10px;
    }
    .innovation .firstSection .content .legend .legend-title{
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
    }
    .innovation .firstSection .content .legend .margin{
      height: 1px;
      width: 100%;
      background: #D9D9D9;
    }
    .innovation .firstSection .content {
        width: 900px;
        gap: 24px;
    }
    .innovation .firstSection .content .header {
        padding: 0px 12px 0px 0px;
        gap: 24px;
    }
    .innovation .firstSection .content .header .title {
        width: 900px;

        /* Heading H1 */
        font-weight: 440;
        font-size: 84px;
        line-height: 106%;
        /* or 89px */
        /* White Text */
    }

    .innovation .firstSection .content .header .description {
        width: 100%;
        height: 84px;
        /* Heading H6 */

        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
        /* or 133% */
    }

    .innovation .firstSection .content  .header .margin {
        width: 192.53px;
        height: 6.3px;
    }
}
@media (min-width: 1440px) {
    .innovation .firstSection {
        height: 100vh;
        padding: 82px;
        justify-content: center;
    }

    .innovation .firstSection .content {
        width: 100%;
        gap: 22px;
        height : 100%;
        justify-content: space-between;
    }
    .innovation .firstSection .content .legend {
        width: 100%;
        gap:  10px;
    }
    .innovation .firstSection .content .legend .legend-title{
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
    }
    .innovation .firstSection .content .legend .margin{
      height: 1px;
      width: 100%;
      background: #D9D9D9;
    }
    .innovation .firstSection .content .header {
        padding: 0px 12px 0px 0px;
        gap: 24px;
    }
    .innovation .firstSection .content .header .title {
        /* Heading H1 */
        width: 1069px;
        font-weight: 440;
        font-size: 84px;
        line-height: 106%;
    }

    .innovation .firstSection .content .header .description {
        height: 84px;
        width: 905px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
    }

    .innovation .firstSection .content .header .margin {
        width: 192.53px;
        height: 6.3px;
    }

}
/*Second Section*/
.innovation .secondSection .cards{
    background: #08090D;    
}
.innovation .secondSection .cards .card .card-content .card-title{
    color: #F8F8F8;
    font-family: 'Baloo 2';
    font-style: normal;
}
.innovation .secondSection .cards .card .card-content .card-info{
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
}

@media (min-width: 360px) and (max-width: 1024px) {
    .innovation .secondSection .cards{
        align-items: center;
    }
    .innovation .secondSection .cards .card{
        align-items: center;
        padding: 62px;
        gap: 80px;
        flex-direction: column !important;

    } 
    .innovation .secondSection .cards .card .image{
        width: 100%;
        height: 267px;
        border-radius: 10px;
    }
    .innovation .secondSection .cards .card .card-content{
        align-items: flex-start;
        flex-direction: column;
        padding: 0px;
        gap: 24px;
        width: 100%;
    }
    .innovation .secondSection .cards .card .card-content .card-title{
        width: 100%;
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;   
    }
    .innovation .secondSection .cards .card .card-content .card-info{
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        /* White Text */
              
    }
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .innovation .secondSection .cards{
        align-items: center;
    }
    .innovation .secondSection .cards .card{
        align-items: center;
        padding: 62px;
        gap: 80px;

    } 
    .innovation .secondSection .cards .card .image{
        width: 100%;
        height: 460px;
        border-radius: 10px;
    }
    .innovation .secondSection .cards .card .card-content{
        align-items: flex-start;
        padding: 0px;
        gap: 24px;
        width: 100%;
    }
    .innovation .secondSection .cards .card .card-content .card-title{
        width: 100%;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;        
    }
    .innovation .secondSection .cards .card .card-content .card-info{
        width: 100%;

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* White Text */
              
    }
}

@media (min-width: 1440px) {
    .innovation .secondSection .cards{
        align-items: center;
    }
    .innovation .secondSection .cards .card{
        align-items: center;
        padding: 80px;
        gap: 80px;

        width: 100%;
    } 
    .innovation .secondSection .cards .card .image{
        width: 630px;
        height: 560px;
        border-radius: 10px;
    }
    .innovation .secondSection .cards .card .card-content{
        align-items: flex-start;
        padding: 0px;
        gap: 24px;
        width: 100%;
    }
    .innovation .secondSection .cards .card .card-content .card-title{
        width: 100%;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;        
    }
    .innovation .secondSection .cards .card .card-content .card-info{
        width: 100%;
        white-space: pre-line;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* White Text */
              
    }
}
