@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow: scroll;
  overflow-x: hidden;
}


.navbar-element-list.disabled {

  opacity: 0.3;
  pointer-events: none;

}




::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ff0000;
}

/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  background: #3a3b3a;
}

/* Footer */

.navbar .navbar-element-list {
  font-family: "Poppins";
  color: #f8f8f8;
  font-style: normal;
  margin: -5px;
  
}



.navbar .navbar-container .navbar-element-buttons .menu-icon:hover {

  transform: scale(1.3);
  transition: all ease 0.5ms;

}

.navbar .search-button {
  background: #f05033;
  margin: 0.3em;
}

.navbar .search-button:hover {
  transform: scale(1.2);
  transition: all ease-in-out;
}
.navbar .navbar-element .logo:hover {
  transform: scale(1.2);
  transition: all ease-in-out;
}

.nav .navbar-elements-drop .selected{
  transform: rotate(-180deg);
}
.mainlayout{
  transition: all .2s ease-out;
}
@media (min-width: 360px) and (max-width: 1023px) {
  .mainlayout {
    margin-top: 86px;

  }

  .navbar{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px;
    gap: 30px;
    position: fixed;
    top: 0%;
    width: 100vw;
    z-index: 2;
  }
  .navbar .nav{
    width: 100vw;
    height: 86px;
    justify-content:  center;
    overflow: hidden;
  }
  .navbar .navbar-container {
    justify-content:space-between;
  }

  .navbar .navbar-container .navbar-element {
    gap: 42px;
  }

  .navbar-element-list.disabled {

    opacity: 0.3;

  }
  .navbar .navbar-container .navbar-element .navbar-header-elements:nth-last-child(2){
    display: none;
  }
  .navbar .navbar-container .show{
    right: 0% !important;
  }
  .navbar .navbar-container .navbar-element .navbar-header-elements {
    width: 300px;
    min-height: 100vh;
    background-color: #222327;
    position: absolute;
    padding: 30px 30px;
    top:86px;
    flex-direction: column;
    transition: all ease-in-out 500ms;
    gap: 36px;
    right: -100%;
  }
  .navbar .navbar-container .navbar-element-buttons{
    gap: 40px;
  }
  .navbar .navbar-container .navbar-element-buttons .navbar-header-elements {
    display: none;
  }
  /* .navbar .navbar-element-buttons .menu-icon{
    display: inline;
  } */
  .navbar .navbar-container .element-contain {
    align-items: center;
  }

  .navbar .navbar-container .element-contain .margin {
    height: 2px;
    width: 50%;
    background: linear-gradient(128.01deg, #0a32be 7.5%, #fe3d00 100.05%);
  }


  .navbar .navbar-element .logo {
    cursor: pointer;
    width: 103.37px;
    height: 35.68px;
  }

  
  .navbar .navbar-element .logo:hover {
    transform: scale(1.2);
    transition: all ease-in-out 50ms
  }
  

  .navbar .navbar-element-list {
    width: 100%;
    cursor: pointer;
    white-space: nowrap;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }

  

  .navbar .search-button {
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 26px;
    height: 36px;
    width: 36px;
    left: 0px;
    top: 0px;
    border-radius: 60px;
    cursor: pointer;
  }
  .nav .navbar-container .navbar-element .expands{
    display: flex !important;
  }
  .nav .navbar-element-sublist{
    display: none;
    position: absolute;
    width: 100vw;
    height: 240px;
    top : 86px;
    left: 0;
    background: #222327;
    padding: 10px 10px 0px  70px;
    flex-wrap: wrap;
    align-content: flex-start
  }
  .nav  .navbar-element-sublist .navbar-element .icon{

  }

  /* CSS */
.navbar-element-buttons {
  opacity: 0.5; /* Set low opacity */
}

.navbar-element-buttons:hover {
  pointer-events: none; /* Disable hover effects */
}

  .nav  .navbar-element-sublist .navbar-element{
    align-items: center;
    padding: 10px;
    gap: 16px;
    height: 56px;
  }
  .navbar .navbar-element-list:hover, .navbar .active {
    color : #F05033 !important;
  }
  .navbar .nav .navbar-container .navbar-element .navbar-header-elements .navbar-sublist-elements 
  {
    margin-top:20px;
    gap : 12px;
    background: #3E3E42;

    padding:10px 30px 10px 30px ;

  }
  .navbar .nav .navbar-container .navbar-element .navbar-header-elements .navbar-sublist-elements .navbar-element-list{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    white-space: pre-wrap;
    color: #F8F8F8;
  }
  .navbar .nav .navbar-container .navbar-element .navbar-header-elements .navbar-elements-drop .navbar-element .icons
  {
    display: none;
  }
  .navbar .nav .navbar-container .navbar-element .navbar-header-elements .navbar-elements-drop .navbar-element-list{
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 1024px) and (max-width: 1439px) {
  .mainlayout {
    margin-top: 86px;

  }

  .navbar{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 80px;
    gap: 30px;
    position: fixed;
    top: 0%;
    width: 100vw;
    z-index: 2;
  }
  .navbar .nav{
    width: 100vw;
    height: 86px;
    justify-content:  center;
    overflow: hidden;
  }
  .navbar .navbar-container {
    justify-content:space-around;
  }

  .navbar .navbar-container .navbar-element {
    gap: 42px;
  }
  .navbar .navbar-container .navbar-element .navbar-header-elements:nth-last-child(2){
    display: none;
  }
  .navbar .navbar-container .show{
    right: 0% !important;
  }
  .navbar .navbar-container .navbar-element .navbar-header-elements {
    width: 450px;
    background-color: #222327;
    position: absolute;
    padding: 30px 30px;
    top:86px;
    flex-direction: column;
    transition: all ease-in-out 500ms;
    gap: 36px;
    right: -100%;
  }
  .navbar .navbar-container .navbar-element-buttons{
    gap: 10px;
  }
  .navbar .navbar-container .navbar-element-buttons .navbar-header-elements {
    display: none;
  }
  /* .navbar .navbar-element-buttons .menu-icon{
    display: inline;
  } */
  .navbar .navbar-container .element-contain {
    align-items: center;
  }

  .navbar .navbar-container .element-contain .margin {
    height: 2px;
    width: 50%;
    background: linear-gradient(128.01deg, #0a32be 7.5%, #fe3d00 100.05%);
  }

  .navbar .navbar-element-buttons .navbar-header-elements {
  }

  .navbar .navbar-element .logo {
    cursor: pointer;
    width: 103.37px;
    height: 35.68px;
  }

  .navbar .navbar-element-list {
    width: 100%;
    cursor: pointer;
    white-space: nowrap;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }


  /* CSS */
.navbar-element-buttons {
  opacity: 0.5; /* Set low opacity */
}

.navbar-element-buttons:hover {
  pointer-events: none; /* Disable hover effects */
}

  

  .navbar .search-button {
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 26px;
    height: 36px;
    width: 36px;
    left: 0px;
    top: 0px;
    border-radius: 60px;
    cursor: pointer;
  }
  .nav .navbar-container .navbar-element .expands{
    display: flex !important;
  }
  .nav .navbar-element-sublist{
    display: none;
    position: absolute;
    width: 100vw;
    height: 240px;
    top : 86px;
    left: 0;
    background: #222327;
    padding: 10px 10px 0px  70px;
    flex-wrap: wrap;
    align-content: flex-start
  }
  .nav  .navbar-element-sublist .navbar-element .icon{

  }
  .nav  .navbar-element-sublist .navbar-element{
    align-items: center;
    padding: 10px;
    gap: 16px;
    height: 56px;
  }
  .navbar .navbar-element-list:hover,.active {
    color : #F05033 !important;
  }
  .navbar .nav .navbar-container .navbar-element .navbar-header-elements .navbar-sublist-elements 
  {
    margin-top:20px;
    gap : 12px;
    background: #222327;

    padding:10px 30px 10px 30px ;

  }
  .navbar .nav .navbar-container .navbar-element .navbar-header-elements .navbar-sublist-elements .navbar-element-list{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #F8F8F8;
  }
  .navbar .nav .navbar-container .navbar-element .navbar-header-elements .navbar-elements-drop .navbar-element .icons
  {
    display: none;
  }
  .navbar .nav .navbar-container .navbar-element .navbar-header-elements .navbar-elements-drop .navbar-element-list{
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 1440px) {
  .mainlayout {
    margin-top: 86px;
  }

  .navbar{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 80px;
    gap: 30px;
    position: fixed;
    top: 0%;
    width: 100vw;
    z-index: 2;
  }
  .navbar .nav{
    width: 100vw;
    height: 86px;
    justify-content:  center;
    overflow: hidden;
  }
  .navbar .navbar-container {
    justify-content: space-between;
  }

  .navbar .navbar-container .navbar-element {
    gap: 42px;
  }

  .navbar .navbar-container .navbar-element .navbar-header-elements {
    align-items: center;
    gap: 36px;
  }

  .navbar .navbar-container .navbar-element-buttons .navbar-header-elements {
    align-items: center;
    gap: 36px;
  }

  .navbar .navbar-container .element-contain {
    align-items: center;
  }

  .navbar .navbar-container .element-contain .margin {
    height: 2px;
    width: 50px;
    background: linear-gradient(128.01deg, #0a32be 7.5%, #fe3d00 100.05%);
  }

  .navbar .navbar-element-buttons .navbar-header-elements {
    width: 305px;
  }
  .navbar .navbar-element-buttons .menu-icon{
    display: none;
  }
  .navbar .navbar-element .logo {
    cursor: pointer;
    width: 103.37px;
    height: 35.68px;
  }

  .navbar .navbar-element-list {
    cursor: pointer;
    white-space: nowrap;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }


  .navbar .search-button {
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 26px;
    height: 36px;
    width: 36px;
    left: 0px;
    top: 0px;
    border-radius: 60px;
    cursor: pointer;
  }
  .nav .expands{
    display: flex !important;
  }
  .nav .navbar-element-sublist{
    display: none;
    position: absolute;
    width: 100vw;
    height: 350px;
    top : 86px;
    left: 0;
    background: #222327;
    padding: 10px 10px 0px  70px;
    flex-wrap: wrap;
    align-content: flex-start;
    column-gap: 90px;
  }
  .nav  .navbar-element-sublist .navbar-element .icons{
    width: 24px;
    height: 24px;
  }
  .navbar .nav .navbar-container .navbar-element .navbar-header-elements .navbar-sublist-elements{ 
    display : none;
  }
  .navbar .navbar-container .navbar-element .navbar-header-elements .navbar-elements-drop:nth-last-child(-n+2){
    display: none;width: 24px;
    height: 24px;
  }
  .nav  .navbar-element-sublist .navbar-element{
    align-items: center;
    padding: 10px;
    gap: 16px;
    height: 56px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nav  .navbar-element-sublist .navbar-element-sublist-container{
    position: relative;
    flex-wrap: wrap;
    height: 250px;
    left: 150px;
    top: 30px;
    column-gap: 30px;
    row-gap: 30px;
  }
  .nav .navbar-element-sublist .navbar-element .navbar-element-list{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px !important;
    line-height: 36px;
    /* identical to box height */

    /* White Text */
    color: #F8F8F8;
    white-space: break-spaces;
    max-width: 400px;

  }
  .navbar .navbar-element-list:hover{
    border-bottom: solid;
    border-bottom-width: 2px;
    border-bottom-color: #F05033;
  }
  .navbar .active {
    color : #F05033 !important;
    border-bottom: solid;
    border-bottom-width: 2px;
    border-bottom-color: #F05033;
  }
  .navbar .navbar-element-sublist .navbar-element .navbar-element-list:hover, .navbar  .navbar-element-sublist .active {
    color : #F8F8F8 !important;
  }
  .navbar .nav .navbar-container .navbar-element .navbar-header-elements .navbar-elements-drop .drop-icons{
    display: none;
  }

}

/*Footer*/
.footer .footer-container .footer-right-main .footer-element {
  color: #F8F8F8;
  font-family: 'Poppins';
  font-style: normal;  height: 86px;
  justify-content:  center;
}
.footer {
  background: #3a3b3a;
  border-top: 1px solid #666666;
}

.footer .footer-container .main .footer-contents .note {
  color: #F8F8F8;
  font-family: 'Poppins';
  font-style: normal;
}
.footer .footer-container .main .footer-contents .button
{
  background: #F05033;
  color: #F4F4F4;
  font-family: 'Poppins';
  font-style: normal;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.footer .credits .credit-info{
  font-family: 'Poppins';
  font-style: normal;    
  color: #F8F8F8;
}
.footer .footer-container .footer-right-main .footer-element:hover, .footer  .active {
  color : #F05033 !important;
}
.footer .subscribe-button .button:hover {
  background-color: #ee3434 !important;
  border: 1px solid #f8f8f8 !important;
  cursor: pointer !important;
}
.footer .icons:hover{
  background-color: #ee3434 !important;

}
@media (min-width: 360px) and (max-width: 1024px) {
  .footer {
    align-items: center;
    gap: 32px;
    justify-content: center;
    width: 100vw;
    /* background variation */
    background: #3a3b3a;
    border-top: 1px solid #666666;
  }

  .footer .footer-container {
    margin-top: 30px;
    gap: 60px;
    flex-direction: column;
    width: 360px;
  }

  .footer .logo {
    width: 103.37px;
    height: 35.68px;
  }

  .footer .footer-container .main {
    align-items: flex-start;
    padding: 0px;
    gap: 31px;
    width: 360px;
  }

  .footer .footer-container .main .footer-contents {
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 360px;
  }

  .footer .footer-container .main .subscribe-button {
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    flex-direction: column;
    width: 360px;
  }

  .footer .footer-container .main .subscribe-button .input {
    align-items: center;
    padding: 12px;
    gap: 8px;

    width: 90%;
    height: 48px;

    /* White Text */

    background: #F8F8F8;
    /* Background */

    border: 1px solid #08090D;
  }

  .footer .footer-container .main .subscribe-button .button {
    width: 142px;
    height: 48px;
    border-radius: 3px;
    


    font-weight: 500;
    font-size: 18px;
    line-height: 27px;

  }

  .footer .footer-container .main .footer-contents .footer-subcontents {
    align-items: flex-start;
    padding: 0px;
    gap: 32px;

    width: 343px;
  }

  .footer .footer-container .main .footer-contents .footer-subcontents .info {
    align-items: flex-start;
    padding: 0px;
    gap: 4px;

    width: 343px;

  }

  .footer .footer-container .main .footer-contents .note {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .footer .footer-container .footer-right-main {
    align-items: flex-start;
    padding: 0px;
    gap: 24px;

    height: 182px;
    flex-wrap: wrap;
    column-gap: 100px;
  }


  .footer .footer-container .footer-right-main .footer-element {
    height: 27px;

    /* Label */

    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */


    /* White Text */


  }

  .footer .margin {
    width: 360px;
    height: 1px;
    

    /* White Text */

    background: #F8F8F8;

  }
  .footer .credits{
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    width: 360px;
    flex-direction: column;
  }
  .footer .credits .credit-info{
    font-weight: 400;

    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    /* White Text */
  }
  .footer .credits .social-media{
    height: 34px;
    gap : 16px;
    align-items : flex-start;
  }
  .footer .icons
  {
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 34px;
    border-radius: 60px;
    background: #393939;
  }

  .footer .credits .social-media .social-media-icon{

    left: 7px;
    top: 3px;
    border-radius: 0px;    
    object-fit: fill;
  }
}
@media (min-width: 1024px) and (max-width: 1439px) {
  .footer {
    align-items: center;
    gap: 32px;
    justify-content: center;
    width: 100vw;
    /* background variation */
    background: #3a3b3a;
    border-top: 1px solid #666666;
  }

  .footer .footer-container {
    margin-top: 30px;
    gap: 60px;
    flex-direction: column;
    width: 896px;
  }

  .footer .logo {
    width: 103.37px;
    height: 35.68px;
  }

  .footer .footer-container .main {
    align-items: flex-start;
    padding: 0px;
    gap: 31px;
    width: 750px;
    height: 248.68px;
  }

  .footer .footer-container .main .footer-contents {
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 633px;
    height: 160px;
  }

  .footer .footer-container .main .subscribe-button {
    align-items: flex-start;
    padding: 0px;
    gap: 16px;

    width: 633px;
    height: 48px;
  }

  .footer .footer-container .main .subscribe-button .input {
    align-items: center;
    padding: 12px;
    gap: 8px;

    width: 479px;
    height: 48px;

    /* White Text */

    background: #F8F8F8;
    /* Background */

    border: 1px solid #08090D;
  }

  .footer .footer-container .main .subscribe-button .button {
    width: 142px;
    height: 48px;
    border-radius: 3px;

    font-weight: 500;
    font-size: 18px;
    line-height: 27px;

  }

  .footer .footer-container .main .footer-contents .footer-subcontents {
    align-items: flex-start;
    padding: 0px;
    gap: 32px;

    width: 343px;
    height: 182px;
  }

  .footer .footer-container .main .footer-contents .footer-subcontents .info {
    align-items: flex-start;
    padding: 0px;
    gap: 4px;

    width: 343px;
    height: 79px;

  }

  .footer .footer-container .main .footer-contents .note {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .footer .footer-container .footer-right-main {
    align-items: flex-start;
    padding: 0px;
    gap: 24px;

    height: 182px;
    flex-wrap: wrap;
    column-gap: 100px;
  }

  .footer .footer-container .footer-right-main .footer-element {
    height: 27px;

    /* Label */

    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */


    /* White Text */


  }

  .footer .margin {
    width: 896px;
    height: 1px;
    

    /* White Text */

    background: #F8F8F8;

  }
  .footer .credits{
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 32px;
    width: 896px;
    height: 67px;
  }
  .footer .credits .credit-info{
    font-weight: 400;

    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    /* White Text */
  }
  .footer .credits .social-media{
    height: 34px;
    gap : 16px;
    align-items : flex-start;
  }
  .footer .icons
  {
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 34px;
    border-radius: 60px;
    background: #393939;
  }
  .footer .credits .social-media .social-media-icon{

    left: 7px;
    top: 3px;
    border-radius: 0px;    
    object-fit: fill;
  }
}
@media (min-width: 1440px) {
  .footer {
    align-items: center;
    gap: 32px;
    justify-content: center;
    width: 100vw;
    height: 429.68px;

    /* background variation */
    background: #3a3b3a;
    border-top: 1px solid #666666;
  }

  .footer .footer-container {
    width: 1295px;
    gap: 132px;
  }

  .footer .logo {
    width: 103.37px;
    height: 35.68px;
  }

  .footer .footer-container .main {
    align-items: flex-start;
    padding: 0px;
    gap: 31px;

    width: 750px;
    height: 248.68px;
  }

  .footer .footer-container .main .footer-contents {
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 633px;
    height: 160px;
  }

  .footer .footer-container .main .subscribe-button {
    align-items: flex-start;
    padding: 0px;
    gap: 16px;

    width: 633px;
    height: 48px;
  }

  .footer .footer-container .main .subscribe-button .input {
    align-items: center;
    padding: 12px;
    gap: 8px;

    width: 479px;
    height: 48px;

    /* White Text */

    background: #F8F8F8;
    /* Background */

    border: 1px solid #08090D;
  }

  .footer .footer-container .main .subscribe-button .button {
    width: 142px;
    height: 48px;
    border-radius: 3px;
    


    font-weight: 500;
    font-size: 18px;
    line-height: 27px;

  }

  .footer .footer-container .main .footer-contents .footer-subcontents {
    align-items: flex-start;
    padding: 0px;
    gap: 32px;

    width: 343px;
    height: 182px;
  }

  .footer .footer-container .main .footer-contents .footer-subcontents .info {
    align-items: flex-start;
    padding: 0px;
    gap: 4px;

    width: 343px;
    height: 79px;

  }

  .footer .footer-container .main .footer-contents .note {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .footer .footer-container .footer-right-main {
    align-items: flex-start;
    padding: 0px;
    gap: 24px;

    height: 182px;
    flex-wrap: wrap;
    column-gap: 100px;
  }

  .footer .footer-container .footer-right-main .footer-element {
    height: 27px;

    /* Label */

    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */


    /* White Text */


  }

  .footer .margin {
    width: 1312px;
    height: 1px;

    /* White Text */

    background: #F8F8F8;

  }
  .footer .credits{
    justify-content: space-between;
    align-items: flex-end;
    padding: 0px;
    gap: 64px;

    width: 1312px;
    height: 34px;
  }
  .footer .credits .credit-info{
    font-weight: 400;

    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    /* White Text */
  }
  .footer .credits .social-media{
    height: 34px;
    gap : 16px;
    align-items : flex-start;
  }
  .footer .icons
  {
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 34px;
    border-radius: 60px;
    background: #393939;
  }
  .footer .credits .social-media .social-media-icon{

    left: 7px;
    top: 3px;
    border-radius: 0px;    
    object-fit: fill;
  }
}


/* Loading Screen */
.splash-container {
  align-items: center;
  gap:0px;
  justify-content: center;
  background: #000;
  height: 100vh;
  width: 100vw;
  animation: lift 1.6s ease-in-out;
  animation-delay: 2.2s;
  animation-fill-mode:both;
  position: absolute;
  overflow: hidden;
  z-index: 4;
}

.splash-container .title {
  font-size: 64px;
  color: #fff;
  text-align: center;
  font-family: "Oswald", sans-serif;
  animation: wave 0.4s, jump 3s;
  position: relative;
  top: 0;
  padding: 4px;
  opacity: 0;
  z-index: 3;
  animation-fill-mode: both;
}
.splash-container .subtitle {
  font-size: 24px;
  color: #fff;
  text-align: center;
  font-family: "Oswald", sans-serif;
}
.splash-container .title:nth-of-type(1) {
  animation: wave 0.4s, jump 3s ease-in-out alternate 0.6s;
}

.splash-container .title:nth-of-type(2) {
  animation: wave 0.8s, jump 7s ease-in-out alternate 0.6s;
}
@keyframes wave {
  0% {
    top: 0%;
  }
  100% {
    top: 100%;
  }
}
@keyframes jump {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
  90% {
    transform: translate3d(0, -16%, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, -32%, 0);
    opacity: 1;
  }
}
@keyframes lift {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    visibility: visible;
  }
  25% {
    transform: translate3d(0, -25%, 0);
    opacity: 1;
    visibility: visible;
  }
  50% {
    transform: translate3d(0, -50%, 0);
    opacity: 1;
    visibility: visible;
  }
  75% {
    transform: translate3d(0, -75%, 0);
    opacity: 1;
    visibility: visible;
  }
  100% {
    transform: translate3d(0, -100%, 0);
    opacity: 0.6;
    visibility: hidden;
  }
}
@keyframes appear {
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}