/*
First Section
*/
.sustainability .firstSection {
    position: relative;
    width: 100vw;

    background-size: cover;
    background-image: linear-gradient(90.15deg,
    #0c101c -10.1%,
    rgba(12, 16, 28, 0) 106.97%),
url("../images/sustainability/firstSection.png");
}

.sustainability .firstSection .content .title {
    color: #f8f8f8;
    font-family: "Baloo 2";
    font-style: normal;
}

.sustainability .firstSection .content .description {
    font-family: "Poppins";
    font-style: normal;
    color: #ffffff;
}

.sustainability .firstSection .content .margin {
    background: #f05033;
}
@media (min-width: 360px) and (max-width: 1024px) {
    .sustainability .firstSection {
        height: calc(100vh - (86px));
        padding: 36px 32px 36px 36px;        
        justify-content: flex-end;
    }

    .sustainability .firstSection .content {
        gap: 24px;
    }
    .sustainability .firstSection .content .main{
        gap : 12px;
    }
    .sustainability .firstSection .content .title {

        /* Heading H1 */
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;
        /* or 89px */
        /* White Text */
    }

    .sustainability .firstSection .content .description {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;

    }

    .sustainability .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}
@media (min-width: 1024px) and (max-width: 1440px) {
    .sustainability .firstSection {
        height: calc(100vh - (86px));
        padding: 82px;
        justify-content: flex-end;
    }

    .sustainability .firstSection .content {
        gap: 22px;
    }

    .sustainability .firstSection .content .title {

        /* Heading H1 */
        font-weight: 440;
        font-size: 84px;
        line-height: 106%;
        /* or 89px */
        /* White Text */
    }

    .sustainability .firstSection .content .description {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;

    }

    .sustainability .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}
@media (min-width: 1440px) {
    .sustainability .firstSection {
        height: calc(100vh - (86px));
        padding: 82px;
        justify-content: flex-end;
    }

    .sustainability .firstSection .content {
        gap: 22px;
    }

    .sustainability .firstSection .content .title {

        /* Heading H1 */
        font-weight: 440;
        font-size: 84px;
        line-height: 106%;
        /* or 89px */
        /* White Text */
    }

    .sustainability .firstSection .content .description {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;

    }

    .sustainability .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}
/*Second Section*/
.sustainability .secondSection {
    position: relative;
    width: 100vw;
    border-radius: 0px;
    background-color: #08090D;
}

.sustainability .secondSection .image {
    content: url('../images/sustainability/secondSection.svg')
}

.sustainability .secondSection .content .info .title {
    color: #F8F8F8;
    font-family: 'Baloo 2';
    font-style: normal;
}

.sustainability .secondSection .content .box {
    background: #F05033;
    border-radius: 3px;
    cursor: pointer;
}

.sustainability .secondSection .content .box .text {
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
    cursor: pointer;
}

.sustainability .secondSection .content .info .description {
    font-family: 'Poppins';
    font-style: normal;
    color: #F8F8F8;
}
@media (min-width: 360px) and (max-width: 1024px) {
    .sustainability .secondSection {
        padding: 32px 16px;
        flex-direction: column;
        gap: 32px;
        justify-content: center;
    }

    .sustainability .secondSection .content {
        align-items: start;
        gap:21px;
    }

    .sustainability .secondSection .content .info {
        align-items: start;
        gap: 12px;

    }
    .sustainability .secondSection .content .info-list {
        margin-left: 16px;
    }
    .sustainability .secondSection .content .info-list .list {
        font-style: normal;
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        gap: 20px;
        /* White Text */
        list-style:disc;
        color: #F8F8F8;
    }
    .sustainability .secondSection .image {
        height: 232px;
        border-radius: 10px;
        object-fit: cover;
    }

    .sustainability .secondSection .content .info .title {
        /* Heading H2 */
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;
    }

    .sustainability .secondSection .content .info .description {
        /* Text */
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
    }
}
@media (min-width: 1024px) and (max-width: 1440px) {
    .sustainability .secondSection {
        padding: 80px;
        gap: 80px;
        justify-content: center;
    }

    .sustainability .secondSection .content {
        align-items: start;
        gap:21px;
    }

    .sustainability .secondSection .content .info {
        align-items: start;
        gap: 12px;

    }
    .sustainability .secondSection .content .info-list {
        margin-left: 16px;
    }
    .sustainability .secondSection .content .info-list .list {
        font-style: normal;
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        gap: 20px;
        /* White Text */
        list-style:disc;
        color: #F8F8F8;
    }
    .sustainability .secondSection .image {
        width: 360px;
        border-radius: 10px;
        object-fit: cover;
    }

    .sustainability .secondSection .content .info .title {
        /* Heading H2 */
        align-self: stretch;
        font-weight: 400;
        font-size: 64px;
        
        line-height: 120%;
    }

    .sustainability .secondSection .content .info .description {
        /* Text */
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
    }
}
@media (min-width: 1440px) {
    .sustainability .secondSection {
        padding: 80px;
        gap: 80px;
        justify-content: center;
    }

    .sustainability .secondSection .content {
        align-items: start;
        gap:21px;
    }

    .sustainability .secondSection .content .info {
        align-items: start;
        gap: 12px;

    }
    .sustainability .secondSection .content .info-list {
        margin-left: 16px;
    }
    .sustainability .secondSection .content .info-list .list {
        font-style: normal;
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        gap: 20px;
        /* White Text */
        list-style:disc;
        color: #F8F8F8;
    }
    .sustainability .secondSection .image {
        width: 630px;
        height: 475.51px;

        border-radius: 10px;
    }

    .sustainability .secondSection .content .info .title {
        /* Heading H2 */
        align-self: stretch;
        font-weight: 400;
        font-size: 64px;
        
        line-height: 120%;
    }

    .sustainability .secondSection .content .info .description {
        /* Text */
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
    }
}
/*Third Section*/
.sustainability .thirdSection{
    background: #08090D;
}
.sustainability .thirdSection .header .header-content .margin {
    background: #f05033;
}
.sustainability .thirdSection .header .header-content.title{
    
    /* Heading H2 */
    color: #F8F8F8;
    font-family: 'Baloo 2';
    font-style: normal;
}
.sustainability .thirdSection .header .header-content .title{
    /* Heading H2 */
    
    color: #F8F8F8;
    font-family: 'Baloo 2';
    font-style: normal;
}
.sustainability .thirdSection .main {
    position: relative;
    width: 100vw;
    border-radius: 0px;
    background-color: #08090D;
}

.sustainability .thirdSection .main .image {
}

.sustainability .thirdSection .main .content .info .title {
    color: #F05033;
    font-family: 'Baloo 2';
    font-style: normal;
}

.sustainability .thirdSection .main .content .info .title span:first-child {
    color: #0B2EAC;
}

.sustainability .thirdSection .main .content .info .title span:last-child {
    color: #F05033;
}
.sustainability .thirdSection .main .content .info .description {
    font-family: 'Poppins';
    font-style: normal;
    color: #F8F8F8;
}

.sustainability .thirdSection .margin {
    background: #f05033;
}
.sustainability .thirdSection .things .header .title{
    
    /* Heading H2 */
    color: #F8F8F8;
    font-family: 'Baloo 2';
    font-style: normal;
}
@media (min-width: 360px) and (max-width: 1024px) {
    .sustainability .thirdSection{
        padding: 20px;
        gap : 80px;
        width: 100vw;
    }
    .sustainability .thirdSection .header {
        display: none;

    }
    .sustainability .thirdSection .header .header-content{
        gap: 24px;
    }
    .sustainability .thirdSection .header .header-content .title{
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }
    .sustainability .thirdSection .header .header-content .margin{
        width: 10px;
        height: 83px;
    }
    .sustainability .thirdSection .main {
        width: 100%;
        gap: 30px;
    
    }

    .sustainability .thirdSection .main .content {
        align-items: start;
        width: 715px;
        gap: 6px;
        justify-content: center;
    }

    .sustainability .thirdSection .main .content .info {
        align-items: start;
        gap: 6px;
    }

    .sustainability .thirdSection .main .image {
        width: 152px !important;
    }

    .sustainability .thirdSection .main .content .info .title {
        /* Heading H2 */
        align-self: stretch;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .sustainability .thirdSection .main .content .info .description {
        /* Text */
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    .sustainability .thirdSection .main .content .margin {
        width: 121px;
        height: 6.3px;
    }
    .sustainability .thirdSection .resources-list{
        gap : 72px;
        display: none;
    }
    .sustainability .thirdSection .ImagesList{
        width: 100% ;
    }
}
@media (min-width: 1024px) and (max-width: 1440px) {
    .sustainability .thirdSection{
        padding: 90px 80px 90px 80px;
        gap : 80px;
        width: 100vw;
    }
    .sustainability .thirdSection .header {

    }
    .sustainability .thirdSection .header .header-content{
        gap: 24px;
    }
    .sustainability .thirdSection .header .header-content .title{
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }
    .sustainability .thirdSection .header .header-content .margin{
        width: 10px;
        height: 83px;
    }
    .sustainability .thirdSection .main {
        width: 100%;
        gap: 30px;
    
    }

    .sustainability .thirdSection .main .content {
        align-items: start;
        width: 715px;
        gap: 6px;
        justify-content: center;
    }

    .sustainability .thirdSection .main .content .info {
        align-items: start;
        gap: 6px;
    }

    .sustainability .thirdSection .main .image {
        width: 506px !important;
    }

    .sustainability .thirdSection .main .content .info .title {
        /* Heading H2 */
        align-self: stretch;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .sustainability .thirdSection .main .content .info .description {
        /* Text */
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    .sustainability .thirdSection .main .content .margin {
        width: 121px;
        height: 6.3px;
    }
    .sustainability .thirdSection .resources-list{
        gap : 72px;
    }
    .sustainability .thirdSection .ImagesList{
        width: 100% ;        
    }
}
@media (min-width: 1440px) {
    .sustainability .thirdSection{
        padding: 90px 80px 90px 80px;
        gap : 80px;
        width: 100vw;
    }
    .sustainability .thirdSection .header {

    }
    .sustainability .thirdSection .header .header-content{
        gap: 24px;
    }
    .sustainability .thirdSection .header .header-content .title{
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }
    .sustainability .thirdSection .header .header-content .margin{
        width: 10px;
        height: 83px;
    }
    .sustainability .thirdSection .main {
        width: 100%;
        gap: 30px;
    
    }

    .sustainability .thirdSection .main .content {
        align-items: start;
        width: 715px;
        gap: 6px;
        justify-content: center;
    }

    .sustainability .thirdSection .main .content .info {
        align-items: start;
        gap: 6px;
    }

    .sustainability .thirdSection .main .image {
        width: 506px !important;
    }

    .sustainability .thirdSection .main .content .info .title {
        /* Heading H2 */
        align-self: stretch;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .sustainability .thirdSection .main .content .info .description {
        /* Text */
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    .sustainability .thirdSection .main .content .margin {
        width: 121px;
        height: 6.3px;
    }
    .sustainability .thirdSection .resources-list{
        gap : 72px;
    }
    .sustainability .thirdSection .ImagesList{
        width: 100% ;        
    }
}
/* Forth Section*/
.sustainability .forthSection {
    /* background variation */
    background: #222327;
    align-items: center;
}

.sustainability .forthSection .image {
    content: url('../images/home/sixthSection.svg');
}

.sustainability .forthSection .content .title {
    color: #F8F8F8;
    /* Heading H2 */

    font-family: 'Baloo 2';
    font-style: normal;
}

.sustainability .forthSection .content .description {
    font-family: 'Poppins';
    font-style: normal;
    color: #F8F8F8;
}

.sustainability .forthSection .content .box {
    background: #F05033;
    border-radius: 3px;
    cursor: pointer;
}

.sustainability .forthSection .content .box .text {
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
    cursor: pointer;
}
@media (min-width: 360px ) and (max-width: 1024px) {
    .sustainability .forthSection{
        display: none;
    }
}
@media (min-width: 1024px ) and (max-width: 1440px) {
    .sustainability .forthSection {
        padding: 0px 80px 0px 0px;
        gap: 80px;

        width: 100vw;
        height: 422px;
    }

    .sustainability .forthSection .image {
        width: 426px;
        height: 421px;
        object-fit: cover;
    }

    .sustainability .forthSection .content {
        gap: 24px;
        width: 438px;
        height: 351px;
    }

    .sustainability .forthSection .content .title {
        width: 438px;
        height: 231px;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .sustainability .forthSection .content .description {
        width: 438px;
        height: 24px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .sustainability .forthSection .content .box {
        height: 48px;
        width: 156px;
        left: 0px;
        top: 0px;
        border-radius: 3px;
        align-items: center;
        justify-content: center;
    }

    .sustainability .forthSection .content .box .text {
        height: 28.138957977294922px;
        width: 114.88965606689453px;
        left: 13.623046875px;
        top: 10.15380859375px;
        text-align: center;

        /* Label */
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        /* White Text */
    }
}
@media (min-width: 1440px) {
    .sustainability .forthSection {
        padding: 0px 80px 0px 0px;
        gap: 80px;

        width: 100vw;
        height: 422px;

    }

    .sustainability .forthSection .image {
        width: 629px;
        height: 422px;
    }

    .sustainability .forthSection .content {
        gap: 24px;
        width: 651px;
        height: 274px;

    }

    .sustainability .forthSection .content .title {
        width: 651px;
        height: 154px;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .sustainability .forthSection .content .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
}