/*
First Section
*/
.industry .firstSection {
    position: relative;
    width: 100vw;
    background-size: cover;

}

.industry .firstSection .content .legend .legend-title{
    /* identical to box height, or 133% */
    font-family: 'Poppins';
    font-style: normal;
    /* White Text */
    color: #F8F8F8;
    
}
.industry .firstSection .content .header .title {
    color: #f8f8f8;
    font-family: "Baloo 2";
    font-style: normal;
}

.industry .firstSection .content .header .description {
    font-family: "Poppins";
    font-style: normal;
    color: #ffffff;
}

.industry .firstSection .content .header .margin {
    background: #f05033;
}
@media (min-width: 360px) and (max-width: 1024px) {
    .industry .firstSection {
        height: 100vh;
        padding: 47px 23px 38px 32px;
    }
    .industry .firstSection .content {
        width: 100%;
        gap: 22px;
        height : 100%;
        justify-content: space-between;
    }
    .industry .firstSection .content .legend {
        width: 100%;
        gap:  6px;
    }
    .industry .firstSection .content .legend .legend-title{
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }
    .industry .firstSection .content .legend .margin{
      height: 1px;
      width: 100%;
      background: #D9D9D9;
    }
    .industry .firstSection .content {
        width: 310px;
        gap: 24px;
    }
    .industry .firstSection .content .header {
        padding: 0px 12px 0px 0px;
        gap: 12px;
    }
    .industry .firstSection .content .header .title {
        width: 322px;

        /* Heading H1 */
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;
        /* or 89px */
        /* White Text */
    }

    .industry .firstSection .content .header .description {
        width: 100%;
        /* Heading H6 */
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        /* or 133% */
    }

    .industry .firstSection .content  .header .margin {
        width: 192.53px;
        height: 6.3px;
    }
}
@media (min-width: 1024px) and (max-width: 1440px) {
    .industry .firstSection {
        height: 100vh;
        padding: 82px;
    }
    .industry .firstSection .content {
        width: 100%;
        gap: 22px;
        height : 100%;
        justify-content: space-between;
    }
    .industry .firstSection .content .legend {
        width: 100%;
        gap:  10px;
    }
    .industry .firstSection .content .legend .legend-title{
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
    }
    .industry .firstSection .content .legend .margin{
      height: 1px;
      width: 100%;
      background: #D9D9D9;
    }
    .industry .firstSection .content {
        width: 900px;
        gap: 24px;
    }
    .industry .firstSection .content .header {
        padding: 0px 12px 0px 0px;
        gap: 24px;
    }
    .industry .firstSection .content .header .title {
        width: 900px;

        /* Heading H1 */
        font-weight: 440;
        font-size: 84px;
        line-height: 106%;
        /* or 89px */
        /* White Text */
    }

    .industry .firstSection .content .header .description {
        width: 100%;
        height: 84px;
        /* Heading H6 */

        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
        /* or 133% */
    }

    .industry .firstSection .content  .header .margin {
        width: 192.53px;
        height: 6.3px;
    }
}

@media (min-width: 1440px) {
    .industry .firstSection {
        height: 100vh;
        padding: 82px;
        justify-content: center;
    }

    .industry .firstSection .content {
        width: 100%;
        gap: 22px;
        height : 100%;
        justify-content: space-between;
    }
    .industry .firstSection .content .legend {
        width: 100%;
        gap:  10px;
    }
    .industry .firstSection .content .legend .legend-title{
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
    }
    .industry .firstSection .content .legend .margin{
      height: 1px;
      width: 100%;
      background: #D9D9D9;
    }
    .industry .firstSection .content .header {
        padding: 0px 12px 0px 0px;
        gap: 24px;
    }
    .industry .firstSection .content .header .title {
        /* Heading H1 */
        width: 1069px;
        font-weight: 440;
        font-size: 84px;
        line-height: 106%;
    }

    .industry .firstSection .content .header .description {
        height: 84px;
        width: 75%;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
    }

    .industry .firstSection .content .header .margin {
        width: 192.53px;
        height: 6.3px;
    }

}
/*Second Section*/
.industry .secondSection .cards{
    background: #08090D;    
}
.industry .secondSection .cards .card .card-content .card-title{
    color: #F8F8F8;
    font-family: 'Baloo 2';
    font-style: normal;
}
.industry .secondSection .cards .card .card-content .card-info{
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
}
@media (min-width: 360px) and (max-width: 1024px) {
    .industry .secondSection .cards{
    }
    .industry .secondSection .cards .card{
        align-items: center;
        padding: 36px 32px;
        flex-direction: column;
        gap: 24px;
        width: 100%;
    } 
    .industry .secondSection .cards .card .image{
        width: 295px;
        height: 267px;
        border-radius: 10px;
    }
    .industry .secondSection .cards .card .card-content{
        align-items: flex-start;
        padding: 0px;
        gap: 24px;
        width: 100%;  
    }
    .industry .secondSection .cards .card .card-content .card-title{
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;  
        width: 100%;  
    }
    .industry .secondSection .cards .card .card-content .card-info{
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        /* White Text */  
    }
}
@media (min-width: 1024px) and (max-width: 1440px) {
    .industry .secondSection .cards{
        align-items: center;
    }
    .industry .secondSection .cards .card{
        align-items: center;
        padding: 80px;
        gap: 80px;
        width: 100%;
    } 
    .industry .secondSection .cards .card .image{
        width: 400px;
        height: 458.25px;
        border-radius: 10px;
    }
    .industry .secondSection .cards .card .card-content{
        align-items: flex-start;
        padding: 0px;
        gap: 24px;
        width: 100%;
    }
    .industry .secondSection .cards .card .card-content .card-title{
        font-weight: 440;
        font-size: 64px;
        line-height: 120%;    
        width: 100%;  
    }
    .industry .secondSection .cards .card .card-content .card-info{
        width: 100%;

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* White Text */  
    }
}
@media (min-width: 1440px) {
    .industry .secondSection .cards{
        align-items: center;
    }
    .industry .secondSection .cards .card{
        align-items: center;
        padding: 80px;
        gap: 80px;

        width: 1440px;
    } 
    .industry .secondSection .cards .card .image{
        width: 630px;
        height: 560px;
        border-radius: 10px;
    }
    .industry .secondSection .cards .card .card-content{
        align-items: flex-start;
        padding: 0px;
        gap: 24px;
        width: 570px;
    }
    .industry .secondSection .cards .card .card-content .card-title{
        width: 570px;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;        
    }
    .industry .secondSection .cards .card .card-content .card-info{
        width: 570px;

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* White Text */  
    }
}
/* Third Section */
.industry .thirdSection
{
    background: #08090D;
}
.industry .thirdSection .header .margin{
    background: #F05033;
}
.industry .thirdSection .header .title{
    color: #F8F8F8;
    font-family: 'Baloo 2';
    font-style: normal;
}
.industry .thirdSection .header .description{
    font-family: 'Poppins';
    font-style: normal;
    color: #F8F8F8;
}
.industry .thirdSection .info-cards .info-number{
    text-transform: uppercase;
    color: #222327;
    font-family: 'Poppins';
    font-style: normal;
}
.industry .thirdSection .info-cards .info-content .info-title{
    /* Heading H4 */
    
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
}
.industry .thirdSection .info-cards .info-content .info-description{
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
}
@media (min-width: 360px) and (max-width: 1024px) {
    .industry .thirdSection {
        padding: 45px 16px 33px 37px ;
        gap: 66px;
    }
    .industry .thirdSection .header{
        align-items: center;
        padding: 0px;
        width: 100%;
        gap: 24px;
    }
    .industry .thirdSection .header .margin{
        width: 10px;
        height: 269px;
    }
    .industry .thirdSection .header .title{
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;
    }
    .industry .thirdSection .header .description{
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }
    .industry .thirdSection .info-cards{
        align-items: flex-start;
        padding: 0px;
        gap: 62px;
        width: 100%;
    }
    .industry .thirdSection .info-cards .info-card{
        gap: 6px;
        align-items: flex-start;
    }
    .industry .thirdSection .info-cards .info-number{
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        width: 53px;
        text-align: center;
    }
    .industry .thirdSection .info-cards .info-content{
        align-items: flex-start;
        padding: 0px;
        gap: 12px;
        width: 100%;
    }
    .industry .thirdSection .info-cards .info-content .info-title{
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
    }
    .industry .thirdSection .info-cards .info-content .info-description{

        color: #F8F8F8;
        font-family: 'Poppins';
        font-style: normal;

        font-weight: 400;
        font-size: 14px;
        line-height: 21px;

        /* White Text */

    }
        
}
@media (min-width: 1024px) and (max-width: 1440px) {
    .industry .thirdSection {
        padding: 76px;
        gap: 60px;
    }
    .industry .thirdSection .header{
        align-items: center;
        padding: 0px;
        width: 100%;
        gap: 24px;
    }
    .industry .thirdSection .header .margin{
        width: 10px;
        height: 190px;
    }
    .industry .thirdSection .header .title{
        width: 100%;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }
    .industry .thirdSection .header .title{
        width: 100%;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }
    .industry .thirdSection .header .description{
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    .industry .thirdSection .info-cards{
        overflow-x: scroll;
        align-items: flex-start;
        padding: 0px;
        gap: 62px;
        width: 827px;
    }
    .industry .thirdSection .info-cards .info-card{
        gap:12px;
    }
    .industry .thirdSection .info-cards .info-number{
        font-weight: 600;
        font-size: 64px;
        line-height: 96px;
        width: 108px;
        text-align: center;
    }
    .industry .thirdSection .info-cards .info-content{
        align-items: flex-start;
        padding: 0px;
        gap: 12px;
        width: 100%;
    }
    .industry .thirdSection .info-cards .info-content .info-title{
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;
    }
    .industry .thirdSection .info-cards .info-content .info-description{

        color: #F8F8F8;
        font-family: 'Poppins';
        font-style: normal;

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        /* White Text */

    }
        
}
@media (min-width: 1440px) {
    .industry .thirdSection {
        align-items: center;
        gap : 96px;
        padding: 76px;
    }
    .industry .thirdSection .header{
        align-items: center;
        padding: 0px;
        width: 1280px;
        gap: 24px;
    }
    .industry .thirdSection .header .margin{
        width: 10px;
        height: 190px;
    }
    .industry .thirdSection .header .title{
        width: 100%;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }
    .industry .thirdSection .header .title{
        width: 100%;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }
    .industry .thirdSection .header .description{
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    .industry .thirdSection .info-cards{
        align-items: flex-start;
        padding: 0px;
        gap: 62px;        
        width: 1280px;

    }
    .industry .thirdSection .info-cards .info-number{
        width: 108px;

        font-weight: 600;
        font-size: 64px;
        line-height: 96px;
        
    }
    .industry .thirdSection .info-cards .info-content{
        align-items: flex-start;
        padding: 0px;
        gap: 12px;

    }
    .industry .thirdSection .info-cards .info-content .info-title{
        width: 906px;    
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;
    }
    .industry .thirdSection .info-cards .info-content .info-description{
        width: 1076px;

        color: #F8F8F8;
        font-family: 'Poppins';
        font-style: normal;

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        /* White Text */

    }
        
}
/*Forth Section*/


/*Third Section*/
.industry .forthSection {
    position: relative;
    width: 100vw;
    border-radius: 0px;
    background-color: #252730;
    overflow: hidden;
}

.industry .forthSection .top .title {
    font-family: 'Baloo 2';
    font-style: normal;
    color: #F8F8F8;
}

.industry .forthSection .top .box {
    background: #F05033;
    border-radius: 3px;
}

.industry .forthSection .top .box {
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
}
.industry .forthSection .services .content .cards .cardslide{
    height: 460.22px;
    display: inline-flex;
    gap: 20px;
    transition: ease 1000ms;
    transform: translate3d(-0%,0,0);
}
.industry .forthSection .services .content .cards .cardslide .card {
    white-space: nowrap;
}

.industry .forthSection .services .buttons {
    white-space: pre-wrap;
    justify-content:center;
    max-width: 1800px;
}
/* Hover Effect */
.industry .forthSection .services .content .cards .card:hover .title{
   bottom:15%;
}
.industry .forthSection .services .content .cards .card:hover .colorBend{
    background: rgba(12, 16, 28, 0.3);
}
.industry .forthSection .services .content .cards  {
    display: inline-flex;
    overflow: hidden;
}
.industry .forthSection .services .content .cards .card
{
    display: inline-block;
}
.industry .forthSection .services .content .cards .card .image {
    object-fit: cover;
    border-radius: 10px;
    height: 100%;
    width: 100%;
}

.industry .forthSection .services .content .cards .card .title {
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
}

.industry .forthSection .services .content .cards .card .colorBend {

    background: rgba(12, 16, 28, 0.5);
    width: 100%;
    border-radius: 10px;
    height: 100%;
    position: absolute;
}

.industry .forthSection .services .buttons .button {
    background-color: #D9D9D9;
}

.industry .forthSection .services .buttons .selected {
    background-color: #f05033;
}
.industry .forthSection .services .content .cards .cardslide{
    height: 460.22px;
    display: inline-flex;
    gap: 20px;
    transition: ease 1000ms;
    transform: translate3d(-0%,0,0);
}
.industry .forthSection .services .content .cards .cardslide .card {
    white-space: nowrap;
}

.industry .forthSection .services .buttons {
    white-space: pre-wrap;
    justify-content:center;
    max-width: 1800px;
}
/* Hover Effect */
.industry .forthSection .services .content .cards .card:hover .title{
   top:60%;
}
.industry .forthSection .services .content .cards .card:hover .colorBend{
    
    
 }
  /* Expand */
  .industry .forthSection .services .hide{
    display:  none;
}
.industry .forthSection .services .expand{
    height: 100% !important;
    display: inline-flex;
    justify-content: center;
    flex-wrap: wrap;
    transform: translate3d(0%,0%,0) !important;
}
.industry .forthSection .services .content  .contentControls .left{
    width: 52px;
    height: 52px;
    left: 52px;
    top: 256px;
    background: #F05033;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    fill: #FFFFFF;
    cursor: pointer;
}
.industry .forthSection .services .content  .contentControls svg{
    fill: #FFFFFF;
}
.industry .forthSection .services .content  .contentControls .right{
    width: 52px;
    height: 52px;
    left: 52px;
    top: 256px;
    background: #F05033;
    border-radius: 100px;
    transform: rotate(-180deg);
    align-items: center;
    justify-content: center;
    fill: #FFFFFF;
    cursor: pointer;
}
.industry .forthSection .services .content  .contentControls .disable{
    background: #323232 !important;
    fill: #7b7b7b;
}
@media (min-width: 360px ) and (max-width: 1024px) {
    .industry .forthSection {
        padding: 50px;
        align-items: center;
        gap: 24px;
    }

    .industry .forthSection .top {
        width: 100%;
        height: 63px;
        align-items: center;
        justify-content: space-between;
    }

    .industry .forthSection .top .title {
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;
    }

    .industry .forthSection .top .box {
        display: none;
        width: 107px;
        height: 33px;
        border-radius: 100px;
        flex-direction: row;

        font-weight: 500;
        font-size: 14px;
        line-height: 21px;

        gap: 6px;
        align-items: center;
        justify-content: center;
    }

    .industry .forthSection .top .box img {
        width: 14px ;
        height: 14px ;
    }
    .industry .forthSection .services {
        gap: 24px;
        align-items: center;
    }

    .industry .forthSection .services .content {
        width: 100vw;
        justify-content: center;
    }

    .industry .forthSection .services .content .cards {
        width: 100vw;
        height: 100%;
        padding: 20px;
        overflow-x: scroll;
        
    }
    .industry .forthSection .services .content .cards {
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    .industry .forthSection .services .content .cards::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
    .industry .forthSection .services .content .cards .card {
        width: 224.24px;
        height: 100%;
        position: relative;   
    }
    .industry .forthSection .services .content .controls {
        cursor: pointer;
        display: none;

    }
    .industry .forthSection .services .content .cards .cardslide{
        height: 100%;
    }
    .industry .forthSection .services .content .contentControls {
        gap: 24px;
        align-items: center
    }
    .industry .forthSection .services .content .cards .card .colorBend {
        border-radius: 10px;

    }
    .industry .forthSection .services .content .cards .card:hover .title{
        top:60%;
     }
    .industry .forthSection .services .content .cards .card .title {
        position: absolute;
        padding: 12px;
        left: 0%;
        right: 0.04%;
        bottom: 0%;
        width: 172px;
        height: 27px;
        
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        white-space: pre-wrap;
        display: flex;
        align-items: self-end;
    }

    .industry .forthSection .services .buttons {
        gap: 10px;
        border-radius: 0px;
        display: none;
    }

    .industry .forthSection .services .buttons .button {
        width: 23px;
        height: 5px;
        left: 0px;
        
        top: 0px;
        border-radius: 0px;
        cursor: pointer;
    }

}
@media (min-width: 1024px ) and (max-width: 1440px) {
    .industry .forthSection {
        width: 100vw;
        border-radius: 0px;
        padding: 40px 60px 80px 60px;
        align-items: center;
        gap: 24px;
    }

    .industry .forthSection .top {
        width: 863px;
        height: 97px;
        align-items: center;
        justify-content: space-between;
    }

    .industry .forthSection .top .title {
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .industry .forthSection .top .box {
        height: 48px;
        width: 156px;

        display: flex;
        flex-direction: row;

        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        gap : 12px;
        align-items: center;
        justify-content: center;
    }

    .industry .forthSection .services {
        gap: 24px;
        align-items: center;
    }

    .industry .forthSection .services .content {
        width: 1320.47px;
        height: 312px;
        justify-content: center;
    }

    .industry .forthSection .services .content .cards {
        height: 312px;
        width: 760.47px;
    }

    .industry .forthSection .services .content .cards .card {
        position: relative;   
        width: 234.67px;
        height: 311.85px;
        cursor: pointer;

    }
    .industry .forthSection .services .content .controls {
        cursor: pointer;
    }

    .industry .forthSection .services .content .contentControls {
        gap: 24px;
        align-items: center;
        justify-content: center;
    }

    .industry .forthSection .services .content .cards .card .title {
        position: absolute;
        padding: 12px;
        left: 0%;
        right: 0.04%;
        bottom: 5%;        
        width: 100%;
        height: 48px;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        cursor: pointer;
    }
    .industry .forthSection .services .buttons {
        gap: 20px;
        border-radius: 0px;
    }

    .industry .forthSection .services .buttons .button {
        width: 40px;
        height: 6px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        cursor: pointer;
    }

}
@media (min-width: 1440px) {
    .industry .forthSection {
        padding: 50px;
        align-items: center;
        gap: 24px;
    }

    .industry .forthSection .top {
        width: 1282px;
        height: 97px;
        align-items: center;
        justify-content: space-between;
    }

    .industry .forthSection .top .title {
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .industry .forthSection .top .box {
        height: 48px;
        width: 156px;

        display: flex;
        flex-direction: row;

        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        gap : 12px;
        align-items: center;
        justify-content: center;
    }


    .industry .forthSection .services {
        gap: 24px;
        align-items: center;
    }

    .industry .forthSection .services .content {
        width: 1320.47px;
        height: 460.22px;
    }

    .industry .forthSection .services .content .cards {
        height: 460.22px;
        width: 1168.47px;
    }

    .industry .forthSection .services .content .cards .card {
        width: 373.49px;
        height: 460.22px;
        position: relative;   
    }
    .industry .forthSection .services .content .controls {
        cursor: pointer;
    }

    .industry .forthSection .services .content .contentControls {
        gap: 24px;
        align-items: center
    }
    .industry .forthSection .services .content .cards .card .colorBend {
        border-radius: 10px;

    }
    .industry .forthSection .services .content .cards .card .title {
        position: absolute;
        padding: 12px;
        left: 0%;
        right: 0.04%;
        bottom: 5%;
        white-space: pre-wrap;
        display: flex;
        align-items: self-end;

        width: 305px;
        height: 48px;
        font-weight: 500;
        font-size: 32px;
        line-height: 48px;
    }

    .industry .forthSection .services .buttons {
        gap: 20px;
        border-radius: 0px;
    }

    .industry .forthSection .services .buttons .button {
        width: 40px;
        height: 6px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        cursor: pointer;
    }

}
/**/

.industry .fifthSection {
    /* background variation */
    background: #222327;
    align-items: center;
}

.industry .fifthSection .image {
    content: url('../images/home/sixthSection.svg');
}

.industry .fifthSection .content .title {
    color: #F8F8F8;
    /* Heading H2 */

    font-family: 'Baloo 2';
    font-style: normal;
}

.industry .fifthSection .content .description {
    font-family: 'Poppins';
    font-style: normal;
    color: #F8F8F8;
}

.industry .fifthSection .content .box {
    background: #F05033;
    border-radius: 3px;
    cursor: pointer;
}

.industry .fifthSection .content .box .text {
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
    cursor: pointer;
}
@media (min-width: 360px ) and (max-width: 1024px) {
    .industry .fifthSection{
        display: none;
    }
}
@media (min-width: 1024px ) and (max-width: 1440px) {
    .industry .fifthSection {
        padding: 0px 80px 0px 0px;
        gap: 80px;

        width: 100vw;
        height: 422px;
    }

    .industry .fifthSection .image {
        width: 426px;
        height: 421px;
        object-fit: cover;
    }

    .industry .fifthSection .content {
        gap: 24px;
        width: 438px;
        height: 351px;
    }

    .industry .fifthSection .content .title {
        width: 438px;
        height: 231px;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .industry .fifthSection .content .description {
        width: 438px;
        height: 24px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .industry .fifthSection .content .box {
        height: 48px;
        width: 156px;
        left: 0px;
        top: 0px;
        border-radius: 3px;
        align-items: center;
        justify-content: center;
    }

    .industry .fifthSection .content .box .text {
        height: 28.138957977294922px;
        width: 114.88965606689453px;
        left: 13.623046875px;
        top: 10.15380859375px;
        text-align: center;

        /* Label */
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        /* White Text */
    }
}
@media (min-width: 1440px) {
    .industry .fifthSection {
        padding: 0px 80px 0px 0px;
        gap: 80px;

        width: 100vw;
        height: 422px;

    }

    .industry .fifthSection .image {
        width: 629px;
        height: 422px;
    }

    .industry .fifthSection .content {
        gap: 24px;
        width: 651px;
        height: 274px;

    }

    .industry .fifthSection .content .title {
        width: 651px;
        height: 154px;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .industry .fifthSection .content .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .industry .fifthSection .content .box {
        height: 48px;
        width: 142px;

        align-items: center;
        justify-content: center;
    }

    .industry .fifthSection .content .box .text {
        height: 28.138957977294922px;
        width: 114.88965606689453px;
        left: 13.623046875px;
        top: 10.15380859375px;
        border-radius: nullpx;
        text-align: center;

        /* Label */
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        /* White Text */
    }
}