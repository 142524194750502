/*
First Section
*/

.team .firstSection {
    position: relative;
    background-size: cover;
    background-image: linear-gradient(90.15deg,
            #0c101c -10.1%,
            rgba(12, 16, 28, 0) 106.97%),
        url("../images/home/homeScreen.svg");
}

.team .firstSection .content .title {
    color: #f8f8f8;
    font-family: "Baloo 2";
    font-style: normal;
}

.team .firstSection .content .description {
    font-family: "Poppins";
    font-style: normal;
    color: #ffffff;
}

.team .firstSection .content .margin {
    background: #f05033;
}

.team .firstSection .buttons .button {
    background-color: #D9D9D9;
}

.team .firstSection .buttons .selected {
    background-color: #f05033;
}
.team .firstSection .top{
    gap: 24px;
}
@media (min-width: 360px) and (max-width: 1023px) {
    .team .firstSection {
        height: 100vh;
        width: 100vw;
        flex-direction: row;
        gap: 13px;
    }

    .team .firstSection .content {
        width: 100vw;
        gap: 12px;
        height: 100%;
        justify-content: flex-end;
        padding: 32px 32px;
    }

    .team .firstSection .content .title {
        /* Heading H1 */
        width: 301px;
        font-weight: 440;
        font-size: 36px;
        line-height: 120%;
        /* or 89px */
        /* White Text */
    }

    .team .firstSection .content .description {
        /* Heading H6 */
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        /* or 133% */
    }

    .team .firstSection .buttons {
        display: none;
        position: absolute;
        height: 6px;
        width: 220px;
        left: calc((100vw/2) - 80px);
        top: 846px;
        gap: 20px;
        border-radius: 0px;
    }

    .team .firstSection .buttons .button {
        height: 6px;
        width: 40px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        cursor: pointer;
    }

    .team .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}

@media (min-width: 1024px) and (max-width: 1439px) {
    .team .firstSection {
        height: 100vh;
    }

    .team .firstSection .content {
        position: absolute;
        width: 740px;
        height: 368.09px;
        left: 80px;
        top: 425px;
        gap: 24px;
    }

    .team .firstSection .content .title {
        width: 752px;

        /* Heading H1 */
        font-weight: 440;
        font-size: 84px;
        line-height: 106%;
        /* or 89px */
        /* White Text */
    }

    .team .firstSection .content .description {
        width: 75%;
        /* Heading H6 */

        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
        /* or 133% */
    }

    .team .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}

@media (min-width: 1440px) {
    .team .firstSection {
        height: 100vh;
        padding: 82px;
        justify-content: flex-end;
    }

    .team .firstSection .content {
        width: 100%;
        gap: 22px;
    }

    .team .firstSection .content .title {
        width: 75%;
        /* Heading H1 */
        font-weight: 440;
        font-size: 84px;
        line-height: 106%;
        /* or 89px */
        /* White Text */
    }

    .team .firstSection .content .description {
        width: 75%;
        /* Heading H6 */

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        /* or 133% */
    }

    .team .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}

/*Second Section*/
.team .secondSection {
    width: 100vw;
    background: #08090D;
    justify-content: center;
}

.team .secondSection .cards .card .profile {
    background: #D9D9D9;
    
    
}

.team .secondSection .cards .card .margin {
    background: #F05033;
}

.team .secondSection .cards .card .content .info .title {
    color: #F8F8F8;
    font-family: 'Baloo 2';
    font-style: normal;
}

.team .secondSection .cards .card .content .info .designation {
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
}
.team .secondSection .cards .card .content .about{
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
}
.team .secondSection .cards .card .content .icons .socialmedia{
    background: #393939;        
}
@media (min-width: 360px) and (max-width: 1024px) {
    .team .secondSection{
        padding-top: 40px;
    }
    .team .secondSection .cards {
        flex-wrap: wrap;        
        align-items: center;
        justify-content: space-around;
        padding: 0px;
        gap: 30px;
    }

    .team .secondSection .cards .card {
        padding: 0px;
        gap: 24px;
        flex-direction: column;
        height: 353px;
    }
    .team .secondSection .cards .card .profile {
        width: 168px;
        height: 163px;
        margin-left: 50px;
        border-radius: 30px 0px 0px 0px;
    }

    .team .secondSection .cards .card .margin {
        width: 168px;
        height: 3px;
    }

    .team .secondSection .cards .card .content {
        align-items: flex-start;
        padding: 0px;
        height: 134px;    
    }

    .team .secondSection .cards .card .content .info .title {
        font-weight: 400;
        font-size: 32px;
        line-height: 120%;
    }

    .team .secondSection .cards .card .content .info .designation {
        font-weight: 400;
        font-size: 14px;
    }
    .team .secondSection .cards .card .content .about{
        height: 80px;

        /* Text */

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        /* White Text */
    }
    .team .secondSection .cards .card .content .icons{
        width: 92px;
        height: 34px;
        gap: 24px;
    }
    .team .secondSection .cards .card .content .icons .socialmedia{
        height: 34px;
        width: 34px;
        display: flex;
        padding: 8.5px 8px 8.5px 8px;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
    }
}
@media (min-width: 1024px) and (max-width: 1440px) {
    .team .secondSection .cards {
        flex-wrap: wrap;
        gap: 64px;
        padding:90px;
        justify-content: center;
    }

    .team .secondSection .cards .card {
        align-items: center;
        padding: 0px;
        gap: 24px;
        height: 255px;
    }

    .team .secondSection .cards .card .profile {
        width: 233px;
        height: 256px;
        margin-left: 50px;

        border-radius: 30px 0px 0px 0px;
    }

    .team .secondSection .cards .card .margin {
        width: 10px;
        height: 256px;
    }

    .team .secondSection .cards .card .content {
        align-items: flex-start;
        padding: 0px;
        gap: 16px;

        height: 252px;
    }

    .team .secondSection .cards .card .content .info .title {
        height: 77px;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .team .secondSection .cards .card .content .info .designation {
        height: 28px;
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
    }
    .team .secondSection .cards .card .content .about{
        height: 80px;

        /* Text */

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        /* White Text */
    }
    .team .secondSection .cards .card .content .icons{
        width: 92px;
        height: 34px;
        gap: 24px;
    }
    .team .secondSection .cards .card .content .icons .socialmedia{
        height: 34px;
        width: 34px;
        display: flex;
        padding: 8.5px 8px 8.5px 8px;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
    }
}
@media (min-width: 1440px) {
    .team .secondSection .cards {
        flex-wrap: wrap;
        gap: 64px;
        padding: 60px;
        width : 100vw;
        justify-content: center;
    }

    .team .secondSection .cards .card {
        align-items: center;
        padding: 0px;
        gap: 24px;

        width: 624px;
        height: 255px;
    }

    .team .secondSection .cards .card .profile {
        width: 230px;
        margin-right: -15px;
        height: 256px;
        margin-left: -110px;

        border-radius: 30px 0px 0px 0px;
    }

    .team .secondSection .cards .card .margin {
        width: 10px;
        height: 256px;
    }

    .team .secondSection .cards .card .content {
        align-items: flex-start;
        padding: 0px;
        gap: 16px;

        height: 252px;
    }

    .team .secondSection .cards .card .content .info .title {
        height: 70px;
        font-weight: 100px;
        font-size: 40px;
        line-height: 120%;
        width: 460px;
    }

    .team .secondSection .cards .card .content .info .designation {
        height: 28px;
        font-weight: 500;
        font-size: 21px;
        line-height: 40px;
    }
    .team .secondSection .cards .card .content .about{
        height: 80px;

        /* Text */

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        /* White Text */
    }
    .team .secondSection .cards .card .content .icons{
        width: 92px;
        height: 34px;
        gap: 24px;
    }
    .team .secondSection .cards .card .content .icons .socialmedia{
        height: 34px;
        width: 34px;
        display: flex;
        
        padding: 8.5px 8px 8.5px 8px;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
    }

}
/* Sixth Section*/
.team .thirdSection {
    /* background variation */
    background: #222327;
    align-items: center;
    
}

.team .thirdSection .image {
    content: url('../images/home/sixthSection.svg');
}

.team .thirdSection .content .title {
    color: #F8F8F8;
    /* Heading H2 */

    font-family: 'Baloo 2';
    font-style: normal;
}

.team .thirdSection .content .description {
    font-family: 'Poppins';
    font-style: normal;
    color: #F8F8F8;
}

.team .thirdSection .content .box {
    background: #F05033;
    border-radius: 3px;
    cursor: pointer;
}

.team .thirdSection .content .box .text {
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
    cursor: pointer;
}

@media (min-width: 360px ) and (max-width: 1024px) {
    .team .thirdSection{
        display: none;
    }
}
@media (min-width: 1024px ) and (max-width: 1440px) {
    .team .thirdSection {
        padding: 0px 80px 0px 0px;
        gap: 80px;

        width: 100vw;
        height: 422px;
    }

    .team .thirdSection .image {
        width: 426px;
        height: 421px;
        object-fit: cover;
    }

    .team .thirdSection .content {
        gap: 24px;
        width: 438px;
        height: 351px;
    }

    .team .thirdSection .content .title {
        width: 438px;
        height: 231px;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .team .thirdSection .content .description {
        width: 438px;
        height: 24px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .team .thirdSection .content .box {
        height: 48px;
        width: 156px;
        left: 0px;
        top: 0px;
        border-radius: 3px;
        align-items: center;
        justify-content: center;
    }

    .team .thirdSection .content .box .text {
        height: 28.138957977294922px;
        width: 114.88965606689453px;
        left: 13.623046875px;
        top: 10.15380859375px;
        text-align: center;

        /* Label */
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        /* White Text */
    }
}
@media (min-width: 1440px) {
    .team .thirdSection {
        padding: 0px 80px 0px 0px;
        gap: 80px;

        width: 100vw;
        height: 422px;

    }

    .team .thirdSection .image {
        width: 629px;
        height: 422px;
    }

    .team .thirdSection .content {
        gap: 24px;
        width: 651px;
        height: 274px;

    }

    .team .thirdSection .content .title {
        width: 651px;
        height: 154px;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .team .thirdSection .content .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .team .thirdSection .content .box {
        height: 48px;
        width: 142px;

        align-items: center;
        justify-content: center;
    }

    .team .thirdSection .content .box .text {
        height: 28.138957977294922px;
        width: 114.88965606689453px;
        left: 13.623046875px;
        top: 10.15380859375px;
        text-align: center;

        /* Label */
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        /* White Text */
    }
}