/*First Section*/
.career .firstSection {
    position: relative;
    width: 100vw;
    background-size: cover;
    background-image: linear-gradient(90.15deg,
            #0c101c -10.1%,
            rgba(12, 16, 28, 0) 106.97%),
        url("../images/career/firstSection.svg");
}

.career .firstSection .content .title {
    color: #f8f8f8;
    font-family: "Baloo 2";
    font-style: normal;
}

.career .firstSection .content .description {
    font-family: "Poppins";
    font-style: normal;
    color: #ffffff;
}

.career .firstSection .content .margin {
    background: #f05033;
    margin-bottom: 12px;
}

@media (min-width: 360px) and (max-width: 1024px) {
    .career .firstSection {
        height: 100vh;
        width: 100vw;
    }

    .career .firstSection .content {
        position: absolute;
        padding: 0px 12px 0px 0px;
        width: calc(100vw-(32px));        
        height: 190.3px;
        top: 159.7px;
        left: 32px;
    }

    .career .firstSection .content .title {
        width: 281px;
        height: 172px;
        /* Heading H1 */
        font-weight: 400;
        font-size: 36px;
        line-height: 106%;
        /* or 89px */
        /* White Text */
    }

    .career .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .career .firstSection {
        height: 100vh;
    }

    .career .firstSection .content {
        position: absolute;
        height: 285.30029296875px;
        width: 100vw;        
        left: 80px;
        top: 264.69970703125px;
        border-radius: 0px;
        padding: 0px 12px 0px 0px;
    }

    .career .firstSection .content .title {
        height: 267px;
        width: calc(100vw - (112px));
        left: 0px;
        top: 0px;
        border-radius: 0px;

        /* Heading H1 */
        font-weight: 440;
        font-size: 84px;
        line-height: 106%;
        /* or 89px */
        /* White Text */
    }

    .career .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}

@media (min-width: 1440px) {
    .career .firstSection {
        height: 100vh;
    }

    .career .firstSection .content {
        position: absolute;
        width: 100vw;        
        height: 285.3px;
        left: 80px;
        top: 264.69970703125px;
        border-radius: 0px;
        padding: 0px 12px 0px 0px;
    }

    .career .firstSection .content .title {
        width: 988px;
        height: 267px;
        /* Heading H1 */
        font-weight: 440;
        font-size: 84px;
        line-height: 106%;
        /* or 89px */
        /* White Text */
    }

    .career .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}

/*Second Section*/
.career .secondSection {
    width: 100vw;
    background: #f8f8f8;
    align-items: center;
}

.career .secondSection .cards .card {
    background: #222327;
    overflow: hidden;
}

.career .secondSection .cards .card .header .title {
    font-family: "Baloo 2";
    font-style: normal;
    color: #f8f8f8;
}

.career .secondSection .cards .card .header .description {
    font-family: "Poppins";
    font-style: normal;
    color: #f8f8f8;
}

.career .secondSection .cards .card .info .details .tags .tagname {
    font-family: "Poppins";
    font-style: normal;
    color: #f8f8f8;
}
.career .secondSection .cards .card .info .details .tags .tagname1 {
    font-family: "Poppins";
    font-style: normal;
    color: #f8f8f8;
}

.career .secondSection .cards .card .info .buttons .button-one {
    font-family: "Poppins";
    font-style: normal;
    color: #f05033;
    display: flex;
    flex-direction: row;
    transition: all ease-in-out 1000s;
}

.career .secondSection .cards .card .info .buttons .expand {
    transform: rotate(45deg);
    text-decoration: underline;
}

.career .secondSection .cards .card .info .buttons .button-two {
    background: #f05033;
    border-radius: 3px;
}

.career .secondSection .cards .card .info .buttons .button-two .text {
    font-family: "Poppins";
    font-style: normal;
    text-align: center;
    color: #f4f4f4;
}

.career .secondSection .cards .card .job-details .details-content {
    font-family: "Poppins";
    color: #f8f8f8;
}

/*Expand Card*/
.career .secondSection .expand {
    height: 100% !important;
}

@media (min-width: 360px) and (max-width: 1024px) {
    .career .secondSection {
        align-items: center;
        gap: 48px;
    }

    .career .secondSection .cards {
        margin-top:10px;
        align-items: flex-start;
        padding: 0px;
        gap: 10px;
        width: 100%;
    }

    .career .secondSection .cards .card {
        gap: 32px;
        flex-direction: column;
        padding: 32px 32px 32px 32px;
        width: 100%;
        height: 450px;
        transition: linear ease-in-out 1000ms;
        overflow: hidden;
        /* background variation */
    }

    .career .secondSection .cards .card .header {
        gap: 10px;
        border-radius: 0px;
        width: 262px;
    }

    .career .secondSection .cards .card .header .title {
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;
    }

    .career .secondSection .cards .card .header .description {
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
    }

    .career .secondSection .cards .card .info {
        flex-direction: column;
        align-items: center;
        gap: 19px;
    }

    .career .secondSection .cards .card .info .details {
        align-items: center;
        padding: 0px;
        gap: 30px;
        flex-direction: column;
        width: 100%;
    }

    .career .secondSection .cards .card .info .details .tags {
        align-items: center;
        padding: 0px;
        gap: 12px;
        width: 100%;
        height: 27px;
    }

    .career .secondSection .cards .card .job-details {
        align-items: flex-start;
    }

    .career .secondSection .cards .card .info .details .tags .tagicon1 {
        height: 25px;
    }
    .career .secondSection .cards .card .info .details .tags .tagname {
        width: 83px;
        font-weight: 500;
        padding-top: 4px;
        height: 27px;
    }

    .career .secondSection .cards .card .info .details .tags .tagname1 {
        font-weight: 500;
        width: 300px;
        height: 27px;
        padding-top: 4px;
    }

    .career .secondSection .cards .card .info .buttons {
        justify-content: center;
        align-items: flex-start;        
        padding: 0px;
        gap: 32px;
        flex-direction: column;
        width: 100%;
    }

    .career .secondSection .cards .card .info .buttons .button-one {
        align-items:center;
        padding: 10px;

        width: 153.94px;
        height: 53.94px;
    }

    .career .secondSection .cards .card .info .buttons .button-two {
        width: 142px;
        height: 48px;
        align-items: center;
    }

    .career .secondSection .cards .card .info .buttons .button-two .text {
        height: 28px;
        width: 142px;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
    }

    .career .secondSection .cards .card .info .buttons .button-one .text {
        width: 100px;
        height: 27px;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
    }

    .career .secondSection .hr-line {
        width: 206px;
        height: 0px;

        /* background variation */

        border: 3px solid #222327;
    }

    .career .secondSection .cards .card .job-details .details-content {
        gap: 12px;
    }

    .career .secondSection .cards .card .job-details {
    }

    .career .secondSection .cards .card .job-details .details-content .main-details {
        gap: 26px;
    }

    .career .secondSection .cards .card .job-details .details-content .job-skills {
        gap: 12px;
    }

    .career .secondSection .cards .card .job-details .details-content .job-description {
        gap: 12px;
    }

    .career .secondSection .cards .card .job-details .details-content .job-skills .list-skills {
        gap: 28px;
        flex-wrap: wrap;
    }

    .career .secondSection .cards .card .job-details .details-content .job-skills .list-skills .skill {
        width: 170.95px;
        height: 40px;
        /* White Text */

        border: 2px solid #f8f8f8;
        border-radius: 100px;
        font-family: "Poppins";

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        align-items: center;
        justify-content: center;
        padding: 10px 12px;
        color: #ffffff;
    }

    .career .secondSection .cards .card .job-details .details-content .title {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        /* identical to box height */
    }

    .career .secondSection .cards .card .job-details .details-content .main-details {
        text-transform: uppercase;
    }

    .career .secondSection .cards .card .job-details .details-content .job-info {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .career .secondSection {
        padding: 112px 80px 55px;
        gap: 48px;
        width: 100vw;
    }

    .career .secondSection .cards {
        align-items: flex-start;
        padding: 0px;
        gap: 48px;
    }

    .career .secondSection .cards .card {
        align-items: flex-start;
        gap: 32px;
        padding: 32px;
        width: calc(100vw-112px);
        height: 232.94px;
        transition: linear ease-in-out 1000ms;
        /* background variation */
    }

    .career .secondSection .cards .card .header {
        height: 83px;
        align-items: flex-start;
        padding: 0px;
        gap: 12px;
    }

    .career .secondSection .cards .card .header .title {
        height: 43px;
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;
    }

    .career .secondSection .cards .card .header .description {
        height: 28px;
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
    }

    .career .secondSection .cards .card .info {
        height: 53.94px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .career .secondSection .cards .card .info .details {
        align-items: flex-start;
        padding: 0px;
        gap: 30px;
        height: 27px;
    }

    .career .secondSection .cards .card .info .details .tags {
        align-items: center;
        padding: 0px;
        gap: 12px;
        white-space: nowrap;
        height: 27px;
        
    }

    .career .secondSection .cards .card .job-details {
        align-items: flex-start;
    }

    .career .secondSection .cards .card .info .details .tags .tagicon1 {
        height: 25px;
    }

    .career .secondSection .cards .card .info .details .tags .tagname {
        font-weight: 500;
        width: 100px;
        height: 27px;
        padding-top: 4px;
    }
    .career .secondSection .cards .card .info .details .tags .tagname1 {
        font-weight: 500;
        width: 300px;
        height: 27px;
        padding-top: 4px;
    }

    .career .secondSection .cards .card .info .buttons {
        align-items: flex-start;
        padding: 10px;
        gap: 32px;
        height: 53.94px;
        
    }

    .career .secondSection .cards .card .info .buttons .button-one {
        align-items: center;
        padding: 10px;
        height: 53.94px;
    }

    .career .secondSection .cards .card .info .buttons .button-two {
        width: 142px;
        height: 48px;
        align-items: center;
    }

    .career .secondSection .cards .card .info .buttons .button-two .text {
        height: 28px;
        width: 142px;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
    }

    .career .secondSection .cards .card .info .buttons .button-one .text {
        height: 27px;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
    }

    .career .secondSection .hr-line {
        height: 0px;
        /* background variation */
        border: 3px solid #222327;
    }

    .career .secondSection .cards .card .job-details .details-content {
        gap: 12px;
    }

    .career .secondSection .cards .card .job-details {
        padding: 41px 72px;
    }

    .career .secondSection .cards .card .job-details .details-content .main-details {
        gap: 26px;
    }

    .career .secondSection .cards .card .job-details .details-content .job-skills {
        gap: 12px;
    }

    .career .secondSection .cards .card .job-details .details-content .job-description {
        gap: 12px;
    }

    .career .secondSection .cards .card .job-details .details-content .job-skills .list-skills {
        gap: 28px;
        flex-wrap: wrap;
    }

    .career .secondSection .cards .card .job-details .details-content .job-skills .list-skills .skill {
        height: 40px;
        /* White Text */

        border: 2px solid #f8f8f8;
        border-radius: 100px;
        font-family: "Poppins";

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        align-items: center;
        justify-content: center;
        padding: 10px 12px;
        color: #ffffff;
    }

    .career .secondSection .cards .card .job-details .details-content .title {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        /* identical to box height */
    }

    .career .secondSection .cards .card .job-details .details-content .main-details {
        text-transform: uppercase;
    }

    .career .secondSection .cards .card .job-details .details-content .job-info {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
}

@media (min-width: 1440px) {
    .career .secondSection {
        padding: 112px 72px 112px 72px;
        align-items: center;
        gap: 48px;
    }

    .career .secondSection .cards {
        align-items: flex-start;
        padding: 0px;
        gap: 48px;
        width: 1280px;
        overflow-y: scroll;
    }

    .career .secondSection .cards .card {
        align-items: flex-start;
        gap: 32px;
        padding: 32px;
        width: 1280px;
        height: 232.94px;
        transition: linear ease-in-out 1000ms;

        /* background variation */
    }

    .career .secondSection .cards .card .header {
        height: 83px;
        gap: 12px;
        width: 1216px;
        border-radius: 0px;
    }

    .career .secondSection .cards .card .header .title {
        
        height: 43px;
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;
    }

    .career .secondSection .cards .card .header .description {
        width: 1216px;
        height: 28px;
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
    }

    .career .secondSection .cards .card .info {
        width: 1219.94px;
        height: 53.94px;
        align-items: center;
        gap: 505px;
    }

    .career .secondSection .cards .card .info .details {
        align-items: flex-start;
        padding: 0px;
        gap: 30px;
        width: 387px;
        height: 27px;
    }

    .career .secondSection .cards .card .info .details .tags {
        align-items: center;
        padding: 0px;
        gap: 12px;
        width: 200px;
        height: 27px;
    }

    .career .secondSection .cards .card .job-details {
        align-items: flex-start;
    }

    .career .secondSection .cards .card .info .details .tags .tagicon1 {
        height: 34px;
    }

    .career .secondSection .cards .card .info .details .tags .tagname {
        font-weight: 500;
        width: 150px;
        height: 27px;
        padding-top: 4px;
    }
    .career .secondSection .cards .card .info .details .tags .tagname1 {
        font-weight: 500;
        width: 300px;
        height: 27px;
        padding-top: 4px;
    }

    .career .secondSection .cards .card .info .buttons {
        align-items: center;
        padding: 0px;
        gap: 32px;
        width: 327.94px;
        height: 53.94px;
    }

    .career .secondSection .cards .card .info .buttons .button-one {
        align-items: center;
        padding: 10px;

        width: 153.94px;
        height: 53.94px;
    }

    .career .secondSection .cards .card .info .buttons .button-two {
        width: 142px;
        height: 48px;
        align-items: center;
    }

    .career .secondSection .cards .card .info .buttons .button-two .text {
        height: 28px;
        width: 142px;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
    }

    .career .secondSection .cards .card .info .buttons .button-one .text {
        width: 100px;
        height: 27px;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
    }

    .career .secondSection .hr-line {
        width: 206px;
        height: 0px;

        /* background variation */

        border: 3px solid #222327;
    }

    .career .secondSection .cards .card .job-details .details-content {
        gap: 12px;
    }

    .career .secondSection .cards .card .job-details {
        padding: 41px 72px;
    }

    .career .secondSection .cards .card .job-details .details-content .main-details {
        gap: 26px;
    }

    .career .secondSection .cards .card .job-details .details-content .job-skills {
        gap: 12px;
    }

    .career .secondSection .cards .card .job-details .details-content .job-description {
        gap: 12px;
    }

    .career .secondSection .cards .card .job-details .details-content .job-skills .list-skills {
        gap: 28px;
        flex-wrap: wrap;
    }

    .career .secondSection .cards .card .job-details .details-content .job-skills .list-skills .skill {
        width: 170.95px;
        height: 40px;
        /* White Text */

        border: 2px solid #f8f8f8;
        border-radius: 100px;
        font-family: "Poppins";

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        align-items: center;
        justify-content: center;
        padding: 10px 12px;
        color: #ffffff;
    }

    .career .secondSection .cards .card .job-details .details-content .title {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        /* identical to box height */
    }

    .career .secondSection .cards .card .job-details .details-content .main-details {
        text-transform: uppercase;
    }

    .career .secondSection .cards .card .job-details .details-content .job-info {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
}

/*Third Section*/
.career .thirdSection {}

.career .thirdSection .faq .header .title {
    font-family: "Baloo 2";
    /* identical to box height, or 77px */

    text-align: center;
    color: #000000;
}

.career .thirdSection .faq .content .description {
    font-family: "Poppins";
    font-style: normal;

    text-align: center;

    color: #000000;
}

.career .thirdSection .faq .question .title {
    font-family: "Poppins";
    text-align: center;
    text-transform: uppercase;
    color: #000000;
}

.career .thirdSection .faq .question .box {
    background: #f05033;
    border: 2px solid white;
}

.career .thirdSection .faq .question .box .button {
    color: #f4f4f4;
    font-family: "Poppins";
    font-style: normal;
    text-align: center;
}

.career .thirdSection .faq .accordian .accordian-question .answer .answer-text {
    font-family: "Poppins";
    font-style: normal;
    color: #000000;
}

@media (min-width: 360px) and (max-width: 1024px) {
    .career .thirdSection {
        justify-content: flex-start;
        align-items: center;
        padding: 112px 64px;
        gap: 48px;
    }

    .career .thirdSection .faq {
        width: 100vw;
        align-items: center;
        padding: 0px;
        gap: 40px;    
    }

    .career .thirdSection .faq .header {
        align-items: center;
        padding: 0px;
        gap: 24px;
    }

    .career .thirdSection .faq .header .title {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;
    }

    .career .thirdSection .faq .content .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .career .thirdSection .faq .question {
        padding: 0px;
        gap: 24px;
        align-items: center;
        height: 108px;
    }

    .career .thirdSection .faq .question .title {
        height: 36px;
        width: 100%;
        /* Heading H5 */

        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
    }

    
    .career .thirdSection .faq .hover-button {
        position: relative;
        padding: 19px 22px;
        transition: all 0.2s ease;
        border: none;
        background-color: transparent;
        gap: 14px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        color: black;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .career .thirdSection .faq .hover-button:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border: 0.6px solid #F86A1A;
        width: 56px;
        height: 56px;
        transition: all 0.3s ease;
      }
      .career .thirdSection .faq .hover-button span {
        position: relative;
        font-size: 16px;
        line-height: 18px;
        font-weight: 900;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        vertical-align: middle;
      }
      .career .thirdSection .faq .hover-button svg{
        fill: #F05033;
      }
      
      .career .thirdSection .faq .hover-button:hover:before {
        width: 100%;
        background: #F05033;
        border: 1.4px solid #F05033;
      }
      .career .thirdSection .faq .hover-button:hover {
        color:#F4F4F4;
    }
    
      .career .thirdSection .faq .hover-button:hover svg {
        transform: translateX(0);
        fill: #F4F4F4;
      }
      .career .thirdSection .faq .hover-button:active {
        transform: scale(0.96);
      }

    /* Accordian */
    .career .thirdSection .faq .accordian {
        align-items: flex-start;
        padding: 0px;
        width: 296px;
    }

    .career .thirdSection .faq .accordian .accordian-question {
        align-items: center;
        padding: 20px 0px;
        gap: 12px;
        border-top: 1px solid black;
        width: 100%;
   
    }

    .career .thirdSection .faq .accordian .accordian-question:last-child {
        border-bottom: 1px solid black;
    }

    .career .thirdSection .faq .accordian .accordian-question .questions-contains .question-text {
        /* Label */
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height */

        /* Black */
        color: #000000;
    }
    .career .thirdSection .faq .accordian .accordian-question .expands{
        display: block !important;
    }
    .career .thirdSection .faq .accordian .accordian-question .questions-contains .arrow{
        cursor: pointer;
    }
    .career .thirdSection .faq .accordian .accordian-question .questions-contains .inverse{
        transform: rotate(-180deg);
    }
    .career .thirdSection .faq .accordian .accordian-question .answer {
        padding: 10px;
        background: #E9E9E9;
        display: none;
    }

    .career .thirdSection .faq .accordian .accordian-question .answer .answer-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        /* Black */
    }
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .career .thirdSection {
        justify-content: flex-start;
        align-items: center;
        padding: 112px 64px;
        gap: 48px;
    }

    .career .thirdSection .faq {
        width: 919px;
        align-items: center;
        padding: 0px;
        gap: 80px;
    }

    .career .thirdSection .faq .header {
        align-items: flex-start;
        padding: 0px;
        gap: 24px;
    }

    .career .thirdSection .faq .header .title {
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .career .thirdSection .faq .content .description {
        width: 100%;
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
    }

    .career .thirdSection .faq .question {
        padding: 0px;
        gap: 24px;
        align-items: center;
        width: 560px;
        height: 108px;
    }

    .career .thirdSection .faq .question .title {
        height: 36px;
        width: 100%;
        /* Heading H5 */

        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
    }


    .career .thirdSection .faq .hover-button {
        position: relative;
        padding: 19px 22px;
        transition: all 0.2s ease;
        border: none;
        background-color: transparent;
        gap: 14px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        color: black;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .career .thirdSection .faq .hover-button:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border: 0.6px solid #F86A1A;
        width: 56px;
        height: 56px;
        transition: all 0.3s ease;
      }
      .career .thirdSection .faq .hover-button span {
        position: relative;
        font-size: 16px;
        line-height: 18px;
        font-weight: 900;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        vertical-align: middle;
      }
      .career .thirdSection .faq .hover-button svg{
        fill: #F05033;
      }
      
      .career .thirdSection .faq .hover-button:hover:before {
        width: 100%;
        background: #F05033;
        border: 1.4px solid #F05033;
      }
      .career .thirdSection .faq .hover-button:hover {
        color:#F4F4F4;
    }
    
      .career .thirdSection .faq .hover-button:hover svg {
        transform: translateX(0);
        fill: #F4F4F4;
      }
      .career .thirdSection .faq .hover-button:active {
        transform: scale(0.96);
      }

    /* Accordian */
    .career .thirdSection .faq .accordian {
        align-items: flex-start;
        padding: 0px;
        width: 915px;
    }

    .career .thirdSection .faq .accordian .accordian-question {
        align-items: center;
        padding: 20px 0px;
        gap: 12px;
        border-top: 1px solid black;
        width: 914px;
   
    }

    .career .thirdSection .faq .accordian .accordian-question:last-child {
        border-bottom: 1px solid black;
    }

    .career .thirdSection .faq .accordian .accordian-question .questions-contains .question-text {
        width: 870px;
        height: 27px;

        /* Label */
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */

        /* Black */
        color: #000000;
    }
    .career .thirdSection .faq .accordian .accordian-question .expands{
        display: block !important;
    }
    .career .thirdSection .faq .accordian .accordian-question .questions-contains .arrow{
        cursor: pointer;
    }
    .career .thirdSection .faq .accordian .accordian-question .questions-contains .inverse{
        transform: rotate(-180deg);
    }
    .career .thirdSection .faq .accordian .accordian-question .answer {
        width: 915px;
        height: 117px;
        padding: 10px;
        background: #E9E9E9;
        display: none;
    }

    .career .thirdSection .faq .accordian .accordian-question .answer .answer-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        /* Black */
        width: 870px;
    }
}

@media (min-width: 1440px) {
    .career .thirdSection {
        justify-content: flex-start;
        align-items: center;
        padding: 112px 64px;
        gap: 48px;
    }

    .career .thirdSection .faq {
        width: 919px;
        align-items: center;
        padding: 0px;
        gap: 80px;
    }

    .career .thirdSection .faq .header {
        align-items: flex-start;
        padding: 0px;
        gap: 24px;
        height: 157px;
    }

    .career .thirdSection .faq .header .title {
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .career .thirdSection .faq .content .description {
        width: 100%;
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
    }

    .career .thirdSection .faq .question {
        padding: 0px;
        gap: 24px;
        align-items: center;
        width: 560px;
        height: 108px;
    }

    .career .thirdSection .faq .question .title {
        height: 36px;
        width: 100%;
        /* Heading H5 */

        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
    }

    .career .thirdSection .faq .hover-button {
        position: relative;
        padding: 19px 22px;
        transition: all 0.2s ease;
        border: none;
        background-color: transparent;
        gap: 14px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        color: black;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .career .thirdSection .faq .hover-button:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border: 0.6px solid #F86A1A;
        width: 10px;
        height: 65px;
        background-color: #F86a1a;
        transition: all 0.3s ease;
      }
      .career .thirdSection .faq .hover-button span {
        position: relative;
        font-size: 16px;
        line-height: 18px;
        font-weight: 900;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        vertical-align: middle;
      }
      .career .thirdSection .faq .hover-button svg{
        fill: #F05033;
      }
      
      .career .thirdSection .faq .hover-button:hover:before {
        width: 100%;
        background: #F05033;
        border: 1.4px solid #F05033;
      }
      .career .thirdSection .faq .hover-button:hover {
        color:#F4F4F4;
    }
    
      .career .thirdSection .faq .hover-button:hover svg {
        transform: translateX(0);
        fill: #F4F4F4;
      }
      .career .thirdSection .faq .hover-button:active {
        transform: scale(0.96);
      }

    /* Accordian */
    .career .thirdSection .faq .accordian {
        align-items: flex-start;
        padding: 0px;

        width: 915px;
    }

    .career .thirdSection .faq .accordian .accordian-question {
        align-items: center;
        padding: 20px 0px;
        gap: 12px;
        border-top: 1px solid black;
        width: 914px;
   
    }

    .career .thirdSection .faq .accordian .accordian-question:last-child {
        border-bottom: 1px solid black;
    }

    .career .thirdSection .faq .accordian .accordian-question .questions-contains .question-text {
        width: 870px;
        height: 27px;

        /* Label */
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */

        /* Black */
        color: #000000;
    }
    .career .thirdSection .faq .accordian .accordian-question .expands{
        display: block !important;
    }
    .career .thirdSection .faq .accordian .accordian-question .questions-contains .arrow{
        cursor: pointer;
    }
    .career .thirdSection .faq .accordian .accordian-question .questions-contains .inverse{
        transform: rotate(-180deg);
    }
    .career .thirdSection .faq .accordian .accordian-question .answer {
        width: 915px;
        height: 117px;
        padding: 10px;
        background: #E9E9E9;
        display: none;
    }

    .career .thirdSection .faq .accordian .accordian-question .answer .answer-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        /* Black */
        width: 870px;
    }
    
}

/* Sixth Section*/
.career .fourthSection {
    /* background variation */
    background: #222327;
    align-items: center;
}

.career .fourthSection .content .hover-button:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border: 0.6px solid #F86A1A;
    width: 10px;
    height: 65px;
    transition: all 0.3s ease;
  }

.career .fourthSection .image {
    content: url('../images/home/sixthSection.svg');
}

.career .fourthSection .content .title {
    color: #F8F8F8;
    /* Heading H2 */

    font-family: 'Baloo 2';
    font-style: normal;
}

.career .fourthSection .content .description {
    font-family: 'Poppins';
    font-style: normal;
    color: #F8F8F8;
}

.career .fourthSection .content .box {
    background: #F05033;
    border-radius: 3px;
    cursor: pointer;
}

.career .fourthSection .content .box .text {
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
    cursor: pointer;
}
@media (min-width: 360px ) and (max-width: 1024px) {
    .career .fourthSection{
        display: none;
    }
}
@media (min-width: 1024px ) and (max-width: 1440px) {
    .career .fourthSection {
        padding: 0px 80px 0px 0px;
        gap: 80px;

        width: 100vw;
        height: 422px;
    }

    .career .fourthSection .image {
        width: 426px;
        height: 421px;
        object-fit: cover;
    }

    .career .fourthSection .content {
        gap: 24px;
        width: 438px;
        height: 351px;
    }

    .career .fourthSection .content .title {
        width: 438px;
        height: 231px;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .career .fourthSection .content .description {
        width: 438px;
        height: 24px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .career .fourthSection .content .box {
        height: 48px;
        width: 156px;
        left: 0px;
        top: 0px;
        border-radius: 3px;
        align-items: center;
        justify-content: center;
    }
    .career .fourthSection .content .hover-button:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border: 0.6px solid #F86A1A;
        width: 10px;
        height: 65px;
        transition: all 0.3s ease;
        background-color: #F86A1A;
      }

    .career .fourthSection .content .box .text {
        height: 28.138957977294922px;
        width: 114.88965606689453px;
        left: 13.623046875px;
        top: 10.15380859375px;
        text-align: center;

        /* Label */
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        /* White Text */
    }
}
@media (min-width: 1440px) {
    .career .fourthSection {
        padding: 0px 80px 0px 0px;
        gap: 80px;

        width: 100vw;
        height: 422px;

    }

    .career .fourthSection .image {
        width: 629px;
        height: 422px;
    }

    .career .fourthSection .content {
        gap: 24px;
        width: 651px;
        height: 274px;

    }

    .career .fourthSection .content .title {
        width: 651px;
        height: 154px;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .career .fourthSection .content .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    .career .fourthSection .content .hover-button {
        position: relative;
        padding: 19px 22px;
        transition: all 0.2s ease;
        border: none;
        width: 250px;
        background-color: transparent;
        gap: 14px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #F4F4F4;
        display: flex;
        align-items: center;
        white-space: nowrap;
      }
      .career .fourthSection .content .hover-button:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border: 0.6px solid #F86A1A;
        width: 10px;
        height: 65px;
        transition: all 0.3s ease;
        background-color: #F86A1A;
      }
      .career .fourthSection .content .hover-button span {
        position: relative;
        font-size: 16px;
        line-height: 18px;
        font-weight: 900;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        vertical-align: middle;
      }
      .career .fourthSection .content  .hover-button svg{
        fill: #F05033;
      }
      
      .career .fourthSection .content .hover-button:hover:before {
        width: 100%;
        background: #F05033;
        border: 1.4px solid #F05033;
      }
      .career .fourthSection .content .hover-button:hover svg {
        transform: translateX(0);
        fill: #F4F4F4;
      }
      .career .fourthSection .content .hover-button:active {
        transform: scale(0.96);
      }
}

/*
Hover Effects
*/
.box:hover {
    background-color: #ee3434 !important;
    border: 1px solid #f8f8f8 !important;
    cursor: pointer !important;
}