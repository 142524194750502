/*
First Section
*/

.portfolio .firstSection {
    position: relative;
    width: 100vw;
    background-size: cover;
    background-image: linear-gradient(90.15deg,
            #0c101c -10.1%,
            rgba(12, 16, 28, 0) 106.97%),
        url("../images/sustainability/firstSection.png");
}

.portfolio .firstSection .content .title {
    color: #f8f8f8;
    font-family: "Baloo 2";
    font-style: normal;
}

.portfolio .firstSection .content .description {
    font-family: "Poppins";
    font-style: normal;
    color: #ffffff;
}

.portfolio .firstSection .content .margin {
    background: #f05033;
}
.portfolio .firstSection .top{
    gap: 24px;
}
@media (min-width: 360px) and (max-width: 1024px) {
    .portfolio .firstSection {
        height: 100vh;
        width: 100vw;
        flex-direction: row;
        gap: 13px;
    }

    .portfolio .firstSection .content {
        justify-content: flex-end;
        padding: 16px;;
        width: 100%;
        gap: 14px;
    }

    .portfolio .firstSection .content .title {
        /* Heading H1 */
        font-weight: 440;
        font-size: 36px;
        line-height: 120%;
        /* or 89px */
        /* White Text */
    }

    .portfolio .firstSection .content .description {
        width: 75%;
        /* Heading H6 */

        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        /* or 133% */
    }

    .portfolio .firstSection .buttons {
        display: none;
        position: absolute;
        height: 6px;
        width: 220px;
        left: calc((100vw/2) - 80px);
        top: 846px;
        gap: 20px;
        border-radius: 0px;
    }

    .portfolio .firstSection .buttons .button {
        height: 6px;
        width: 40px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        cursor: pointer;
    }

    .portfolio .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .portfolio .firstSection {
        height: 100vh;
    }

    .portfolio .firstSection .content {
        justify-content: flex-end;
        padding: 80px;;
        width: 100%;
        gap: 14px;
    }

    .portfolio .firstSection .content .title {
        width: 75%;

        /* Heading H1 */
        font-weight: 440;
        font-size: 64px;
        line-height: 106%;
        /* or 89px */
        /* White Text */
    }

    .portfolio .firstSection .content .description {
        width: 75%;
        /* Heading H6 */

        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
        /* or 133% */
    }

    .portfolio .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}

@media (min-width: 1440px) {
    .portfolio .firstSection {
        height: 100vh;
        padding: 82px;
        justify-content: flex-end;
    }

    .portfolio .firstSection .content {
        justify-content: flex-end;
        width: 100%;
        gap: 14px;
    }

    .portfolio .firstSection .content .title {

        /* Heading H1 */
        font-weight: 440;
        font-size: 84px;
        line-height: 106%;
        /* or 89px */
        /* White Text */
    }

    .portfolio .firstSection .content .description {
        /* Heading H6 */
        width: 75%;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        /* or 133% */
    }

    .portfolio .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}

/*Second Section*/
.portfolio .secondSection {
    background: #08090d;
}

.portfolio .secondSection .main .projects .project .project-content .project-info .content .see-more-button {
    align-self:normal;
    background-color: #db3923;
    background-image: none;
    background-position: 0 50%;
    background-repeat: repeat no-repeat;
    background-size: 4px 3px;
    border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
    border-style: solid;
    border-width: 3px;
    box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    width: 400px;
    display: inline-block;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1.3rem;
    line-height: 23px;
    outline: none;
    padding-top: 80px;
    align-items:first baseline;
    padding: 1rem;
    text-decoration: none;
    transition: all 235ms ease-in-out;
    border-bottom-left-radius: 15px 255px;
    border-bottom-right-radius: 225px 15px;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.portfolio .secondSection .main .projects .project .project-content .project-info .content .see-more-button:hover {
    box-shadow: rgb(248, 247, 247) 8px 10px 10px -10px;
    transform: translate3d(0, 8px, 0);
    background-color: #db3923;
}

.portfolio .secondSection .main .projects .project .project-content .project-info .content .see-more-button:focus {
    box-shadow: rgb(255, 254, 254) 2px 8px 4px -6px;
    color: #ffffff;
}

.portfolio .secondSection .projects .project .project-number {
    font-family: "Poppins";
    font-style: normal;
    color: #252730;
    
}

.portfolio .secondSection .projects .project .project-content .project-main .margin {
    background: #f05033;
}

.portfolio .secondSection .projects .project .project-content .project-main .project-title {
    text-transform: uppercase;
    color: #f05033;
    font-family: "Poppins";
    font-style: normal;
}

.portfolio .secondSection .projects .project .project-content .project-main .project-description {
    font-family: "Poppins";
    color: #f8f8f8;
    font-style: normal;
}

.portfolio .secondSection .projects .project .project-content .project-button {
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    color: #f8f8f8;
}
.portfolio .secondSection .disable{
    color: #7b7b7b !important;
    border-color: #7b7b7b !important ;
}
.portfolio .secondSection .disable svg{
    fill: #7b7b7b !important;
}
@media (min-width : 360px) and (max-width:1024px) {
    .portfolio .secondSection {
        width: 100vw;
        padding: 43px 36px 36px 31px;
        align-items: center;
        justify-content: space-evenly;
        gap :34px;
        height: 100%;
    }


    .portfolio .secondSection .top{
        gap: 24px;
        align-items: center;
    }
    .portfolio .secondSection .top .content{
        width: 295px;
        justify-content: space-between;
        padding: 0px;
        gap: 12px;
    }
    .portfolio .secondSection .top .title{
        white-space : pre-wrap;
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;
        /* or 77px */
    }
    .portfolio .secondSection .top .margin{
        width: 10px;
        height: 289px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        background: #F05033;
    }
    .portfolio .secondSection .top .description{
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }
    .portfolio .secondSection .main{
    }
    .portfolio .secondSection .projects{
        gap:48px;
    }
    .portfolio .secondSection .projects .project {
        width: 326px;
        flex-direction: column !important;
        position: relative;
    }

    .portfolio .secondSection .projects .project .project-number {
        align-self: flex-start;
        font-weight: 500;
        font-size: 64px;
        line-height: 96px;
        /* identical to box height */
    }

    .portfolio .secondSection .projects .project .project-content {
        align-self: flex-end;
        align-items: flex-end;
        flex-direction: column !important;
        width: 297px;
        gap: 24px;
    }

    .portfolio .secondSection .projects .project .project-content .image {
        width: 100%;
        height: 142px;
        object-fit: cover;
        border-radius: 10px;
    }

    .portfolio .secondSection .projects .project .project-content .project-main {
        align-items: flex-start;
        padding: 0px;
        gap: 6px;
    }

    .portfolio .secondSection .projects .project .project-content .project-main .margin {
        width: 121px;
        height: 6.3px;
    }

    .portfolio .secondSection .projects .project .project-content .project-main .project-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
    }
    .portfolio .secondSection .projects .project .project-content .project-info {
        align-self: flex-start;
        gap : 24px;
        width: 295px;
    }
    .portfolio .secondSection .projects .project .project-content .project-main .project-description {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }

    .portfolio .secondSection .projects .project .project-content .project-button {
        width: 126px;
        height: 48px;
        border: 2px solid #f8f8f8;
        border-radius: 3px;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;

        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
    }
    .portfolio .secondSection .margin{
        width: 961.59px;
        height: 0px;
    }
    .portfolio .secondSection .projects .content .hover-button {

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        height: 48px;
        width: 126px;
        left: 0px;
        top: 0px;
        border-radius: 3px;
        border: 2px solid #F8F8F8;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        align-items: center;
        text-align: center;

        /* White Text */

        color: #F8F8F8;

      }

    .portfolio .secondSection .projects  .content .hover-button svg{
        height: 14.29px ;
        width: 27.4px;
        position: absolute;
    right: -20px;
    background: #222327;
    }    
    /* .portfolio .secondSection .projects .content .hover-button:hover svg{
        transform: scalex(1.43);
        right: -25px;
    } */
    .portfolio .secondSection .view-all .hover-button {
        position: relative;
        padding: 19px 22px;
        transition: all 0.2s ease;
        border: none;
        background-color: transparent;
        gap: 14px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        color: #F4F4F4;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .portfolio .secondSection .view-all .hover-button:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border: 0.6px solid #F86A1A;
        width: 56px;
        height: 56px;
        transition: all 0.3s ease;
      }
      .portfolio .secondSection .view-all  .hover-button span {
        position: relative;
        font-size: 16px;
        line-height: 18px;
        font-weight: 900;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        vertical-align: middle;
      }
      .portfolio .secondSection .view-all .hover-button svg{
        fill: #F05033;
      }
      
      .portfolio .secondSection .view-all  .hover-button:hover:before {
        width: 100%;
        background: #F05033;
        border: 1.4px solid #F05033;
      }
      .portfolio .secondSection .view-all  .hover-button:hover svg {
        transform: translateX(0);
        fill: #F4F4F4;
      }
      .portfolio .secondSection .view-all  .hover-button:active {
        transform: scale(0.96);
      }
}
@media (min-width : 1024px) and (max-width:1439px) {
    .portfolio .secondSection {
        width: 100vw;
        padding: 50px 50px 50px 50px;
        align-items: center;
        justify-content: space-evenly;
        gap :34px;
    }
    .portfolio .secondSection .top{
        height: 214px;
        gap: 24px;
        align-items: center;

    }
    .portfolio .secondSection .top .content{
        width: 864px;
        justify-content: space-between;
    }
    .portfolio .secondSection .top .title{
        white-space : pre-wrap;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
        /* or 77px */
    }
    .portfolio .secondSection .top .margin{
        height: 214px;
        width: 10px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        background: #F05033;
    }
    .portfolio .secondSection .top .description{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    .portfolio .secondSection .main{
        width: 864px;

    }
    .portfolio .secondSection .projects{
        padding-top: 100px;
        padding-bottom: 100px;
        width: 791px;
    }
    .portfolio .secondSection .projects .project .right{
        right: 0;
    }
    .portfolio .secondSection .projects .project .left{
        left: 0;
    }
    .portfolio .secondSection .projects .project {
        height: 441px;
        justify-content: center;
        position: relative;
    }

    .portfolio .secondSection .projects .project .project-number {
        position: absolute;
        font-weight: 500;
        align-self: flex-start;
        font-size: 60px;
        line-height: 168px;
        /* identical to box height */
    }

    .portfolio .secondSection .projects .project .project-content {
        position: absolute;
        align-self: flex-end;
        align-items: flex-end;
        gap: 24px;
    }

    .portfolio .secondSection .projects .project .project-content .image {
        width: 270px;
        height: 315px;
        object-fit: cover;
        border-radius: 10px;
    }

    .portfolio .secondSection .projects .project .project-content .project-main {
        align-items: flex-start;
        padding: 0px;
        gap: 6px;
    }

    .portfolio .secondSection .projects .project .project-content .project-main .margin {
        width: 121px;
        height: 6.3px;
    }

    .portfolio .secondSection .projects .project .project-content .project-main .project-title {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
    }
    .portfolio .secondSection .projects .project .project-content .project-info {
        align-self: flex-start;
        gap : 24px;
        width: 492px;
    }
    .portfolio .secondSection .projects .project .project-content .project-main .project-description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .portfolio .secondSection .projects .project .project-content .project-button {
        width: 126px;
        height: 48px;
        border: 2px solid #f8f8f8;
        border-radius: 3px;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;

        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
    }
    .portfolio .secondSection .margin{
        width: 961.59px;
        height: 0px;
    }
    .portfolio .secondSection  .box {
        height: 48px;
        width: 142px;

        align-items: center;
        justify-content: center;
    }

    .portfolio .secondSection  .box .text {
        height: 28.138957977294922px;
        width: 114.88965606689453px;
        left: 13.623046875px;
        top: 10.15380859375px;
        text-align: center;

        /* Label */
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        /* White Text */
    }
    .portfolio .secondSection .projects .content .hover-button {

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        height: 48px;
        width: 126px;
        left: 0px;
        top: 0px;
        border-radius: 3px;
        border: 2px solid #F8F8F8;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        align-items: center;
        text-align: center;

        /* White Text */

        color: #F8F8F8;

      }

    .portfolio .secondSection .projects  .content .hover-button svg{
        height: 14.29px ;
        width: 27.4px;
        position: absolute;
        right: -20px;
        background: #08090D;
    }    
    .portfolio .secondSection .projects .content .hover-button:hover svg{
        transition: all ease-in-out 5000ms;
        transform: scalex(1.43);
        right: -25px;
    }
    .portfolio .secondSection .view-all .hover-button {
        position: relative;
        padding: 19px 22px;
        transition: all 0.2s ease;
        border: none;
        background-color: transparent;
        gap: 14px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        color: #F4F4F4;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .portfolio .secondSection .view-all .hover-button:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border: 0.6px solid #F86A1A;
        width: 56px;
        height: 56px;
        transition: all 0.3s ease;
      }
      .portfolio .secondSection .view-all  .hover-button span {
        position: relative;
        font-size: 16px;
        line-height: 18px;
        font-weight: 900;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        vertical-align: middle;
      }
      .portfolio .secondSection .view-all .hover-button svg{
        fill: #F05033;
      }
      
      .portfolio .secondSection .view-all  .hover-button:hover:before {
        width: 100%;
        background: #F05033;
        border: 1.4px solid #F05033;
      }
      .portfolio .secondSection .view-all  .hover-button:hover svg {
        transform: translateX(0);
        fill: #F4F4F4;
      }
      .portfolio .secondSection .view-all  .hover-button:active {
        transform: scale(0.96);
      }
}
@media (min-width: 1440px) {
    .portfolio .secondSection {
        width: 100vw;
        padding: 50px 50px 50px 50px;
        align-items: center;
        justify-content: space-evenly;
        gap :64px;
    }
    .portfolio .secondSection .top{
        width: 1089px;
        height: 214px;
        gap: 24px;
        align-items: center;

    }
    .portfolio .secondSection .top .content{
        justify-content: space-between;
    }
    .portfolio .secondSection .top .title{
        height: 154px;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
        /* or 77px */
    }
    .portfolio .secondSection .top .margin{
        height: 214px;
        width: 10px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
        background: #F05033;
    }
    .portfolio .secondSection .top .description{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    .portfolio .secondSection .main{
        width: 1288.7px;

    }
    .portfolio .secondSection .projects{
        padding-top: 100px;
        padding-bottom: 100px;
        gap: 32px;
    }
    .portfolio .secondSection .projects .project {
        height: 333.7px;
        position: relative;
        justify-content: center;
    }
    .portfolio .secondSection .projects .project .right{
        right: 0;
    }
    .portfolio .secondSection .projects .project .left{
        left: 0;
    }
    .portfolio .secondSection .projects .project .project-number {
        position: absolute;
        font-weight: 500;
        align-self:first baseline;
        font-size: 60px;
        line-height: 90px;
        /* identical to box height */
    }

    .portfolio .secondSection .projects .project .project-content {
        position: absolute;
        align-self: flex-end;
        align-items: flex-end;
        gap: 24px;
    }

    .portfolio .secondSection .projects .project .project-content .image {
        width: 422px;
        height: 240px;
        object-fit: contain;
        border-radius: 10px;
    }

    .portfolio .secondSection .projects .project .project-content .project-main {
        width: 715px;
        align-items: flex-start;
        padding: 0px;
        gap: 6px;
    }

    .portfolio .secondSection .projects .project .project-content .project-main .margin {
        width: 121px;
        height: 6.3px;
    }

    .portfolio .secondSection .projects .project .project-content .project-main .project-title {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
    }
    .portfolio .secondSection .projects .project .project-content .project-info {
        align-self: flex-start;
        gap : 24px;
    }
    .portfolio .secondSection .projects .project .project-content .project-main .project-description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .portfolio .secondSection .projects .project .project-content .project-button {
        width: 126px;
        height: 48px;
        border: 2px solid #f8f8f8;
        border-radius: 3px;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;

        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
    }
    .portfolio .secondSection .margin{
        width: 961.59px;
        height: 0px;
    }
    .portfolio .secondSection  .box {
        height: 48px;
        width: 142px;

        align-items: center;
        justify-content: center;
    }

    .portfolio .secondSection  .box .text {
        height: 28.138957977294922px;
        width: 114.88965606689453px;
        left: 13.623046875px;
        top: 10.15380859375px;
        text-align: center;

        /* Label */
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        /* White Text */
    }
    .portfolio .secondSection .projects .content .hover-button {

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        height: 48px;
        width: 126px;
        left: 0px;
        top: 0px;
        border-radius: 3px;
        border: 2px solid #F8F8F8;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        align-items: center;
        text-align: center;

        /* White Text */

        color: #F8F8F8;

      }

      .portfolio .secondSection .projects  .content .hover-button svg{
        height: 14.29px ;
        width: 27.4px;
        position: absolute;
        right: -20px;
        background: #08090D;
    }    
    /* .portfolio .secondSection .projects .content .hover-button:hover svg{
        transition: all ease-in-out 500ms;
        transform: scalex(1.43);
        right: -25px;
    } */
    .portfolio .secondSection .view-all .hover-button {
        position: relative;
        padding: 19px 22px;
        transition: all 0.2s ease;
        border: none;
        background-color: transparent;
        gap: 14px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        color: #F4F4F4;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .portfolio .secondSection .view-all .hover-button:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border: 0.6px solid #F86A1A;
        width: 56px;
        height: 56px;
        transition: all 0.3s ease;
      }
      .portfolio .secondSection .view-all  .hover-button span {
        position: relative;
        font-size: 16px;
        line-height: 18px;
        font-weight: 900;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        vertical-align: middle;
      }
      .portfolio .secondSection .view-all .hover-button svg{
        fill: #F05033;
      }
      
      .portfolio .secondSection .view-all  .hover-button:hover:before {
        width: 100%;
        background: #F05033;
        border: 1.4px solid #F05033;
      }
      .portfolio .secondSection .view-all  .hover-button:hover svg {
        transform: translateX(0);
        fill: #F4F4F4;
      }
      .portfolio .secondSection .view-all  .hover-button:active {
        transform: scale(0.96);
      }
}
.portfolio .thirdSection {
    /* background variation */
    background: #222327;
    align-items: center;
}

.portfolio .thirdSection .image {
    content: url('../images/home/sixthSection.svg');
}

.portfolio .thirdSection .content .title {
    color: #F8F8F8;
    /* Heading H2 */

    font-family: 'Baloo 2';
    font-style: normal;
}

.portfolio .thirdSection .content .description {
    font-family: 'Poppins';
    font-style: normal;
    color: #F8F8F8;
}

.portfolio .thirdSection .content .box {
    background: #F05033;
    border-radius: 3px;
    cursor: pointer;
}

.portfolio .thirdSection .content .box .text {
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
    cursor: pointer;
}
@media (min-width: 360px ) and (max-width: 1024px) {
    .portfolio .thirdSection{
        display: none;
    }
}
@media (min-width: 1024px ) and (max-width: 1440px) {
    .portfolio .thirdSection {
        padding: 0px 80px 0px 0px;
        gap: 80px;

        width: 100vw;
        height: 422px;
    }

    .portfolio .thirdSection .image {
        width: 426px;
        height: 421px;
        object-fit: cover;
    }

    .portfolio .thirdSection .content {
        gap: 24px;
        width: 438px;
        height: 351px;
    }

    .portfolio .thirdSection .content .title {
        width: 438px;
        height: 231px;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .portfolio .thirdSection .content .description {
        width: 438px;
        height: 24px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .portfolio .thirdSection .content .box {
        height: 48px;
        width: 156px;
        left: 0px;
        top: 0px;
        border-radius: 3px;
        align-items: center;
        justify-content: center;
    }

    .portfolio .thirdSection .content .box .text {
        height: 28.138957977294922px;
        width: 114.88965606689453px;
        left: 13.623046875px;
        top: 10.15380859375px;
        text-align: center;

        /* Label */
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        /* White Text */
    }
}
@media (min-width: 1440px) {
    .portfolio .thirdSection {
        padding: 0px 80px 0px 0px;
        gap: 80px;

        width: 100vw;
        height: 422px;

    }

    .portfolio .thirdSection .image {
        width: 629px;
        height: 422px;
    }

    .portfolio .thirdSection .content {
        gap: 24px;
        width: 651px;
        height: 274px;

    }

    .portfolio .thirdSection .content .title {
        width: 651px;
        height: 154px;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .portfolio .thirdSection .content .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .portfolio .thirdSection .content .box {
        height: 48px;
        width: 142px;

        align-items: center;
        justify-content: center;
    }

    .portfolio .thirdSection .content .box .text {
        height: 28.138957977294922px;
        width: 114.88965606689453px;
        left: 13.623046875px;
        top: 10.15380859375px;
        border-radius: nullpx;
        text-align: center;

        /* Label */
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        /* White Text */
    }
}