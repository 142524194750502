/*First Section*/
.aboutus .firstSection {
    position: relative;
    width: 100vw;
    background-size: cover;
    background-image: linear-gradient(90.15deg,
            #0c101c -10.1%,
            rgba(12, 16, 28, 0) 106.97%),
        url("../images/aboutus/firstSection1.png");
}

.aboutus .firstSection .content .title {
    color: #f8f8f8;
    font-family: "Baloo 2";
    font-style: normal;
}

.aboutus .firstSection .content .description {
    font-family: "Poppins";
    font-style: normal;
    color: #ffffff;
}

.aboutus .firstSection .content .margin {
    background: #f05033;
    margin-bottom: 12px;
}
.aboutus .firstSection .content .title span:nth-child(1){
    color : #1C4BF4;
}
.aboutus .firstSection .content .title span:nth-child(2){
    color : #F86A1A;
}
@media (min-width: 360px ) and (max-width: 1024px) {
    .aboutus .firstSection {
        height: calc(100vh - (86px));
        padding: 16px;
        justify-content: end;
    }

    .aboutus .firstSection .content {
        width: 100%;
        border-radius: 0px;
        padding: 0px 12px 0px 0px;

    }

    .aboutus .firstSection .content .title {
        /* Heading H1 */
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;
        /* or 89px */
        /* White Text */
    }

    .aboutus .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}
@media (min-width: 1024px ) and (max-width: 1440px) {
    .aboutus .firstSection {
        height: 100vh;
        padding: 100px 80px;
        justify-content: end;
    }

    .aboutus .firstSection .content {
        width: 100%;
        border-radius: 0px;
        padding: 0px 12px 0px 0px;
    }

    .aboutus .firstSection .content .title {
        width: 100%;
        /* Heading H1 */
        font-weight: 440;
        font-size: 84px;
        line-height: 106%;
        /* or 89px */
        /* White Text */
    }

    .aboutus .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}
@media (min-width: 1440px) {
    .aboutus .firstSection {
        height: 100vh;
        padding: 100px 80px;
        justify-content: end;
    }

    .aboutus .firstSection .content {
        width: 100%;
        border-radius: 0px;
        padding: 0px 12px 0px 0px;
    }

    .aboutus .firstSection .content .title {
        width: 100%;
        /* Heading H1 */
        font-weight: 440;
        font-size: 84px;
        line-height: 106%;
        /* or 89px */
        /* White Text */
    }

    .aboutus .firstSection .content .margin {
        width: 192.53px;
        height: 6.3px;
    }
}

/*Second Section*/
.aboutus .secondSection {
    position: relative;
    width: 100vw;
    border-radius: 0px;
    background-color: #08090D;
}

.aboutus .secondSection .image {
    content: url('../images/aboutus/secondSection.svg')
}

.aboutus .secondSection .content .info .title {
    color: #F8F8F8;
    font-family: 'Baloo 2';
    font-style: normal;
}

.aboutus .secondSection .content .info .title span:first-child {
    color: #0B2EAC;
}

.aboutus .secondSection .content .info .title span:last-child {
    color: #F05033;
}

.aboutus .secondSection .content .box {
    background: #F05033;
    border-radius: 3px;
    cursor: pointer;
}

.aboutus .secondSection .content .box .text {
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
    cursor: pointer;
}

.aboutus .secondSection .content .info .description {
    font-family: 'Poppins';
    font-style: normal;
    color: #F8F8F8;
    font-size: 60px;
    padding-top: 50px;
}
@media (min-width: 360px ) and (max-width: 1024px) {
    .aboutus .secondSection {
        padding: 36px 32px;
        gap: 24px;
        flex-direction: column-reverse;
    }

    .aboutus .secondSection .content {
        align-items: start;
        gap: 24px;
        
    }

    .aboutus .secondSection .content .info {
        align-items: center;
    }

    .aboutus .secondSection .image {
        width: 296px;        
        border-radius: 10px;
    }

    .aboutus .secondSection .content .info .title {
        width: 100%;
        /* Heading H2 */
        align-self: stretch;
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;
    }

    .aboutus .secondSection .content .info .description {
        width: 100%;
        /* Text */
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;        
    }
}
@media (min-width: 1024px ) and (max-width: 1440px) {
    .aboutus .secondSection {
        height: calc(100vh - 86px);
        padding: 80px 80px;
        gap: 80px;
        justify-content: space-evenly;
        align-items: center;
    }

    .aboutus .secondSection .content {
        align-items: start;
        gap: 24px;
    }

    .aboutus .secondSection .content .info {
        align-items: start;
    }

    .aboutus .secondSection .image {
        width: 390px;
        height: 460px;
        background-size: contain;
        border-radius: 10px;
    }

    .aboutus .secondSection .content .info .title {
        width: 100%;
        /* Heading H2 */
        align-self: stretch;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .aboutus .secondSection .content .info .description {
        width: 100%;
        /* Text */
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
    }
}   
@media (min-width: 1440px) {
    .aboutus .secondSection {
        height: calc(100vh - 86px);
        padding: 80px 80px;
        gap: 80px;
        justify-content: space-evenly;
        
    }

    .aboutus .secondSection .content {
        align-items: start;
        gap: 24px;
    }

    .aboutus .secondSection .content .info {
        align-items: start;
    }

    .aboutus .secondSection .image {
        width: 100%;
        height: 100%;

        border-radius: 10px;
    }

    .aboutus .secondSection .content .info .title {
        width: 100%;
        /* Heading H2 */
        align-self: stretch;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .aboutus .secondSection .content .info .description {
        width: 100%;
        /* Text */
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
    }
}
/* Third Section */
.aboutus .thirdSection{
    width: 100vw;
    background:#08090D;
}
.aboutus .thirdSection .header .title{
    font-family: 'Baloo 2';
    font-style: normal;
    color: #F8F8F8;
}
.aboutus .thirdSection .header .buttons .button{
    font-family: 'Poppins';
    font-style: normal;
    color: #F8F8F8;
}
.aboutus .thirdSection .header .buttons .select,.aboutus .thirdSection .header .buttons .button:hover{
    font-weight: 600 !important;
    border-bottom-width: 2px !important;
    border-bottom-style: solid !important;
    border-bottom-color:  #F05033 !important ;
}
.aboutus .thirdSection .content .vision .title {
    font-family: 'Baloo 2';
    font-style: normal;
    color: #F8F8F8;
}
.aboutus .thirdSection .content .vision .content .description {
    font-family: 'Poppins';
    font-style: normal;
    color: #F8F8F8;
}
.aboutus .thirdSection .content .value .cards .card .content{
    font-family: 'Poppins';
    font-style: normal;
    color: #F8F8F8;  
}
.aboutus .thirdSection .content .journey {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.aboutus .thirdSection .content .journey::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
@media (min-width: 360px ) and (max-width: 1024px) {
    .aboutus .thirdSection{
        padding: 36px 19px 81px 32px;
        gap:24px
    }
    .aboutus .thirdSection .header{
        justify-content: space-between ;
        flex-direction: column;
        gap :12px;
    }
    .aboutus .thirdSection .header .title{
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;
        /* or 77px */
        display: flex;
        align-items: start; 
    }
    .aboutus .thirdSection .header .buttons{
        gap : 12px;
    }
    .aboutus .thirdSection .header .buttons .button{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        padding-bottom : 1px;
        border-bottom : 2px solid #F8F8F8;
        cursor: pointer;
    }
    .aboutus .thirdSection .content .vision {
        gap : 34px;
        flex-direction: column;
    }
    .aboutus .thirdSection .content .vision .content {
        gap : 14px;
    }
    .aboutus .thirdSection .content .vision .content .title {
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
    }
    .aboutus .thirdSection .content .vision .content .description {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }

    .aboutus .thirdSection .content .value .cards .card .content .title{
        font-weight: 500;
        font-size: 21px;
        line-height: 28px; 
        text-align: left;
    }
    .aboutus .thirdSection .content .value .cards .card .content .description{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }
    .aboutus .thirdSection .content .value{
        gap : 58px;
    }
    .aboutus .thirdSection .content .value .left-image{
       display: none;
    }
    .aboutus .thirdSection .content .value .cards{
        gap : 77px;
        height: 420px;
        width: calc(100vw - 36px);        
        row-gap: 24px;
        flex-wrap: wrap;
        flex-direction: column;
        overflow-x: scroll;
    }
    .aboutus .thirdSection .content .value .cards .card{
        width: 471px;
        gap : 13px;
    }
    .aboutus .thirdSection .content .value .cards .card .image{
        width: 82px;
        height: 78px;
    }
    .aboutus .thirdSection .content .value .cards .card .content{
        margin-top: 12px;
    }
    .aboutus .thirdSection .content .value .cards .card .content .title{
        font-weight: 500;
        font-size: 21px;
        line-height: 28px; 
        text-align: left;
    }
    .aboutus .thirdSection .content .value .cards .card .content .description{
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
    }
    .aboutus .thirdSection .content .journey{
        overflow: scroll;
    }
    .aboutus .thirdSection .content .journey img{
        width: 3176px !important;
        height: 498px;
        max-width: none;
    }
    
}
@media (min-width: 1024px ) and (max-width: 1440px) {
    .aboutus .thirdSection{
        padding: 68px 53px 100px 67px;
        gap: 95px;
    }
    .aboutus .thirdSection .header{
        justify-content: space-between ;
        align-items: center;
    }
    .aboutus .thirdSection .header .title{
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
        /* or 77px */
        display: flex;
        align-items: center; 
    }
    .aboutus .thirdSection .header .buttons{
        gap : 53px;
    }
    .aboutus .thirdSection .header .buttons .button{
        font-weight: 600;
        font-size: 21px;
        line-height: 32px;
        text-align: center;
        padding-bottom : 1px;
        border-bottom : 2px solid #F8F8F8;
        cursor: pointer;
    }
    .aboutus .thirdSection .content .vision {
        gap : 80px;
    }
    .aboutus .thirdSection .content .vision .image {
        width: 320.8px !important;
        height: 348.18px !important;
    }
    .aboutus .thirdSection .content .vision .content {
        gap : 24px;
    }
    .aboutus .thirdSection .content .vision .content .title {
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;
    }

    .aboutus .thirdSection .content .vision .content .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    .aboutus .thirdSection .content .value .cards .card .content .title{
        font-weight: 500;
        font-size: 21px;
        line-height: 28px; 
        text-align: left;
    }
    .aboutus .thirdSection .content .value .cards .card .content .description{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }
    .aboutus .thirdSection .content .value{
        gap : 58px;
    }
    .aboutus .thirdSection .content .value .cards{
        gap : 77px;
        height: 420px;
        width: calc(100vw - 74px -  64px);
        row-gap: 24px;
        flex-wrap: wrap;
        flex-direction: column;
        overflow-x: scroll;
    }
    .aboutus .thirdSection .content .value .cards .card{
        width: 471px;
        gap : 13px;
    }
    .aboutus .thirdSection .content .value .cards .card .image{
        width: 82px;
        height: 78px;
    }
    .aboutus .thirdSection .content .value .cards .card .content{
        margin-top: 12px;
    }
    .aboutus .thirdSection .content .value .cards .card .content .title{
        font-weight: 500;
        font-size: 21px;
        line-height: 28px; 
        text-align: left;
    }
    .aboutus .thirdSection .content .value .cards .card .content .description{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }
    .aboutus .thirdSection .content .journey{
        overflow: scroll;
    }
    .aboutus .thirdSection .content .journey img{
        width: 3361px !important;
        height: 473px;
        max-width: none;
    }
}
@media (min-width: 1440px) {
    .aboutus .thirdSection{
        padding: 45px 83px 100px 80px;
        gap: 95px;
    }
    .aboutus .thirdSection .header{
        justify-content: space-between ;
        align-items: center;
    }
    .aboutus .thirdSection .content .vision .image {
        width: 100% ;
        height: 100% ;
    }
    .aboutus .thirdSection .header .title{
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
        /* or 77px */
        display: flex;
        align-items: center; 
    }
    .aboutus .thirdSection .header .buttons{
        gap : 53px;
    }
    .aboutus .thirdSection .header .buttons .button{
        font-weight: 500;
        font-size: 21px;
        line-height: 32px;
        text-align: center;
        padding-bottom : 1px;
        border-bottom : 2px solid #F8F8F8;
        cursor: pointer;
    }
    .aboutus .thirdSection .content .vision {
        gap : 49px;
    }
    .aboutus .thirdSection .content .vision .content {
        gap : 24px;
    }
    .aboutus .thirdSection .content .vision .content .title {
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }
    .aboutus .thirdSection .content .vision .content .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        padding-top: 30px;
    }

    .aboutus .thirdSection .content .value{
        gap : 58px;
    }
    .aboutus .thirdSection .content .value .cards{
        gap : 77px;
        row-gap: 24px;
        flex-wrap: wrap;
    }
    .aboutus .thirdSection .content .value .cards .card{
        width: 471px;
        gap : 13px;
    }
    .aboutus .thirdSection .content .value .cards .card .image{
        width: 82px;
        height: 78px;
    }
    .aboutus .thirdSection .content .value .cards .card .content{
        margin-top: 12px;
    }
    .aboutus .thirdSection .content .value .cards .card .content .title{
        font-weight: 500;
        font-size: 21px;
        line-height: 28px; 
        text-align: left;
    }
    .aboutus .thirdSection .content .value .cards .card .content .description{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }
    .aboutus .thirdSection .content .journey{
        overflow: scroll;
    }
    .aboutus .thirdSection .content .journey img{
        width: 3361px !important;
        max-width: none;
    }
}


/*Fourth Section*/
.aboutus .fourthSection {
    position: relative;
    width: 100vw;
    border-radius: 0px;
    background-color: #222327;
}

.aboutus .fourthSection .image {
    content: url('../images/aboutus/fourthSection.svg')
}

.aboutus .fourthSection .content .info .title {
    color: #F8F8F8;
    font-family: 'Baloo 2';
    font-style: normal;
}

.aboutus .fourthSection .content .info .title span {
    color: #F05033;
}

.aboutus .fourthSection .content .box {
    border-radius: 3px;
    cursor: pointer;
}

.aboutus .fourthSection .content .content .see-more-button {
    align-self:normal;
    background-color: #db3923;
    background-image: none;
    background-position: 0 50%;
    background-repeat: repeat no-repeat;
    background-size: 4px 3px;
    border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
    border-style: solid;
    border-width: 3px;
    box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    width: 400px;
    display: inline-block;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1.3rem;
    line-height: 23px;
    outline: none;
    padding-top: 80px;
    align-items:first baseline;
    padding: 1rem;
    text-decoration: none;
    transition: all 235ms ease-in-out;
    border-bottom-left-radius: 15px 255px;
    border-bottom-right-radius: 225px 15px;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.aboutus .fourthSection .content .content  .see-more-button:hover {
    box-shadow: rgb(248, 247, 247) 8px 10px 10px -10px;
    transform: translate3d(0, 8px, 0);
    background-color: #db3923;
}

.aboutus .fourthSection .content .content .see-more-button:focus {
    box-shadow: rgb(255, 254, 254) 2px 8px 4px -6px;
    color: #f52828;
}

.aboutus .fourthSection .content .box .text {
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
    cursor: pointer;
}

.aboutus .fourthSection .content .info .description {
    font-family: 'Poppins';
    font-style: normal;
    color: #F8F8F8;
}
@media (min-width: 360px ) and (max-width: 1024px) {
    .aboutus .fourthSection {
        padding: 16px;
        gap: 30px;
        flex-direction: column-reverse;
    }

    .aboutus .fourthSection .content {
        align-items: start;
        gap: 12px;
    }

    .aboutus .fourthSection .content .info {
        align-items: start;
        gap: 12px;
    }

    .aboutus .fourthSection .image {
        border-radius: 10px;
    }

    .aboutus .fourthSection .content .info .title {
        /* Heading H2 */
        align-self: stretch;
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
    }

    .aboutus .fourthSection .content .info .description {
        /* Text */
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }
    .aboutus .fourthSection  .content .hover-button {

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        height: 48px;
        width: 126px;
        left: 0px;
        top: 0px;
        border-radius: 3px;
        border: 2px solid #F8F8F8;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        align-items: center;
        text-align: center;

        /* White Text */

        color: #F8F8F8;

      }

    .aboutus .fourthSection .content .hover-button svg{
        height: 14.29px ;
        width: 27.4px;
        position: absolute;
        right: -20px;
        background: #222327;
    }    
    .aboutus .fourthSection .content .hover-button:hover svg{
        transform: scalex(1.43);
        right: -25px;
    }
 
}
@media (min-width: 1024px ) and (max-width: 1440px) {
    .aboutus .fourthSection {
        padding: 80px;
        gap: 30px;
    }

    .aboutus .fourthSection .content {
        align-items: start;
        gap: 24px;
    }

    .aboutus .fourthSection .content .info {
        align-items: start;
        gap: 24px;
    }

    .aboutus .fourthSection .image {
        width: 390px;
        height: 460px;  
        border-radius: 10px;
    }

    .aboutus .fourthSection .content .info .title {
        /* Heading H2 */
        align-self: stretch;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .aboutus .fourthSection .content .info .description {
        /* Text */
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    .aboutus .fourthSection  .content .hover-button {

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        height: 48px;
        width: 126px;
        left: 0px;
        top: 0px;
        border-radius: 3px;
        border: 2px solid #F8F8F8;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        align-items: center;
        text-align: center;

        /* White Text */

        color: #F8F8F8;

      }

    .aboutus .fourthSection .content .hover-button svg{
        height: 14.29px ;
        width: 27.4px;
        position: absolute;
        right: -20px;
        background: #222327;
    }    
    .aboutus .fourthSection .content .hover-button:hover svg{
        transform: scalex(1.43);
        right: -25px;
    }
 
}
@media (min-width: 1440px) {
    .aboutus .fourthSection {
        height: 722.437744140625px;
        padding: 80px;
        gap: 30px;
        justify-content: space-evenly;
    }

    .aboutus .fourthSection .content {
        align-items: start;
        gap: 24px;
    }

    .aboutus .fourthSection .content .info {
        align-items: start;
        gap: 24px;
    }

    .aboutus .fourthSection .image {
        width: 630px;
        height: 475.51px;

        border-radius: 10px;
    }

    .aboutus .fourthSection .content .info .title {
        width: 570px;
        /* Heading H2 */
        align-self: stretch;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .aboutus .fourthSection .content .info .description {
        width: 570px;
        height: 120px;
        /* Text */
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    .aboutus .fourthSection  .content .hover-button {

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        height: 48px;
        width: 126px;
        left: 0px;
        top: 0px;
        border-radius: 3px;
        border: 2px solid #F8F8F8;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        align-items: center;
        text-align: center;

        /* White Text */

        color: #F8F8F8;

      }

    .aboutus .fourthSection .content .hover-button svg{
        height: 14.29px ;
        width: 27.4px;
        position: absolute;
    right: -20px;
    background: #222327;
    }    
    .aboutus .fourthSection .content .hover-button:hover svg{
        transform: scalex(1.43);
        right: -25px;
    }
 
}
/*Fifth Section*/
.aboutus .fifthSection {
    width: 100vw;
    background: #FFFFFF;

}
.aboutus .fifthSection .title{
    height: 53px;
    font-family: 'Baloo 2';
    color: #08090D;
}
@media (min-width: 360px ) and (max-width: 1024px) {
    .aboutus .fifthSection {
        padding: 36px 32px 36px 36px;
        gap: 12px;     
    }
    .aboutus .fifthSection .title{
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;
    }
    .aboutus .fifthSection .images{
        gap: 16px;
        column-gap: 42px;
        flex-wrap: wrap;
        flex-grow: 2;
        justify-content: center;
    }
    .aboutus .fifthSection .images .image{
        width: 108px;
        height: 104px;
    }

}
@media (min-width: 1024px ) and (max-width: 1440px) {
    .aboutus .fifthSection {
        padding-top: 45px;
        padding-left: 80px;
        padding-right: 80px;
        padding-bottom: 45px;
        gap: 24px;
     
    }
    .aboutus .fifthSection .title{
        font-style: normal;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }
    .aboutus .fifthSection .images{
        gap: 16px;
        column-gap: 42px;
        flex-wrap: wrap;
        flex-grow: 2;
        justify-content: center;
    }
    .aboutus .fifthSection .images .image{
        width: 178px;
        height: 178px;
    }

}
@media (min-width: 1440px) {
    .aboutus .fifthSection {
        padding-top: 45px;
        padding-left: 80px;
        padding-right: 80px;
        padding-bottom: 45px;
        gap: 24px;
     
    }
    .aboutus .fifthSection .title{
        font-style: normal;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }
    .aboutus .fifthSection .images{
        gap: 16px;
        column-gap: 42px;
        flex-wrap: wrap;
        flex-grow: 2;
        justify-content: center;
    }
    .aboutus .fifthSection .images .image{
        width: 178px;
        height: 178px;
    }

}
/*Sixth Section*/
.aboutus .sixthSection{
    background: #08090D;
}
.aboutus .sixthSection .top .margin{
    background: #F05033;
}
.aboutus .sixthSection .top .title
{
    color: #F8F8F8;
    font-family: 'Baloo 2';
    font-style: normal;
}
.aboutus .sixthSection .reviews .main .controls
{
    background: #F05033;
}
.aboutus .sixthSection .reviews .main .review {
    transition: all 0.5s ease-in-out;
}
.aboutus .sixthSection .reviews .main .review .avatar .info .name{
    font-family: 'Poppins';
    text-align: center;
    color: #F8F8F8;
}
.aboutus .sixthSection .reviews .main .review .avatar .info .companyName{
    text-align: center;
    color: #F8F8F8;
    font-family: 'Roboto';
}
.aboutus .sixthSection .reviews .buttons .button {
    background-color: #D9D9D9;
}

.aboutus .sixthSection .reviews .buttons .selected {
    background-color: #f05033 !important;
}

@media (min-width: 1440px) {
    .aboutus .sixthSection{
        display: none;
        padding: 80px;
        gap: 80px;
        align-items: center;
    }
    .aboutus .sixthSection .content{
        align-items: flex-start;
        padding: 0px;
        gap: 80px;
    }
    .aboutus .sixthSection .top{
        align-items: center;
        padding: 0px;
        gap: 24px;
        height: 155px;
    }
    .aboutus .sixthSection .top .margin{
        width: 10px;
        height: 155px;
    }
    .aboutus .sixthSection .top .title{
        height: 154px;
        width: 1089px;

        /* Heading H2 */
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }
    .aboutus .sixthSection .reviews{
        align-items: center;
        padding: 0px;
        gap: 48px;
        height: 326px;
    }
    .aboutus .sixthSection .reviews .main {
        position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;   
    overflow: hidden;

    }
    .aboutus .sixthSection .reviews .main .controls
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 52px;
        width: 52px;
        border-radius: 100px;
        position: absolute;
    }
    .aboutus .sixthSection .reviews .main .left{
        left:30%
    }
    .aboutus .sixthSection .reviews .main .right{
        right:30%
    }
    .aboutus .sixthSection .reviews .main .controls .icon
    {
        height: 18px;
        width: 24px;
        border-radius: 0px;
    }
    .aboutus .sixthSection .reviews .main .slideshowSlider
    {

        display: inline-block;
        position: relative;
        margin: 0 auto;
        width: 100%;
        height: 100%;
    }
    .aboutus .sixthSection .reviews .main .slideshowSlider .review {
        align-items: center;
        padding: 0px;
        gap: 32px;
        width: 768px;
        height: 272px;
        position: relative;
        
    }
        
    @keyframes rightToLeft {
        0%{
            left:15%;
        }
        50%{
            left:5%;
        }
        100%{
            left:0%;
        }
    }
    @keyframes rightToLeftExit {
        0%{
            left:0%;
        }
        50%{
            left:-15%;
        }
        100%{
            left:-30%;
        }
    }
    .aboutus .sixthSection .reviews .main .review .title{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        /* White Text */
        
        color: #F8F8F8;
    }
    .aboutus .sixthSection .reviews .main .review .avatar{
        align-items: center;
        padding: 0px;
        gap: 16px;
    }
    .aboutus .sixthSection .reviews .main .review .avatar.image{
        width: 56px;
        height: 56px;
        background-color: red;
    }
    
    .aboutus .sixthSection .reviews .main .review .avatar .info .name{
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;
        /* identical to box height */
    }
    .aboutus .sixthSection .reviews .main .review .avatar .info .companyName{

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */
    }
    .aboutus .sixthSection .reviews .buttons {
        width: 1089px;
        justify-content: center;
        gap: 20px;
    }
    .aboutus .sixthSection .reviews .buttons .button {
        height: 6px;
        width: 40px;
        border-radius: 0px;
    }

}
/* Seventh Section*/
.aboutus .seventhSection {
    /* background variation */
    background: #2b6e36;
    align-items: center;
}

.aboutus .seventhSection .image {
    content: url('../images/home/sixthSection.svg');
}

.aboutus .seventhSection .content .title {
    color: #F8F8F8;
    /* Heading H2 */

    font-family: 'Baloo 2';
    font-style: normal;
}

.aboutus .seventhSection .content .description {
    font-family: 'Poppins';
    font-style: normal;
    color: #F8F8F8;
}

.aboutus .seventhSection .content .box {
    background: #F05033;
    border-radius: 3px;
    cursor: pointer;
}

.aboutus .seventhSection .content .box .text {
    color: #F8F8F8;
    font-family: 'Poppins';
    font-style: normal;
    cursor: pointer;
}
@media (min-width: 360px ) and (max-width: 1024px) {
    .aboutus .seventhSection{
        display: none;
    }
}
@media (min-width: 1024px ) and (max-width: 1440px) {
    .aboutus .seventhSection {
        padding: 0px 80px 0px 0px;
        gap: 80px;

        width: 100vw;
        height: 422px;
    }

    .aboutus .seventhSection .image {
        width: 426px;
        height: 421px;
        object-fit: cover;
    }

    .aboutus .seventhSection .content {
        gap: 24px;
        width: 438px;
        height: 351px;
    }

    .aboutus .seventhSection .content .title {
        width: 438px;
        height: 231px;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .aboutus .seventhSection .content .description {
        width: 438px;
        height: 24px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .aboutus .seventhSection .content .box {
        height: 48px;
        width: 156px;
        left: 0px;
        top: 0px;
        border-radius: 3px;
        align-items: center;
        justify-content: center;
    }

    .aboutus .seventhSection .content .box .text {
        height: 28.138957977294922px;
        width: 114.88965606689453px;
        left: 13.623046875px;
        top: 10.15380859375px;
        text-align: center;

        /* Label */
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        /* White Text */
    }
}
@media (min-width: 1440px) {
    .aboutus .seventhSection {
        padding: 0px 80px 0px 0px;
        gap: 80px;

        width: 100vw;
        height: 422px;

    }

    .aboutus .seventhSection .image {
        width: 629px;
        height: 422px;
    }

    .aboutus .seventhSection .content {
        gap: 24px;
        width: 651px;
        height: 274px;

    }

    .aboutus .seventhSection .content .title {
        width: 651px;
        height: 154px;
        font-weight: 400;
        font-size: 64px;
        line-height: 120%;
    }

    .aboutus .seventhSection .content .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .aboutus .seventhSection .content .box {
        height: 48px;
        width: 142px;

        align-items: center;
        justify-content: center;
    }

    .aboutus .seventhSection .content .box .text {
        height: 28.138957977294922px;
        width: 114.88965606689453px;
        left: 13.623046875px;
        top: 10.15380859375px;
        border-radius: nullpx;
        text-align: center;

        /* Label */
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        /* White Text */
    }
}